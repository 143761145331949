import React from "react";
import {
  Create,
  SimpleForm,
  required,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  DateInput,
  TextInput,
} from "react-admin";

import { LocationOptionRenderer } from "../districts/list";
import { assayTypes } from "./constants";

export const CreateAnalysis = (props) => (
  <Create title="Create Analysis" {...props}>
    <CreateForm {...props} />
  </Create>
);

const CreateForm = (props) => {
  return (
    <SimpleForm redirect={"edit"} {...props}>
      <TextInput
        source="name"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <SelectInput
        variant="outlined"
        source="assay"
        choices={assayTypes}
        fullWidth
        InputLabelProps={{ shrink: true }}
        validate={[required()]}
      />
      <ReferenceInput
        label="Location"
        source="districtId"
        reference="districts"
        fullWidth
        filter={{ _id: props.permissions?.collectionSiteIds }}
        perPage={100}
        InputLabelProps={{
          shrink: true,
        }}
        filterToQuery={(searchText) => {
          return {
            search: { name: searchText },
          };
        }}
        validate={[required()]}
        variant="outlined"
      >
        <AutocompleteInput
          options={{
            fullWidth: true,
            InputLabelProps: {
              shrink: true,
            },
          }}
          optionText={<LocationOptionRenderer />}
          matchSuggestion={() => true}
          inputText={(choice) => choice.name}
        />
      </ReferenceInput>
      <DateInput source="start" variant="outlined" fullWidth />

      <DateInput source="end" variant="outlined" fullWidth />
    </SimpleForm>
  );
};
