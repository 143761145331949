import {
  Box,
  Chip,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { AvTimer, PinDrop, LocalOffer } from "@material-ui/icons";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../theme";
import { BorderLinearProgress } from "./progress";

export const StockCard = ({ record, big }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const isBigCard = big && !isSmallScreen;

  if (!record) return null;

  const expiry = moment(record.expiry);
  return (
    <Link to={`/adjustments/${record.id}/show`}>
      <Box component={Paper} display={"flex"} flexDirection="column">
        <Box position={"relative"}>
          <Box
            justifyContent="space-between"
            alignItems={"center"}
            display="flex"
            style={{
              paddingBottom: isBigCard ? "24%" : "63%",
              backgroundSize: "contain",
              backgroundPosition: "right center",
              backgroundRepeat: "no-repeat",
              backgroundImage: `linear-gradient(180deg, rgba(150,150,150,0.2) 0%, rgba(256,256,256,1) 100%), url(${record.item?.image})`,
            }}
          />

          <Box
            position={"absolute"}
            bottom={0}
            display={"flex"}
            flexDirection="column"
            padding={2}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Chip
                  icon={<PinDrop />}
                  label={record.lab?.name}
                  size="small"
                  variant="default"
                  color="primary"
                />
              </Grid>
              <Grid item>
                <Chip
                  icon={<AvTimer />}
                  label={`${
                    expiry.isAfter() ? `Expires` : `Expired`
                  } ${expiry.fromNow()}`}
                  size="small"
                  variant="default"
                  color="primary"
                />
              </Grid>
              <Grid item>
                <Chip
                  icon={<AvTimer />}
                  label={`${moment(record.createdAt).fromNow()}`}
                  size="small"
                  variant="default"
                  color="primary"
                />
              </Grid>
              <Grid item>
                <Chip
                  icon={<LocalOffer />}
                  label={record.batchNumber}
                  size="small"
                  variant="default"
                  color="primary"
                />
              </Grid>
            </Grid>
            <Box display={"flex"} flexDirection={"column"} marginTop={1}>
              <Typography
                variant={isBigCard ? "h4" : "body1"}
                style={{ textShadow: "#aaa 1px 1px" }}
              >
                {record.item?.name}
              </Typography>
            </Box>

            <Box position={"relative"} marginTop={2}>
              <Box>
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    (100 *
                      (record.balance ||
                        record.latestBatchBalance?.batchBalance ||
                        record.quantity)) /
                    record.quantity
                  }
                />
              </Box>
              <Box position={"absolute"} top={0} right={0} paddingX={1}>
                <Typography variant="caption" color="textPrimary">
                  {record.balance ||
                    record.latestBatchBalance?.batchBalance ||
                    record.quantity}{" "}
                  {record.item?.unit} left
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export const ItemCard = ({ record, big, noGutter }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const isBigCard = big && !isSmallScreen;

  if (!record) return null;

  return (
    <Link to={`/items/${record.id}/show`}>
      <Box
        component={Paper}
        display={"flex"}
        flexDirection="column"
        margin={noGutter ? 0 : 1}
      >
        <Box position={"relative"}>
          <Box
            justifyContent="space-between"
            alignItems={"center"}
            display="flex"
            style={{
              paddingBottom: isBigCard ? "24%" : "63%",
              backgroundSize: "contain",
              backgroundPosition: "right center",
              backgroundRepeat: "no-repeat",
              backgroundImage: `linear-gradient(180deg, rgba(150,150,150,0.2) 0%, rgba(256,256,256,1) 100%), url(${record.image})`,
            }}
          />

          <Box
            position={"absolute"}
            bottom={0}
            display={"flex"}
            flexDirection="column"
            padding={2}
          >
            <Box display={"flex"} flexDirection={"column"} marginTop={1}>
              <Typography
                variant={isBigCard ? "h4" : "h6"}
                style={{ textShadow: "#aaa 1px 1px" }}
              >
                {record.name}
              </Typography>
              <Typography
                variant={"body1"}
                color="textSecondary"
                style={{ textShadow: "#aaa 1px 1px" }}
              >
                {record.balance} {record.unit} left
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};
