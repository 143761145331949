import Account from "@material-ui/icons/AccountBalance";
import { CreateAdjustment } from "./create-edit";
import { ShowStock } from "./show";

export const inventoryStockAdjustment = {
  name: "adjustments",
  options: {
    label: "Stock"
  },
  icon: Account,
  create: CreateAdjustment,
  show: ShowStock
};
