import AccountCircle from "@material-ui/icons/SupervisorAccount";

import { ShowUser } from "./show";
import { UserList } from "./list";
import { UserCreate, UserEdit } from "./create-edit";

export const users = {
  name: "users",
  options: {
    label: "Users"
  },
  icon: AccountCircle,
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  show: ShowUser
};

export const profileEdit = {
  name: "users",
  options: {
    label: "Users"
  },
  edit: UserEdit
};
