export const facilityAdmin = "facilityAdmin";
export const superAdmin = "super-admin";
export const admin = "admin";
export const stakeholder = "stakeholder";
export const dho = "district-health-official";
export const maintenance = "maintenance";

export const roles = [
  { id: superAdmin, name: "Super Admin" },
  { id: admin, name: "Admin" },
  { id: facilityAdmin, name: "Facility User" },
  { id: dho, name: "Area Health Official" },
  { id: stakeholder, name: "Stakeholder" },
  { id: maintenance, name: "Maintenance" },
];

export const roleMapper = {
  "super-admin": "Super Admin",
  admin: "Admin",
  stakeholder: "Stakeholder",
  facilityAdmin: "Facility Admin",
  dho: "Area Health Official",
  maintenance: "Maintenance",
};
