import * as React from "react";

import { Grid, Typography } from "@material-ui/core";
import { Conditionally } from "../components/conditionally";

export const DiffField = ({ record }) => {
  return (
    <Grid container spacing={2}>
      {record.diffs
        ?.filter(
          (diff) =>
            !diff.path.some((key) => ["_id", "createdBy"].includes(key)) ||
            diff.kind !== "D"
        )
        ?.map((diff) => (
          <Grid item>
            <Typography variant="caption">
              {`${diff.path
                ?.join(".")
                .replace(/([A-Z])/g, " $1")
                .toUpperCase()}`}
            </Typography>
            <div>
              <Conditionally
                when={diff.rhs}
                render={() => (
                  <span
                    style={{
                      display: "inline-block",
                      padding: "5px",
                      background: "#fdeeef",
                      color: "red",
                      border: "1px solid red",
                      borderRight: 0,
                    }}
                  >
                    {JSON.stringify(diff.rhs)}
                  </span>
                )}
              />
              <Conditionally
                when={diff.lhs}
                render={() => (
                  <span
                    style={{
                      display: "inline-block",
                      padding: "5px",
                      background: "#e4faed",
                      color: "green",
                      border: "1px solid green",
                    }}
                  >
                    {JSON.stringify(diff.lhs)}
                  </span>
                )}
              />
              <Conditionally
                when={diff.kind === "A"}
                render={() => (
                  <span
                    style={{
                      display: "inline-block",
                      padding: "5px",
                      background: "#e4faed",
                      color: "green",
                      border: "1px solid green",
                    }}
                  >
                    {JSON.stringify(diff.item.rhs || diff.item.lhs)}
                  </span>
                )}
              />
            </div>
          </Grid>
        ))}
    </Grid>
  );
};
