export function viewPatientDetails(userPermissions, record) {
  const hashValue = "********";

  if (userPermissions.includes("ppi")) {
    return record;
  }

  const sanitizedRecord = { ...record };

  const sanitizeField = (fieldPath) => {
    const fieldValue = fieldPath.reduce(
      (obj, key) => obj?.[key],
      sanitizedRecord
    );
    if (fieldValue != null) {
      fieldPath.reduce((obj, key, index, arr) => {
        if (index === arr.length - 1) {
          obj[key] = hashValue;
        }
        return obj[key];
      }, sanitizedRecord);
    }
  };

  const fieldsToSanitize = [
    ["history", "payload", "destination"],
    ["instrument", "sampleId"],
  ];

  const resultFields = ["name", "primaryId", "secondaryId", "address"];
  const requestFields = ["mrn", "name"];

  fieldsToSanitize.forEach(sanitizeField);

  resultFields.forEach((field) => {
    sanitizeField(["result", "patient", field]);
  });

  requestFields.forEach((field) => {
    sanitizeField(["request", field]);
  });

  return sanitizedRecord;
}
