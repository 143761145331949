import BarChart from "@material-ui/icons/BarChart";
import { ListAnalyses } from "./list";
import { CreateAnalysis } from "./create";
import { ShowAnalysis } from "./show";

export const analyses = {
  name: "analyses",
  options: {
    label: "Data Quality",
  },
  icon: BarChart,
  list: ListAnalyses,
  create: CreateAnalysis,
  show: ShowAnalysis,
};
