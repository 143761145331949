const env = typeof window === "undefined" ? process.env : window.env;

module.exports.backendUrl = env.BACKEND_URL || "http://localhost:5006";

module.exports.nodeEnv = env.NODE_ENV;
module.exports.port = env.PORT;
module.exports.publicUrl = env.PUBLIC_URL || "http://localhost:8080";
module.exports.sentryDsn = env.SENTRY_DSN;
module.exports.sentryRelease = env.SENTRY_RELEASE;
module.exports.vapidPublicKey = env.VAPID_PUBLIC_KEY;
module.exports.googleAnalyticsMeasurementId =
  env.GOOGLE_ANALYTICS_MEASUREMENT_ID;
module.exports.tawkPropertyId = env.TAWK_PROPERTY_ID;
module.exports.tawkWidgetId = env.TAWK_WIDGET_ID;
module.exports.relayUrl = env.RELAY_URL;
