import { LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useTheme } from "../theme";

export const BorderLinearProgress = withStyles(() => {
  const theme = useTheme();
  const color = theme.palette.primary;
  return {
    root: {
      height: 20,
      borderRadius: 5,
      color: color.contrastText,
    },
    colorPrimary: {
      backgroundColor: color.light,
    },
    bar: {
      borderRadius: 5,
      backgroundColor: color.dark,
    },
  };
})(LinearProgress);
