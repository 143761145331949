import React from "react";
import * as moment from "moment";

import { Typography, Tooltip } from "@material-ui/core";

export function TimestampField({
  record,
  source,
  prefix = true,
  showTimestamp = false,
  color = "initial"
}) {
  const timestamp = record && record[source];

  if (!timestamp) {
    return <Typography variant="caption" />;
  }

  const fullDate = moment(timestamp).format("Do MMMM YYYY [at] HH:mm:ss ");

  if (showTimestamp) {
    return (
      <Typography variant="body1" color={color}>
        {fullDate}
      </Typography>
    );
  }

  return (
    <Tooltip title={fullDate}>
      <Typography variant="body1" color={color}>
        {moment(timestamp).fromNow(!prefix)}
      </Typography>
    </Tooltip>
  );
}
