import React, { useEffect } from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Button,
  EditButton,
  FunctionField,
  Pagination,
} from "react-admin";
import { Box, CardActions, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Map, { Marker } from "react-map-gl";

import { facilityAdmin } from "../roles";
import { MachineListGrid } from "../machines/list";
import { UserListGrid } from "../users/list";
import { ResultsGrid } from "../results/list";

const Title = ({ record }) => record && `${record.name}`;

export const ShowFacility = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Show actions={<FacilityActions />} title={<Title />} {...props}>
      <TabbedShowLayout>
        <Tab label="Equipment">
          <ReferenceManyField
            reference="machines"
            target="facilityId"
            addLabel={false}
            pagination={<Pagination />}
          >
            <MachineListGrid />
          </ReferenceManyField>
        </Tab>
        <Tab label="Results">
          <ReferenceManyField
            reference="results"
            target="facilityId"
            addLabel={false}
            pagination={<Pagination />}
          >
            <ResultsGrid />
          </ReferenceManyField>
        </Tab>
        <Tab label="Users">
          <ReferenceManyField
            reference="users"
            target="facilityId"
            addLabel={false}
            pagination={<Pagination />}
          >
            <UserListGrid />
          </ReferenceManyField>
        </Tab>
        <Tab label="Location">
          <FunctionField
            addLabel={false}
            render={(record) => {
              if (!record.geolocation) {
                return (
                  <Box style={{ height: "400px" }}>
                    <Typography color="textSecondary" align="center">
                      Add Geolocation Data for this Facility to show the map
                    </Typography>
                  </Box>
                );
              }
              
              const [lng, lat] = record.geolocation.geometry.coordinates;
              return (
                <>
                  <Map
                    style={{ width: "100%", height: 400 }}
                    mapboxAccessToken={
                      "pk.eyJ1IjoiZXJuZXN0b28iLCJhIjoiY2w1azhydHBoMDdwZTNvbnhndHpybHk3OSJ9.iKMRDef7z1SaYsCT4Z2skQ"
                    }
                    mapStyle="mapbox://styles/ernestoo/cl5kmqzko008i14nvefbcudzo"
                    initialViewState={{
                      longitude: lng,
                      latitude: lat,
                      zoom: 5,
                    }}
                    boxZoom
                  >
                    <Marker
                      longitude={lng}
                      latitude={lat}
                      anchor="bottom"
                      color="red"
                    ></Marker>
                  </Map>
                </>
              );
            }}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const FacilityActions = ({ data, basePath }) => {
  const record = data || {};
  return (
    <CardActions>
      <div style={{ display: "flex", flexGrow: 1 }}>
        <div style={{ flex: "1 1 auto" }}>
          <Typography variant="h6">{record.name}</Typography>
        </div>
        <div style={{ flex: "0 0 auto" }}>
          <EditButton record={record} basePath={basePath} />
          <Button
            label="Add Facility User"
            component={Link}
            record={record}
            to={{
              pathname: "/users/create",
              state: { record: { facilityId: record.id, role: facilityAdmin } },
            }}
          />
        </div>
      </div>
    </CardActions>
  );
};
