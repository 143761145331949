import { DesktopMac } from "@material-ui/icons";

import { ListMachines } from "./list";
import { EditMachine } from "./edit";
import { ShowMachine } from "./show";

const machines = {
  name: "machines",
  options: {
    label: "Equipment",
  },
  icon: DesktopMac,
  list: ListMachines,
  edit: EditMachine,
  show: ShowMachine,
};

export const machineLogs = {
  name: "machine-logs",
  options: {
    label: "Machine Logs",
  },
};

export const relayLogs = {
  name: "relay-logs",
  options: {
    label: "Relay Logs",
  },
};

export const machineCalibrations = {
  name: "machine-calibrations",
  options: {
    label: "Machine Calibrations",
  },
};

export default machines;
