import * as React from "react";
import { Notification } from "react-admin";
import { Paper, makeStyles, Box } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { useTheme } from "../theme";

export const useStyles = makeStyles({
  link: {
    textDecoration: "none",
    display: "block"
  },
  main: {
    height: "100vh",
    padding: 16,
    boxSizing: "border-box"
  },
  paper: {
    padding: 16,
    width: "100%",
    maxWidth: 420,
    margin: 8,
    border: "none"
  }
});

export const BaseLayout = props => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Box
        className={classes.main}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Paper className={classes.paper}>{props.children}</Paper>
        <Notification />
      </Box>
    </ThemeProvider>
  );
};

export const AuthLayout = props => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Box
        className={classes.main}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <img
          style={{ display: "block", margin: "0 auto" }}
          src="/icon.png"
          alt="Logo"
          height="160"
        />

        <Box paddingTop={"16px"} />

        <Paper className={classes.paper}>{props.children}</Paper>
        <Notification />
      </Box>
    </ThemeProvider>
  );
};
