import React from "react";
import { Chip } from "@material-ui/core";
import { AccessTime as Icon } from "@material-ui/icons";
import moment from "moment";
import { grey } from "@material-ui/core/colors";

export const OverdueField = ({ record = {}, days = 14 }) => {
  const currentDate = moment();
  const daysPending = moment(record?.createdAt);
  const diffInDays = currentDate.diff(daysPending, "days");
  const timerLabel =
    diffInDays > days
      ? `${diffInDays - days} days overdue`
      : `${days - diffInDays} days left`;

  return (
    <Chip
      style={{
        backgroundColor: grey["500"],
        color: "white",
        padding: "2px",
      }}
      icon={<OverdueIcon />}
      label={timerLabel}
    />
  );
};

const OverdueIcon = () => {
  return <Icon />;
};
