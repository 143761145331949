import { Chip, Grid, Typography } from "@material-ui/core";
import React, { cloneElement } from "react";
import {
  List,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  Pagination,
  TopToolbar,
  ExportButton,
  CreateButton,
  SelectInput,
} from "react-admin";
import moment from "moment";
import { Check, Refresh } from "@material-ui/icons";

import { MobileListGrid } from "../components/mobile-list-grid";
import {
  LocationOptionRenderer,
  filterToLocationQuery,
  locationInputText,
  matchLocationSuggestion,
} from "../districts/list";
import { FlatActionButton } from "../components/action-button";
import { client } from "../providers/client";
import { machineTypes } from "./constants";

const format = (date) => moment(date).format("Do MMM YYYY");

export const ListFacilityAssignments = ({ permissions, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "end", order: "DESC" }}
    filters={<Filters />}
    actions={<ListActions />}
    pagination={
      <Pagination
        rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
        perPage={100}
      />
    }
  >
    <FacilityAssignmentGrid />
  </List>
);

const Filters = (props) => {
  return (
    <Filter {...props}>
      <SelectInput source="type" choices={machineTypes} />
      <TextInput source="search.serial" label="Serial" />
      <ReferenceInput
        source="facilityId"
        reference="facilities"
        filterToQuery={(name) => ({ search: { name } })}
        variant="outlined"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="locationId"
        reference="districts"
        variant="outlined"
        label="Location"
        filterToQuery={filterToLocationQuery}
      >
        <AutocompleteInput
          option
          optionText={<LocationOptionRenderer />}
          matchSuggestion={matchLocationSuggestion}
          inputText={locationInputText}
        />
      </ReferenceInput>
    </Filter>
  );
};

const ListActions = ({ filters }) => (
  <TopToolbar>
    <Grid container spacing={1}>
      <Grid item>{cloneElement(filters, { context: "button" })}</Grid>

      <Grid item>
        <ExportButton variant="outlined" />
      </Grid>
      <Grid item>
        <FlatActionButton
          icon={<Refresh />}
          title={"Apply"}
          handler={() => applyAssignments()}
          success={(data) => data}
          variant="contained"
          color={"primary"}
        />
      </Grid>
      <Grid item>
        <FlatActionButton
          icon={<Check />}
          title={"Validate"}
          handler={() => validateAssignments()}
          success={(data) => data}
          variant="contained"
          color={"primary"}
        />
      </Grid>
      <Grid item>
        <CreateButton variant="contained" />
      </Grid>
    </Grid>
  </TopToolbar>
);

export const FacilityAssignmentGrid = (props) => {
  return (
    <MobileListGrid
      {...props}
      metadata={(record) => (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Chip label={record.type} />
          </Grid>
          <Grid item>
            <Chip label={record.serial} />
          </Grid>
          <Grid item>
            <Typography variant="body1">{record.facility?.name}</Typography>
          </Grid>
        </Grid>
      )}
      right={(record) => (
        <Typography variant="caption">
          {moment(record.createdAt).fromNow()}
        </Typography>
      )}
      content={(record) => (
        <Typography variant="caption" color="primary">
          {record.start && record.end
            ? `From ${format(record.start)} To ${format(record.end)}`
            : record.start
            ? `Since ${format(record.start)}`
            : `Till ${format(record.end)}`}
        </Typography>
      )}
      link={(record) => `/facility-assignments/${record.id}/`}
    />
  );
};

function validateAssignments() {
  return client
    .get("/portal/facility-assignments/validate")
    .then((response) => response.data);
}

function applyAssignments() {
  return client
    .get("/portal/facility-assignments/apply")
    .then((response) => response.data);
}
