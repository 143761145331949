import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
  Box,
  Select,
  MenuItem,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { InfoOutlined, Refresh, Share } from "@material-ui/icons";
import { stringify } from "qs";
import { ResponsiveContainer } from "recharts";
import { useNotify, useVersion } from "react-admin";
import { mergeAll } from "ramda";
import moment from "moment";

import { TimeRangePicker, UnitOfTimePicker } from "../../components/controls";
import { history } from "../../history";
import { Conditionally } from "../../components/conditionally";
import { useRequestState } from "../../hooks/request";
import { client } from "../../providers/client";
import { backendUrl } from "../../config";

export const ChartContainer = (props) => {
  const {
    type,
    path,
    title,
    location,

    globalPeriod,
    globalStep,
    defaultStep,
    globalSource,
    defaultSource = "all",
    defaultRange = {
      type: "",
      unitOfTime: "",
    },
    defaultState = {},

    showTimeFilter = true,

    filterByDate = "createdAt",

    onChange,

    children,

    modes,
    shades,
    filter = {},

    compare,
  } = props;
  const notify = useNotify();
  const viewVersion = useVersion();
  const [containerVersion, setContainerVersion] = useState(0);
  const [range, setRange] = React.useState(globalPeriod ? globalPeriod : defaultRange);
  const [needsHardRefresh, setNeedsHardRefresh] = React.useState(false);
  const [step, setStep] = React.useState(globalStep ? globalStep : defaultStep);
  const [source, setSource] = React.useState(globalSource ? globalSource : defaultSource);
  const [mode, setMode] = React.useState(getInitialMode(modes));
  const [shade, setShade] = React.useState(shades?.[0]);

  const chart = useRequestState(
    (url, cache) => request(url, cache),
    defaultState
  );
  const featureCollection = useRequestState((url = true) => request(url), {});

  React.useEffect(() => {
    setRange(globalPeriod ? globalPeriod : defaultRange);
  }, [globalPeriod]);

  React.useEffect(() => {
    setStep(globalStep ? globalStep : defaultStep);
  }, [globalStep]);

  React.useEffect(() => {
    setSource(globalSource ? globalSource : defaultSource);
  }, [globalSource]);

  React.useEffect(() => {
    const chartId = path || shade.path;
    const url = getReportUrl(
      chartId,
      {
        field: shade?.filterByDate || filterByDate,
        filter: showTimeFilter ? range : {},
      },
      {
        ...(location?.id ? { [location.resource]: location.id } : {}),
        ...(source && source !== "all" ? { source } : {})
      },
      step,
      { ...filter, ...mode },
      compare
    );

    chart
      .fetch(url, needsHardRefresh)
      .then((data) => onChange && onChange(data))
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorage.removeItem("token", null);
          return history.push("/login");
        }

        return notify(error.response?.data?.message || error.message);
      })
      .then(() => {
        if (needsHardRefresh) {
          setNeedsHardRefresh(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewVersion, containerVersion, shade, range, step, mode, location?.id, source]);

  function getInitialMode(modes) {
    if (modes?.length > 0) {
      return mergeAll(
        modes.map(({ name, items }) => ({ [name]: items[0].id }))
      );
    }

    return {};
  }

  return (
    <Paper className={type === "tabular" ? "" : "avoid-break"} style={{ height: '100%' }}>
      <Grid
        container
        justifyContent="space-between"
        component={Box}
        padding={1}
      >
        <Conditionally
          when={title}
          render={() => (
            <Grid item>
              <Typography variant="h6">{title}</Typography>
            </Grid>
          )}
        />

        <Conditionally
          render={() => (
            <Grid item>
              <Select
                value={shade.path}
                onChange={(e) =>
                  setShade(shades.find((s) => s.path === e.target.value))
                }
              >
                {shades.map((m) => (
                  <MenuItem key={m.path} value={m.path}>
                    {m.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          when={shades?.length}
        />

        <Grid item className="no-print">
          <Box display={"flex"} alignItems="center">
            <Conditionally
              when={chart.loading}
              render={() => <CircularProgress size={20} />}
            />
            <Conditionally
              when={!chart.loading}
              render={() => (
                <IconButton
                  onClick={() => {
                    setNeedsHardRefresh(true);
                    setContainerVersion((v) => v + 1);
                  }}
                  size="small"
                >
                  <Refresh />
                </IconButton>
              )}
            />
            <Conditionally
              when={path}
              render={() => (
                <ExportChartCSV
                  id={path}
                  url={getExportUrl(
                    path,
                    {
                      field: filterByDate,
                      filter: showTimeFilter ? range : {},
                    },
                    location?.id ? { [location.resource]: location.id } : {},
                    step,
                    mode
                  )}
                  filename="report"
                />
              )}
            />
            <ChartDescription config={props} chart={chart} />
          </Box>
        </Grid>
      </Grid>

      {chart.loading && !chart.value ? (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress size={36} />
        </Grid>
      ) : null}

      <Conditionally
        when={chart.value}
        render={() => children(chart, featureCollection)}
      />

      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Conditionally
          when={showTimeFilter}
          render={() => (
            <Grid item>
              <TimeRangePicker value={range} onChange={setRange} />
            </Grid>
          )}
        />
        <Conditionally
          render={() =>
            modes.map(({ name, type, items }) => (
              <>
                <Conditionally
                  when={type === "switch"}
                  render={() => (
                    <Grid item>
                      <ToggleButtonGroup
                        size="small"
                        value={mode[name]}
                        exclusive
                        onChange={(_, x) =>
                          setMode((v) => ({ ...v, [name]: x }))
                        }
                        aria-label="text alignment"
                      >
                        {items.map((item) => (
                          <ToggleButton value={item.id}>
                            {item.name}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </Grid>
                  )}
                />
                <Conditionally
                  when={type === "dropdown"}
                  render={() => (
                    <Grid item>
                      <Select
                        value={mode[name]}
                        onChange={(e) =>
                          setMode((v) => ({ ...v, [name]: e.target.value }))
                        }
                      >
                        {items.map((item) => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  )}
                />
              </>
            ))
          }
          when={modes?.length}
        />

        <Conditionally
          render={() => (
            <Grid item>
              <UnitOfTimePicker value={step} onChange={setStep} />
            </Grid>
          )}
          when={defaultStep}
        />
      </Grid>
    </Paper>
  );
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export const ChartDescription = ({ chart, config }) => {
  return (
    <HtmlTooltip
      title={
        <Box padding={1}>
          <Typography variant="body1">{config.description}</Typography>
          <Box marginTop={1}>
            <Typography variant="caption" color="primary">
              Filtered By{" "}
            </Typography>
            <Typography variant="caption" color="primary">
              {config.filterByDate
                ?.split(/(?=[A-Z])/)
                .join(" ")
                .replace(/\sAt/, " Date")
                .toUpperCase()}
            </Typography>
          </Box>

          <Conditionally
            when={chart.value?.date}
            render={() => (
              <Typography variant="caption" color="primary">
                Last refreshed {moment(chart.value?.date).fromNow()}
              </Typography>
            )}
          />
        </Box>
      }
    >
      <IconButton size="small">
        <InfoOutlined />
      </IconButton>
    </HtmlTooltip>
  );
};

export const ExportChartCSV = ({ url }) => {
  function handleExport() {
    window.open(
      `${backendUrl}${url}&access_token=${localStorage.getItem("token")}`
    );
  }
  return (
    <IconButton onClick={handleExport} size="small">
      <Share />
    </IconButton>
  );
};

export const ResponsiveChartContainer = ({ changes, height, children }) => {
  const [hackyRechartsResizeHeight, setHackyRechartsResizeHeight] =
    useState("100%");

  React.useEffect(() => {
    if (hackyRechartsResizeHeight === "100%") {
      setHackyRechartsResizeHeight("99%");
    } else {
      setHackyRechartsResizeHeight("100%");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changes]);

  return (
    <ResponsiveContainer height={height} width={hackyRechartsResizeHeight}>
      {children}
    </ResponsiveContainer>
  );
};

function request(url, noCache = true) {
  return client
    .get(url, {
      headers: { ...(noCache ? { "Cache-Control": "no-cache" } : {}) },
    })
    .then(({ data }) => data);
}

function getReportUrl(path, range, filter, step, mode, compare) {
  return [
    `/portal/reports/${path}`,
    `${stringify({
      range,
      filter,
      step,
      mode,
      compare,
    })}`,
  ]
    .filter((d) => d)
    .join("?");
}

function getExportUrl(path, range, filter, step, mode) {
  return `/portal/reports/export/csv/${path}?${stringify({
    range,
    filter,
    step,
    mode,
  })}`;
}
