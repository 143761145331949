import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Pagination as RaPagination
} from "react-admin";
import { TimestampField } from "../components/fields";

export const ListTests = props => (
  <List
    bulkActionButtons={false}
    perPage={50}
    pagination={<Pagination />}
    sort={{ field: "createdAt", order: "DESC" }}
    filterDefaultValues={{ disabledTest: false }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <TimestampField label="Created" source="createdAt" />
    </Datagrid>
  </List>
);

export const ListActions = () => {
  return <div />;
};

const Pagination = props => (
  <RaPagination
    rowsPerPageOptions={[10, 25, 50, 100]}
    perPage={50}
    {...props}
  />
);
