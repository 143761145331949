import React from "react";
import { path } from "ramda";
import { Avatar } from "@material-ui/core";
import Person from "@material-ui/icons/AccountCircle";
import { nodeEnv } from "../config";

export const AvatarField = ({ record = {}, source, size = 48 }) => {
  const image = path(source.split("."), record);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {image ? (
        <Avatar
          src={getCloudImageLink(image, size)}
          style={size ? { width: size, height: size } : null}
        />
      ) : (
        <Person color="primary" style={{ fontSize: size }} />
      )}
    </div>
  );
};

function getCloudImageLink(image, size) {
  return nodeEnv === "development"
    ? image
    : `https://abkrtusetq.cloudimg.io/v7/${image}?width=${size *
        2}&height=${size * 2}&func=face&face_margin=50`;
}
