export const statuses = [
  { id: "pending", name: "Pending" },
  { id: "completed", name: "Completed" },
  { id: "closed", name: "Closed" },
];

export const computerType = "computer";
export const moduleType = "module";
export const softwareType = "software";
export const otherType = "other";

export const issueTypes = [
  { id: computerType, name: "Computer" },
  { id: moduleType, name: "Module" },
  { id: softwareType, name: "Software" },
  { id: otherType, name: "Other" },
];

export const issueTypesMapper = {
  computer: "Computer",
  module: "Module",
  software: "Software",
  other: "Other",
};
