import DistrictIcon from "@material-ui/icons/MapOutlined";
import { ListDistricts } from "./list";
import { CreateDistrict, EditDistrict } from "./create-edit";
import { ShowDistrict } from "./show";

const districts = {
  name: "districts",
  options: {
    label: "Locations"
  },
  icon: DistrictIcon,
  list: ListDistricts,
  create: CreateDistrict,
  edit: EditDistrict,
  show: ShowDistrict
};

export default districts;
