import { LocalPharmacy } from "@material-ui/icons";

import { ShowCase } from "./show";
import { CaseList } from "./list";

export const cases = {
  name: "cases",
  options: {
    label: "Treatment",
  },
  icon: LocalPharmacy,
  list: CaseList,
  show: ShowCase,
};