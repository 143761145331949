import polyglotI18nProvider from "ra-i18n-polyglot";
import { english } from "./en";
import { french } from "./fr";
import { resolveBrowserLocale } from "react-admin";

const messages = {
  fr: french,
  en: english,
};

export const availableLocales = [
  { locale: "en", name: "English" },
  { locale: "fr", name: "French" },
];

export const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  resolveBrowserLocale("en"),
  availableLocales,
  {
    allowMissing: false,
    onMissingKey: (key, _, __) => key,
  }
);
