import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  AutocompleteArrayInput,
  Button,
  FormDataConsumer,
  FormWithRedirect,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  useDataProvider,
} from "react-admin";
import { groupBy, isNil, mergeAll } from "ramda";
import { ExpandMore, Save } from "@material-ui/icons";

import { useRequestState } from "./hooks/request";
import { Conditionally } from "./components/conditionally";
import { ImageInput } from "./components/camera";
import { client } from "./providers/client";

const SettingsContext = createContext({});

export function useSettings() {
  const settings = useContext(SettingsContext);

  return settings;
}

function getSettings() {
  if (localStorage.getItem("settings")) {
    return Promise.resolve(JSON.parse(localStorage.getItem("settings")));
  }
  return client.get("/config").then((response) => {
    const settings = response.data;

    localStorage.setItem("settings", JSON.stringify(settings));

    return getSettings();
  });
}

export const SettingsProvider = (props) => {
  const settings = useRequestState(getSettings);

  useEffect(() => {
    settings.fetch();
  }, []);

  if (!settings.value) {
    return (
      <Box
        position={"fixed"}
        top={0}
        bottom={0}
        left={0}
        right={0}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <SettingsContext.Provider value={settings.value}>
      {props.children}
    </SettingsContext.Provider>
  );
};

export const Settings = () => {
  const provider = useDataProvider();
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    provider
      .getList("settings", {
        pagination: { perPage: "infinity" },
      })
      .then((response) => response.data)
      .then(setSettings);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const update = useRequestState((values) =>
    Promise.all(
      Object.entries(values).map(([key, value]) =>
        provider.update("settings", { data: { value }, id: key })
      )
    )
  );

  return (
    <Paper component={Box} padding={1}>
      <FormWithRedirect
        resource="settings"
        defaultValue={mergeAll(settings.map((x) => ({ [x.key]: x.value })))}
        save={(data) => update.fetch(data)}
        render={({ handleSubmitWithRedirect }) => {
          const groups = groupBy((x) => x.group, settings || []);
          return (
            <>
              <Box marginBottom={2}>
                {Object.entries(groups).map(([group, entries]) => (
                  <Accordion key={group}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography variant="body1">{group}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        width={"100%"}
                      >
                        <FormDataConsumer>
                          {({ formData }) =>
                            entries.map((setting) =>
                              !isNil(setting.when) &&
                              formData[setting.when[0]] !==
                                setting.when[1] ? null : (
                                <Box key={setting.key}>
                                  <Conditionally
                                    when={setting.type === "reference"}
                                    render={() => (
                                      <ReferenceInput
                                        label={setting.name}
                                        source={setting.key}
                                        reference={setting.reference}
                                        fullWidth
                                        variant="outlined"
                                      >
                                        <SelectInput
                                          optionText={setting.label || "name"}
                                        />
                                      </ReferenceInput>
                                    )}
                                  />
                                  <Conditionally
                                    when={setting.type === "reference-many"}
                                    render={() => (
                                      <ReferenceArrayInput
                                        label={setting.name}
                                        source={setting.key}
                                        reference={setting.reference}
                                        fullWidth
                                        variant="outlined"
                                        filterToQuery={(name) => ({
                                          search: {
                                            [setting.label || "name"]: name,
                                          },
                                        })}
                                      >
                                        <AutocompleteArrayInput
                                          optionText={setting.label || "name"}
                                        />
                                      </ReferenceArrayInput>
                                    )}
                                  />
                                  <Conditionally
                                    when={setting.type === "number"}
                                    render={() => (
                                      <NumberInput
                                        label={setting.name}
                                        source={setting.key}
                                        fullWidth
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                  <Conditionally
                                    when={setting.type === "text"}
                                    render={() => (
                                      <TextInput
                                        label={setting.name}
                                        source={setting.key}
                                        fullWidth
                                        variant="outlined"
                                      />
                                    )}
                                  />

                                  <Conditionally
                                    when={setting.type === "multiline-text"}
                                    render={() => (
                                      <TextInput
                                        label={setting.name}
                                        source={setting.key}
                                        fullWidth
                                        multiline
                                        minRows={3}
                                        variant="outlined"
                                      />
                                    )}
                                  />

                                  <Conditionally
                                    when={setting.type === "image"}
                                    render={() => (
                                      <ImageInput
                                        label={setting.name}
                                        source={setting.key}
                                        variant="outlined"
                                      />
                                    )}
                                  />

                                  <Conditionally
                                    when={setting.type === "select"}
                                    render={() => (
                                      <RadioButtonGroupInput
                                        label={setting.name}
                                        source={setting.key}
                                        choices={setting.choices.map(
                                          (choice) => ({
                                            id: choice,
                                            name: choice,
                                          })
                                        )}
                                        fullWidth
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Box>
                              )
                            )
                          }
                        </FormDataConsumer>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
              <Button
                variant={"contained"}
                label={"Save"}
                color={"primary"}
                onClick={handleSubmitWithRedirect}
              >
                <Save />
              </Button>
            </>
          );
        }}
      />
    </Paper>
  );
};
