import { Datagrid, List, Pagination as RaPagination } from "ra-ui-materialui";
import React from "react";
import { SelectField, ReferenceField, TextField } from "react-admin";
import { TimestampField } from "../components/fields";
import { assayTypes } from "./constants";
import moment from "moment";

export const ListAnalyses = (props) => {
  return (
    <List
      title="Data Quality"
      bulkActionButtons={false}
      perPage={50}
      pagination={<Pagination />}
      exporter={false}
      sort={{ field: "createdAt", order: "DESC" }}
      {...props}
    >
      <Datagrid rowClick="show">
        <SelectField source="assay" choices={assayTypes} optionText="name" />
        <CustomDateField source="start" showTime />
        <CustomDateField source="end" showTime />
        <ReferenceField
          label="Location"
          source="districtId"
          reference="districts"
          link="show"
          allowEmpty={true}
        >
          <TextField source="name" />
        </ReferenceField>
        <TimestampField label="Created" source="createdAt" />
      </Datagrid>
    </List>
  );
};

const Pagination = (props) => (
  <RaPagination
    rowsPerPageOptions={[10, 25, 50, 100]}
    perPage={50}
    {...props}
  />
);

const formatDate = (dateString) => {
  const date = moment(dateString);
  return date.format("D MMM YYYY");
};

const CustomDateField = ({ record, source, showTime }) => {
  const date = record[source];
  const formattedDate = formatDate(date);
  return <span>{formattedDate}</span>;
};
