import { useCallback } from "react";
import { useMutation } from "react-admin";
import { useHistory } from "react-router-dom";

export const useServerValidation = ({ type, resource, redirect }) => {
  const [mutate] = useMutation();
  const history = useHistory();
  return useCallback(
    values => {
      return mutate(
        {
          type,
          resource,
          payload: { data: values }
        },
        { returnPromise: true }
      )
        .then(() => history.goBack())
        .catch(error => {
          if (error.body.type === "ValidateError") {
            return error.body.errors;
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutate]
  );
};
