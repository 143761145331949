import React from "react";
import { useInput } from "react-admin";
import ChipInput from "material-ui-chip-input";
import { Box } from "@material-ui/core";

export const SimpleArrayField = ({ variant, label, ...props }) => {
  const { input } = useInput(props);

  return (
    <>
      <ChipInput
        {...input}
        {...{ variant, label }}
        fullWidth
        onAdd={(value) => {
          const values = input.value || [];
          input.onChange([...values, value]);
        }}
        onDelete={(value) => {
          const values = input.value || [];
          input.onChange(values.filter((v) => v !== value));
        }}
      />
      <Box marginBottom={"32px"} />
    </>
  );
};
