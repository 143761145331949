import { Avatar, Grid, Typography } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import React from "react";
import { addField, FileField } from "react-admin";

export const FileInput = addField(
  ({ input, meta: { touched, error } = {}, label, fileType }) => {
    function handleUpload(e) {
      const [selectedFile] = Array.from(e.target.files);
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = function () {
        input.onChange(reader.result);
      };
    }

    function handleDrop(e) {
      e.preventDefault();
      if (e.dataTransfer.files.length) {
        const uploadInput = document.createElement("input");
        uploadInput.type = "file";
        uploadInput.files = e.dataTransfer.files;
        uploadInput.onchange = handleUpload;
        uploadInput.dispatchEvent(new Event("change"));
      }
    }

    function handleClick() {
      const uploadInput = document.createElement("input");
      uploadInput.type = "file";
      uploadInput.onchange = handleUpload;
      uploadInput.click();
    }

    return (
      <Grid
        justifyContent="center"
        alignItems="center"
        style={{ paddingBottom: "2em" }}
        container
      >
        <Grid item xs={12}>
          <Typography variant="caption">
            {touched && error ? error : label}
          </Typography>
          {input.value ? (
            <Grid container>
              <AttachFile color="primary" />
              <Typography variant="subtitle2" color="grey">
                File attached
              </Typography>
            </Grid>
          ) : (
            <Avatar
              style={{
                width: 600,
                height: 200,
                backgroundColor: "transparent",
                border: "dashed 2px grey",
              }}
              onClick={handleClick}
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
              variant="square"
            >
              <Typography color="primary">
                Click or drag and drop
                {fileType ? ` .${fileType} file` : " here"}
              </Typography>
              <AttachFile color="primary" style={{ fontSize: 42 }} />
            </Avatar>
          )}
        </Grid>
      </Grid>
    );
  }
);

export const FileReport = ({ title, source }) => (
  <Grid container style={{ marginBottom: "12px" }}>
    <Grid item>
      <AttachFile color="primary" />
    </Grid>
    <Grid item>
      <FileField source={source} title={title} />
    </Grid>
  </Grid>
);
