import * as jwtDecode from "jwt-decode";
import { client } from "./client";

export const authProvider = {
  login,
  checkError,
  checkAuth,
  logout,
  getIdentity,
  getPermissions: authPermissions,
};

function getUser() {
  const token = localStorage.getItem("token");

  if (!token) return;

  const user = jwtDecode(token);

  return user;
}

function checkAuth(_params) {
  const token = localStorage.getItem("token");

  if (!token) {
    return Promise.reject();
  }

  return Promise.resolve();
}

function checkError(error) {
  if (error.status === 401) {
    return Promise.reject();
  }

  return Promise.resolve();
}

function login(params) {
  return client
    .post(`/auth/login`, params)
    .then((response) => response.data)
    .then((token) => {
      localStorage.setItem("token", token);
    });
}

function logout(_params) {
  localStorage.removeItem("token");
  return Promise.resolve();
}

function authPermissions() {
  const user = getUser();
  const isResetOrForgot = /(forgot|reset|passcode)/.test(window.location.pathname);

  if (!user && !isResetOrForgot) {
    return Promise.reject({});
  }

  return Promise.resolve(user || {});
}

function getIdentity() {
  const user = jwtDecode(localStorage.getItem("token"));

  if (!user) {
    return Promise.reject();
  }

  const { id, name, avatar } = user;

  const [fullName] = name.split(/\s+/);

  return Promise.resolve({ id, fullName, avatar });
}
