import * as React from "react";
import {
  createStyles,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Button, useListSortContext } from "react-admin";
import { Sort } from "@material-ui/icons";

export const SortButton = ({ fields }) => {
  const { currentSort, setSort } = useListSortContext();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeSort = (field) => {
    setSort(
      field,
      field === currentSort.field ? inverseOrder(currentSort.order) : "ASC"
    );
    setAnchorEl(null);
  };

  return (
    <>
      <Button color="primary" onClick={handleClick} size="small">
        <SortIcon order={currentSort.order} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {fields.map((field) => (
          <MenuItem onClick={() => handleChangeSort(field.id)} key={field.id}>
            <ListItemIcon>
              {currentSort.field === field.id ? (
                <SortIcon order={currentSort.order} />
              ) : (
                <Sort />
              )}
            </ListItemIcon>
            <Typography>{field.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    asc: {
      transform: "scaleY(-1)",
    },
  })
);

const SortIcon = ({ order }) => {
  const classes = useStyles();

  return <Sort className={order === "DESC" ? "" : classes.asc} />;
};

const inverseOrder = (sort) => (sort === "ASC" ? "DESC" : "ASC");
