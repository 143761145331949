import React from "react";
import { Create, Edit, SimpleForm, TextInput, NumberInput } from "react-admin";
import { required } from "react-admin";

const validateName = [required()];

export const CreateLevel = props => {
  return (
    <Create title={<CreateTitle />} {...props}>
      <LevelForm {...props} />
    </Create>
  );
};

export const EditLevel = props => {
  return (
    <Edit title="Edit District" undoable={false} {...props}>
      <LevelForm {...props} />
    </Edit>
  );
};

const CreateTitle = () => "Add Level";

const LevelForm = props => {
  return (
    <SimpleForm {...props} redirect={"list"}>
      <TextInput
        source="name"
        label="Name"
        InputLabelProps={{
          shrink: true
        }}
        fullWidth
        validate={validateName}
      />
      <NumberInput
        source="number"
        label="Number"
        InputLabelProps={{
          shrink: true
        }}
        fullWidth
        step={1}
        min={1}
        max={5}
        validate={validateName}
      />
    </SimpleForm>
  );
};
