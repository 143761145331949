import {
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  TextField
} from "react-admin";
import React from "react";
import { CardActions } from "@material-ui/core";
import { ItemField } from "../users/userTimeline";
import { StatusField } from "../components/status";
import { TimestampField } from "../components/fields";
import { DiffField } from "./diff";

export function ShowTrail(props) {
  return (
    <Show actions={<UserActions user={props.permissions} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Audit Trail">
          <ReferenceManyField
            reference="timelines"
            target="itemId"
            source="itemId"
          >
            <Datagrid rowClick={false}>
              <StatusField />
              <TextField source="user.name" label="User" />
              <ItemField label="Item" />
              <TimestampField label="Created" source="createdAt" />
              <DiffField />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export const UserActions = ({ data, basePath }) => {
  return <CardActions></CardActions>;
};
