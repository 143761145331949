// in src/MyLayout.js
import * as React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  createMuiTheme,
  Notification,
  useLogout,
  usePermissions,
  useRefresh,
  useToggleSidebar,
} from "react-admin";
import {
  Avatar,
  Box,
  Collapse,
  Drawer,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  GetApp,
  HelpOutline,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  PowerOff,
  Refresh,
  Settings,
} from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";

import { useSettings } from "../settings";
import { Conditionally } from "../components/conditionally";
import { DownloadDump } from "../components/db-dump-btn";
import { Bullboard } from "../components/bullboard";
import { superAdmin } from "../roles";
import { Menu } from "./menu";
import { MyAppBar } from "./app-bar";
import { useTheme } from "../theme";
import { LanguageSwitcher } from "../i18n/switcher";
import { relayUrl } from "../config";

export const MyLayout = ({ children, theme: themeOverride }) => {
  const themeProp = React.useRef(themeOverride);
  const [theme, setTheme] = React.useState(() => createMuiTheme(themeOverride));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [open, toggleSidebar] = useToggleSidebar();

  React.useEffect(() => {
    if (themeProp.current !== themeOverride) {
      themeProp.current = themeOverride;
      setTheme(createMuiTheme(themeOverride));
    }
  }, [themeOverride, themeProp]);

  if (isMd) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          component={"main"}
          display={"flex"}
          flexDirection={"row"}
          height={"100vh"}
          overflow={"hidden"}
        >
          <Conditionally
            when={open}
            render={() => (
              <Drawer
                open
                variant={"temporary"}
                anchor="left"
                style={{ display: "flex", flexGrow: 1, minWidth: 320 }}
                onClose={toggleSidebar}
              >
                <Sidebar />
              </Drawer>
            )}
          />

          <Box flexGrow={1} overflow={"auto"} paddingX={2} marginTop={5}>
            <Conditionally when={isMd} render={() => <MyAppBar />} />
            {children}
          </Box>
        </Box>
        <Notification />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        component={"main"}
        display={"flex"}
        flexDirection={"row"}
        height={"100vh"}
        overflow={"hidden"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          borderRight={"1px solid #eee"}
          minWidth={open ? "200px" : 0}
        >
          <Sidebar />
        </Box>
        <Box
          flexGrow={1}
          display={"flex"}
          flexDirection={"column"}
          overflow={"auto"}
          paddingX={2}
        >
          {children}
        </Box>
      </Box>
      <Notification />
    </ThemeProvider>
  );
};

export const Sidebar = () => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      overflow={"auto"}
      zIndex={1000}
      flexGrow={1}
    >
      <SidebarHeader />

      <Menu />

      <Box flexGrow={1} />

      <UserMenu />

      <Expand />
    </Box>
  );
};

const SidebarHeader = () => {
  const settings = useSettings();

  const [open] = useToggleSidebar();
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      paddingY={2}
      paddingX={1}
    >
      <Slide direction="left" in={!open} mountOnEnter unmountOnExit>
        <img
          style={{ display: "block", margin: "0 auto" }}
          src={settings["instance-icon"] || "icon.png"}
          alt="Logo"
          height="48"
        />
      </Slide>

      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <img
          style={{ display: "block" }}
          src={settings["instance-logo"] || "icon.png"}
          alt="Logo"
          height="48"
        />
      </Slide>
    </Box>
  );
};

const Expand = () => {
  const [open] = useToggleSidebar();
  const { permissions: user = {} } = usePermissions();

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      paddingX={1}
      alignItems={"flex-start"}
      justifyContent={"center"}
      width={"100%"}
    >
      <Box display={"flex"} flexDirection={"column"}>
        <RefreshButton />
        <ToggleButton />
      </Box>

      <Collapse
        in={open}
        timeout="auto"
        style={{ display: "flex" }}
        unmountOnExit
      >
        <Help />

        <DownloadRelay />

        <LanguageSwitcher />

        <Logout />

        {user?.role === superAdmin ? (
          <Box>
            <SettingsButton />

            <DownloadDump />

            <Bullboard />
          </Box>
        ) : null}
      </Collapse>
    </Box>
  );
};

const UserMenu = () => {
  const theme = useTheme();
  const [open, toggleSidebar] = useToggleSidebar();
  const { permissions: user = {} } = usePermissions();
  const history = useHistory();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  function handleClick() {
    history.push(`/users/${user?.id}/edit?profile`);

    if (isMd) {
      return toggleSidebar();
    }
  }

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"100%"}
      paddingX={1}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        paddingY={2}
        alignItems={"center"}
        onClick={handleClick}
        style={{ cursor: "pointer" }}
        flexGrow={1}
      >
        <Avatar style={{ width: 48, height: 48 }}>
          {stringAvatar(`${user.name}`)}
        </Avatar>

        <Conditionally
          when={open}
          render={() => (
            <Box paddingLeft={1} flexGrow={1}>
              <Typography variant="body1" style={{ textWrap: "nowrap" }}>
                {user.name}
              </Typography>
              <Typography variant="caption">{user.role}</Typography>
            </Box>
          )}
        />
      </Box>

      <Box flexGrow={1} />
    </Box>
  );
};

const ToggleButton = () => {
  const [open, toggle] = useToggleSidebar();
  return (
    <IconButton size="small" onClick={toggle}>
      <Conditionally when={!open} render={() => <KeyboardArrowRight />} />
      <Conditionally when={open} render={() => <KeyboardArrowLeft />} />
    </IconButton>
  );
};

const SettingsButton = () => {
  return (
    <IconButton size="small" component={Link} to={"/settings"}>
      <Settings />
    </IconButton>
  );
};

const Logout = () => {
  const logout = useLogout();
  return (
    <IconButton size="small" onClick={() => logout()}>
      <PowerOff />
    </IconButton>
  );
};

const Help = () => {
  return (
    <IconButton
      href={`https://labxpertsurveillance.tawk.help/`}
      target="_blank"
      size="small"
    >
      <HelpOutline />
    </IconButton>
  );
};

const RefreshButton = () => {
  const refresh = useRefresh();

  return (
    <IconButton size="small" onClick={refresh}>
      <Refresh />
    </IconButton>
  );
};

const DownloadRelay = () => {
  function handleDownload(e) {
    e.preventDefault();
    window.open(relayUrl, "_blank");
  }

  return (
    <IconButton size="small" onClick={handleDownload}>
      <GetApp />
    </IconButton>
  );
};

function stringAvatar(name) {
  return name.replace(/(\w)\w+/g, "$1").replace(/\s+/, "");
}
