import React, { useState, useEffect, useMemo, useRef } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useInput } from "react-admin";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Loader } from "@googlemaps/js-api-loader";
import throttle from "lodash/throttle";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const loader = new Loader({
  apiKey: "AIzaSyBQk2TCEfjnSajwWkU1cfu4aojvP5d7a2s",
  version: "weekly",
});

export const RaLocationInput = (props) => {
  const classes = useStyles();
  const { input } = useInput(props);

  const { value } = input;

  const defaultGeolocation = value?.properties;

  const [options, setOptions] = useState([defaultGeolocation]);
  const [selected, setSelected] = useState(defaultGeolocation);
  const [inputValue, setInputValue] = useState("");

  const places = useRef();

  useEffect(() => {
    if (selected?.geometry?.location) {
      const { lat, lng } = selected.geometry?.location;
      input.onChange({
        type: "Feature",
        geometry: { type: "Point", coordinates: [lng(), lat()] },
        properties: { name: selected?.name },
      });
    }
  }, [selected]);

  useEffect(() => {
    loader.importLibrary("places").then(({ PlacesService }) => {
      places.current = new PlacesService(document.createElement("div"));
    });
  }, []);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        places.current.textSearch(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!places.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(selected ? [selected] : []);
      return undefined;
    }

    fetch({ query: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (selected) {
          newOptions = [selected];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [selected, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.name
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={selected}
      onChange={(_, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setSelected(newValue);
      }}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          margin="dense"
        />
      )}
      renderOption={(option) => (
        <Grid container alignItems="center">
          <Grid item>
            <LocationOnIcon className={classes.icon} />
          </Grid>
          <Grid item xs>
            <Typography>{option?.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {option?.formatted_address}
            </Typography>
          </Grid>
        </Grid>
      )}
    />
  );
};
