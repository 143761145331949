import React, { useEffect, useState } from "react";
import {
  Show,
  ReferenceManyField,
  Pagination,
  ReferenceField,
  useNotify,
  SimpleShowLayout,
} from "react-admin";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ResultsGrid } from "../results/list";
import { useRequestState } from "../hooks/request";
import { client } from "../providers/client";
import moment from "moment";
import { UserListGrid } from "../users/list";
import { isNil } from "ramda";
import { InfoField } from "../results/show";
import { Conditionally, Switch } from "../components/conditionally";
import { admin, dho, facilityAdmin, maintenance, superAdmin } from "../roles";
import { MobileListGrid } from "../components/mobile-list-grid";
import { StackedBarChart } from "../dashboard/charts/stacked-bar";
import { HeatmapChart } from "../dashboard/charts/heatmap";
import { HorizontalBarChart } from "../dashboard/charts/horizontal-bar";
import { percent, percentInt } from "../utils/number";
import { StatusField } from "../components/status";
import { Tabular } from "../dashboard/charts/table";
import { FacilityAssignmentGrid } from "../facility-assignment/list";

const Title = ({ record }) => record && `${record.name}`;

const useStyles = makeStyles(() => ({
  maxHeight: {
    maxWidth: 500,
  },
}));

export const ShowMachine = ({ permissions, ...props }) => {
  return (
    <Show title={<Title />} actions={<div />} {...props} component="div">
      <SimpleShowLayout component="div">
        <Details user={permissions} />
        <Box marginY={1} />

        <MachineRelations permissions={permissions} />
      </SimpleShowLayout>
    </Show>
  );
};

const MachineRelations = ({ record, permissions }) => {
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    setActiveTab(record.source === "relay" ? "overview" : "results");
  }, []);

  return (
    <Card component={Box} minHeight={"1024px"}>
      <Tabs
        variant="scrollable"
        value={activeTab}
        onChange={(_, value) => setActiveTab(value)}
      >
        {record.relayType === "genexpert" ? (
          <Tab label="Overview" value="overview" />
        ) : null}

        {permissions?.role !== maintenance ? (
          <Tab label="Results" value="results" />
        ) : null}

        {record.relayType === "genexpert" ? (
          <Tab label="Logs" value="logs" />
        ) : null}

        {record.relayType === "genexpert" ? (
          <Tab label="Calibrations" value="calibrations" />
        ) : null}

        {[superAdmin, admin, maintenance, dho].includes(permissions?.role) ? (
          <Tab label="Contacts" value="contacts" />
        ) : null}

        {record.source === "relay" && permissions?.role === superAdmin ? (
          <Tab label="Relay Logs" value="relayLogs" />
        ) : null}

        {permissions?.role !== facilityAdmin ? (
          <Tab label="Installations" value="transfers" />
        ) : null}
      </Tabs>

      <Switch
        value={activeTab}
        overview={() => <Overview record={record} />}
        results={() => <Results record={record} />}
        logs={() => <Logs record={record} />}
        calibrations={() => <Calibrations record={record} />}
        contacts={() => <Contacts record={record} />}
        relayLogs={() => <RelayLogs record={record} />}
        transfers={() => <Transfers record={record} />}
      />
    </Card>
  );
};

const Details = ({ record, user }) => {
  return (
    <Paper component={Box} padding={2} style={{ margin: 0 }}>
      <Box display={"flex"} justifyContent="space-between">
        <Typography
          variant="body1"
          style={{ fontWeight: 500, marginBottom: "6px" }}
        >
          {record?.name}
        </Typography>
      </Box>

      <Box marginBottom={2}>
        <Status record={record} />
      </Box>

      <Grid container spacing={2}>
        <InfoField label={"Source"} value={record.source?.toUpperCase()} />

        <InfoField label={"Facility"} value={record.lab?.name} />

        <Grid item xs={12} md={6}>
          <Typography variant="caption">Instruments</Typography>
          <Grid container style={{ paddingTop: "4px" }} spacing={1}>
            {record.serialNumbers.map((instrument) => (
              <Grid item>
                <Chip label={instrument} size="small" />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <InfoField
          label={"First Seen"}
          value={record.createdAt && moment(record.createdAt).fromNow()}
        />

        <InfoField
          label={"Last Seen"}
          value={moment(record.lastSeenAt).fromNow()}
        />

        <InfoField
          label={"Last Uploaded"}
          value={moment(record.lastUploadedAt).fromNow()}
        />

        <InfoField
          label={"Last Tested"}
          value={
            record.lastCompletedAt && moment(record.lastCompletedAt).fromNow()
          }
        />
      </Grid>

      <Modules record={record} />
    </Paper>
  );
};

export const LegacyModules = ({ record }) => {
  return (
    <Card>
      <CardHeader title={"Modules"} />

      <CardContent>
        <MachineModules record={record} />
      </CardContent>
    </Card>
  );
};

const Overview = ({ record }) => {
  const { id } = record;
  return (
    <Grid container style={{ padding: 10 }} spacing={2}>
      <Grid item xs={12}>
        <HeatmapChart
          title="Module Activity"
          path={`activity/module`}
          location={{ id, resource: "machine" }}
          defaultRange={{ type: "last", qty: "30", unitOfTime: "day" }}
          filterByDate="completedAt"
        />
      </Grid>
      <Grid item xs={6}>
        <HorizontalBarChart
          title="Module Utilisation"
          path={`category/utilisation/module`}
          location={{ id, resource: "machine" }}
          defaultRange={{ type: "last", qty: "30", unitOfTime: "day" }}
          filterByDate="completedAt"
          format={percent}
          tickFormat={percentInt}
          modes={[
            {
              name: "type",
              type: "switch",
              items: [
                { id: "gross", name: "Gross" },
                { id: "effective", name: "Effective" },
              ],
            },
          ]}
        />
      </Grid>
      <Grid item xs={6}>
        <HorizontalBarChart
          title="Error Rates"
          path={`category/errors/module`}
          location={{ id, resource: "machine" }}
          defaultRange={{ type: "last", qty: "30", unitOfTime: "day" }}
          format={percent}
          tickFormat={percentInt}
          filterByDate="completedAt"
        />
      </Grid>
      <Grid item xs={12}>
        <StackedBarChart
          title="Connectivity"
          path={`connectivity/machine`}
          location={{ id, resource: "machine" }}
          defaultRange={{ type: "last", qty: "30", unitOfTime: "day" }}
          filterByDate="createdAt"
        />
      </Grid>
      <Grid item xs={12}>
        <StackedBarChart
          title="Testing"
          path={`testing/machine`}
          location={{ id, resource: "machine" }}
          defaultRange={{ type: "last", qty: "30", unitOfTime: "day" }}
          filterByDate="completedAt"
        />
      </Grid>
      <Grid item xs={12}>
        <StackedBarChart
          title="Logs"
          path={`logs/machine`}
          location={{ id, resource: "machine" }}
          defaultRange={{ type: "last", qty: "30", unitOfTime: "day" }}
          filterByDate="loggedAt"
        />
      </Grid>
      <Grid item xs={12}>
        <Tabular
          title={"Updates"}
          path={"category/machine/versions"}
          showTimeFilter={false}
          grid={{ xs: 12 }}
          location={{ id, resource: "machine" }}
          filterByDate="createdAt"
        />
      </Grid>
    </Grid>
  );
};

const Results = ({ record }) => {
  return (
    <ReferenceManyField
      reference="results"
      target="machineId"
      perPage={10}
      pagination={<Pagination />}
      sort={{ field: "completedAt", order: "DESC" }}
      addLabel={false}
      record={record}
    >
      <ResultsGrid />
    </ReferenceManyField>
  );
};

const Logs = ({ record }) => {
  if (record?.source !== "relay") return <div />;

  const colors = {
    0: "green",
    2: "orange",
    4: "red",
  };

  return (
    <ReferenceManyField
      reference="machine-logs"
      target="machineId"
      perPage={10}
      pagination={
        <Pagination
          rowsPerPageOptions={[10, 25, 100, 500, 1000]}
          perPage={10}
        />
      }
      sort={{ field: "loggedAt", order: "DESC" }}
      addLabel={false}
    >
      <MobileListGrid
        metadata={(record) => (
          <Grid container spacing={1}>
            <Grid item>
              <Chip size="small" color="primary" label={record.site} />
            </Grid>
            <Grid item>
              <Chip size="small" color="primary" label={record.siteSerial} />
            </Grid>
            <Grid item>
              <Chip
                size="small"
                style={{
                  backgroundColor: colors[record.errorCode] || "purple",
                  color: "white",
                }}
                label={(record.errorCode * 1000 + record.errorType)
                  ?.toString()
                  ?.padStart(4, "0")}
              />
            </Grid>
          </Grid>
        )}
        content={(record) => (
          <Typography variant="caption">{record.details}</Typography>
        )}
        right={(record) => (
          <Typography variant="caption">
            {moment(record.loggedAt).fromNow()}
          </Typography>
        )}
      />
    </ReferenceManyField>
  );
};

const Calibrations = ({ record }) => {
  if (record?.source !== "relay") return <div />;

  return (
    <ReferenceManyField
      reference="machine-calibrations"
      target="machineId"
      perPage={10}
      pagination={<Pagination />}
      sort={{ field: "name", order: "DESC" }}
      addLabel={false}
    >
      <MobileListGrid
        onClick={(record) => window.open(record.url, "_blank")}
        metadata={(record) => (
          <Typography variant="body1">
            Instrument #{record.instrument}
          </Typography>
        )}
        content={(record) => (
          <Grid container spacing={2}>
            {record.modules.map((m) => (
              <Grid item key={m.module}>
                <Box>
                  <Typography variant="caption">Module #{m.module}</Typography>
                </Box>
                <StatusField record={m} />
              </Grid>
            ))}
          </Grid>
        )}
        right={(record) => (
          <Typography variant="caption">
            {moment(record.date).format("Do MMMM YYYY")}
          </Typography>
        )}
      />
    </ReferenceManyField>
  );
};

const RelayLogs = ({ record }) => {
  const classes = useStyles();
  if (record?.source !== "relay") return <div />;

  const colors = {
    warn: "orange",
    error: "red",
  };

  return (
    <ReferenceManyField
      reference="relay-logs"
      target="machineId"
      perPage={10}
      pagination={<Pagination />}
      sort={{ field: "createdAt", order: "DESC" }}
      addLabel={false}
    >
      <MobileListGrid
        metadata={(record) => (
          <Grid container spacing={1}>
            <Grid item>
              <Chip
                size="small"
                style={{
                  backgroundColor: colors[record.level] || "purple",
                  color: "white",
                }}
                label={record.level}
              />
            </Grid>
            <Grid item>
              <Chip size="small" label={moment(record.createdAt).fromNow()} />
            </Grid>
          </Grid>
        )}
        content={(record) => {
          const message = JSON.stringify(record.data, null, 2);
          return (
            <Tooltip
              title={message}
              placement="right"
              classes={{ tooltip: classes.maxHeight }}
            >
              <Typography variant="caption">
                {message.length > 500
                  ? `${message.substring(0, 500)}...`
                  : message}
              </Typography>
            </Tooltip>
          );
        }}
      />
    </ReferenceManyField>
  );
};

const Contacts = () => {
  return (
    <ReferenceField
      reference="facilities"
      addLabel={false}
      source="facilityId"
      link={false}
    >
      <ReferenceManyField
        reference="users"
        target="facilityId"
        pagination={<Pagination />}
        addLabel={false}
      >
        <UserListGrid />
      </ReferenceManyField>
    </ReferenceField>
  );
};

const MachineModules = ({ record }) => {
  const notify = useNotify();
  const modules = useRequestState(
    () =>
      client
        .get(`/portal/machines/${record.id}/modules`)
        .then((response) => response.data?.data)
        .catch((error) => {
          notify(error?.response?.data?.message);
          return [];
        }),
    []
  );

  useEffect(() => {
    modules.fetch();
  }, []);

  return (
    <Box marginTop={1} marginBottom={2}>
      <Grid container spacing={2}>
        {modules.value.map((module) => {
          const days = moment().diff(module.lastSeen, "day");
          const color = days < 7 ? "green" : days < 30 ? "orange" : "red";
          return (
            <Grid key={module._id} item xs={12} sm={6} md={4}>
              <Paper component={Box} p={1}>
                <Box display={"flex"} justifyContent="space-between">
                  <Box>
                    <Typography variant="body1">{module._id}</Typography>
                    <Typography color="textSecondary" variant="caption">
                      {moment(module.firstSeen).fromNow()} -{" "}
                      {moment(module.lastSeen).fromNow()}
                    </Typography>
                  </Box>
                  <Box
                    borderRadius={"8px"}
                    width="16px"
                    height="16px"
                    bgcolor={color}
                  />
                </Box>

                <Box
                  display="flex"
                  marginTop={2}
                  justifyContent="space-between"
                >
                  <Box p={1}>
                    <Typography align="center" variant="h6">
                      {module.total}
                    </Typography>
                    <Typography variant="caption">Tests</Typography>
                  </Box>
                  <Box p={1}>
                    <Typography align="center" variant="h6">
                      {module.utilisation?.toFixed(2)}
                    </Typography>
                    <Typography variant="caption">Utilisation</Typography>
                  </Box>
                  <Box p={1}>
                    <Typography align="center" variant="h6">
                      {module.errorRate?.toFixed(2)}
                    </Typography>
                    <Typography variant="caption">Error Rate</Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const Status = ({ record }) => {
  if (record.relayType !== "genexpert") {
    return null;
  }

  const toFraction = (key) =>
    `${record.lastStatus?.state?.[`${key}_uploaded`] || 0}/${
      record.lastStatus?.state?.[`${key}_total`] || 0
    }`;
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Chip variant="outlined" label={record?.genexpert?.version} />
      </Grid>
      <Grid item>
        <Chip variant="outlined" label={record?.relayVersion} />
      </Grid>
      <Grid item>
        <Chip variant="outlined" label={`${toFraction("tests")} Tests`} />
      </Grid>
      <Grid item>
        <Chip variant="outlined" label={`${toFraction("logs")} Logs`} />
      </Grid>
      <Grid item>
        <Chip
          variant="outlined"
          label={`${toFraction("requests")} Test Details`}
        />
      </Grid>
      <Grid item>
        <Chip
          variant="outlined"
          label={`${toFraction("calibrations")} Calibrations`}
        />
      </Grid>
      <Grid item>
        <Chip
          variant="outlined"
          label={`${toFraction("relaylogs")} Relay Logs`}
        />
      </Grid>
      <Conditionally
        when={!isNil(record?.timeDifference)}
        render={() => (
          <Grid item>
            <Chip
              variant="outlined"
              label={
                moment().add(record.timeDifference).fromNow(true) +
                (record.timeDifference > 0 ? " ahead" : " behind")
              }
            />
          </Grid>
        )}
      />
    </Grid>
  );
};

const Modules = ({ record }) => {
  return (
    <Grid container spacing={1} component={Box} marginTop={4}>
      {Object.keys(record.genexpert?.sites || {})
        .sort()
        .map((key) => {
          const site = record.genexpert.sites[key];
          const module = record.genexpert.modules[site?.module];
          const color = isNil(module)
            ? "gray"
            : module?.disabled
            ? "red"
            : "green";
          const status = isNil(module)
            ? "EMPTY"
            : module?.disabled
            ? "DISABLED"
            : "ACTIVE";
          return (
            <Grid xs={6} md={3} item>
              <Box component={Paper} padding={1} bgcolor={color}>
                <Box
                  display={"flex"}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography style={{ color: "white" }} variant="caption">
                        {key}
                      </Typography>
                    </Grid>
                    <Conditionally
                      when={site?.module}
                      render={() => (
                        <Grid item>
                          <Typography
                            style={{ color: "white" }}
                            variant="caption"
                          >
                            {site.module}
                          </Typography>
                        </Grid>
                      )}
                    />
                  </Grid>

                  <Typography style={{ color: "white" }} variant="caption">
                    {status}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
    </Grid>
  );
};

const Transfers = ({ record }) => {
  return (
    <ReferenceManyField
      reference="facility-assignments"
      addLabel={false}
      source="serialNumbers"
      target="serial"
      link={false}
      record={record}
    >
      <FacilityAssignmentGrid />
    </ReferenceManyField>
  );
};
