import React from "react";
import { Chip, Typography } from "@material-ui/core";

export const Diagnosis = ({ name, quantitative, qualitative, unit, range }) => {
  const qty = quantitative
    ? ` ${unit ? `${quantitative} ${unit}` : `(${quantitative})`}`
    : "";

  return (
    <div>
      {name && (
        <div>
          <Typography
            variant="caption"
            color="textPrimary"
            style={{ marginBottom: 5 }}
          >
            {name}
          </Typography>
        </div>
      )}
      {qualitative ? (
        <Chip
          label={`${qualitative?.toUpperCase()}${qty}`}
          style={{
            backgroundColor: getColor(qualitative),
            color: "white"
          }}
          size="small"
        />
      ) : (
        <Chip
          label={`${qty}`}
          style={{
            backgroundColor: getQuantitativeColor(
              quantitative,
              qualitative,
              range
            ),
            color: "white"
          }}
          size="small"
        />
      )}
    </div>
  );
};

function getQuantitativeColor(quantitative, qualitative, range) {
  if (qualitative) {
    return getColor(qualitative);
  }

  if (!range) return "red";

  const [start, end] = range.split(/\s*to\s*/).map(parseFloat);

  if (quantitative > start && quantitative < end) {
    return "green";
  }

  return "green";
}

function getColor(value) {
  if (
    [
      "NOT DETECTED",
      "NEGATIVE",
      "Non Reactive",
      "Non-Reactive",
      "NEG",
      "Negative",
      "Normal",
      "NORMAL",
      "N"
    ].indexOf(value) > -1
  ) {
    return "green";
  }

  if (
    ["DETECTED HIGH", "Reactive", "H", "Positive", "POSITIVE"].indexOf(value) >
    -1
  ) {
    return "red";
  }

  if (
    ["DETECTED LOW", "DETECTED VERY LOW", "PRESUMPTIVE POS", "L"].indexOf(
      value
    ) > -1
  ) {
    return "darkorange";
  }

  if (["NO RESULT"].indexOf(value) > -1) {
    return "gray";
  }

  if (["ERROR", "INVALID"].indexOf(value) > -1) {
    return "purple";
  }

  return "red";
}
