import React from "react";
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  required,
  AutocompleteInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
} from "react-admin";
import { RaLocationInput } from "../components/ra-location-input";
import { LocationOptionRenderer } from "../districts/list";
import { facilityTypes, ownerships, settlements } from "./constants";

export const CreateFacility = (props) => (
  <Create title="Register Laboratory" {...props}>
    <FacilityForm {...props} />
  </Create>
);

export const EditFacility = (props) => (
  <Edit title="Edit Facility" {...props}>
    <FacilityForm {...props} />
  </Edit>
);

const FacilityForm = (props) => {
  return (
    <SimpleForm redirect={redirect} {...props}>
      <TextInput
        source="name"
        fullWidth
        InputLabelProps={{ shrink: true }}
        variant="outlined"
      />

      <SelectInput
        source="settlement"
        choices={settlements}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        fullWidth
        margin="dense"
      />

      <SelectInput
        source="ownership"
        choices={ownerships}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        fullWidth
        margin="dense"
      />

      <SelectArrayInput
        source="type"
        choices={facilityTypes}
        variant="outlined"
        inputProps={{ InputLabelProps: { shrink: true } }}
        fullWidth
        margin="dense"
      />

      <TextInput
        source="address"
        fullWidth
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />

      <ReferenceInput
        label="Location"
        source="districtId"
        reference="districts"
        fullWidth
        filter={{ _id: props.permissions?.collectionSiteIds }}
        perPage={100}
        InputLabelProps={{
          shrink: true,
        }}
        filterToQuery={(searchText) => {
          return {
            search: { name: searchText },
          };
        }}
        validate={[required()]}
        variant="outlined"
      >
        <AutocompleteInput
          options={{
            fullWidth: true,
            InputLabelProps: {
              shrink: true,
            },
          }}
          optionText={<LocationOptionRenderer />}
          matchSuggestion={() => true}
          inputText={(choice) => choice.name}
        />
      </ReferenceInput>

      <RaLocationInput source="geolocation" label="Geolocation" />
    </SimpleForm>
  );
};

const redirect = () => `/facilities`;
