export const installGATag = (trackingId, additionalConfigInfo = {}) => {
  const scriptId = "ga-gtag";

  if (document.getElementById(scriptId)) return;

  const { head } = document;
  const script = document.createElement("script");
  script.id = scriptId;
  script.type = "text/javascript";
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  head.insertBefore(script, head.firstChild);

  window.dataLayer = window.dataLayer || [];

  gtag("js", new Date());
  gtag("config", trackingId, additionalConfigInfo);
  gtag("event", "page_view", {
    page_title: window.location.pathname,
    page_location: window.location.href
  });
};

export const gtag = function() {
  window.dataLayer.push(arguments);
};
