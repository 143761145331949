import React from "react";
import {
  Show,
  Button,
  SimpleShowLayout,
  ReferenceManyField,
  Datagrid,
  TextField,
  Pagination,
} from "react-admin";
import { Box, Card, CardHeader, Grid } from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { StatusField } from "../../components/status";
import { TimestampField } from "../../components/fields";
import { ItemCard } from "../stock-card";
import { Tabular } from "../../dashboard/charts/table";
import { admin, dho, superAdmin } from "../../roles";
import { Conditionally } from "../../components/conditionally";

const Title = ({ record }) => record.name;

export const ShowItem = (props) => (
  <Show title={<Title />} component="div" actions={null} {...props}>
    <SimpleShowLayout>
      <ItemCard big noGutter />
      <ItemActions user={props.permissions}  />
      <StockLevels user={props.permissions} />
      <StockHistory />
    </SimpleShowLayout>
  </Show>
);

export const StockHistory = ({ record, ...props }) => {
  return (
    <Card component={Box} marginTop={2}>
      <CardHeader title={"History"} />
      <ReferenceManyField
        reference="adjustments"
        target="itemId"
        record={record}
        sort={{ field: "createdAt", order: "DESC" }}
        pagination={
          <Pagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
            {...props}
          />
        }
      >
        <Datagrid>
          <StatusField source="type" size="small" />
          <TextField source="quantity" />
          <TextField source="itemBalance" label={"Balance"} />
          <TimestampField source="date" />
          <TimestampField source="createdAt" />
        </Datagrid>
      </ReferenceManyField>
    </Card>
  );
};

export const StockPiles = ({ record, ...props }) => {
  return (
    <Card component={Box} marginTop={2}>
      <CardHeader title={"Stock Piles"} />
      <ReferenceManyField
        reference="stockpiles"
        target="itemId"
        record={record}
        sort={{ field: "createdAt", order: "DESC" }}
        filter={{ mode: "all" }}
        pagination={
          <Pagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
            {...props}
          />
        }
        link="show"
      >
        <Datagrid>
          <TextField source="lab.name" label={"Store"} />
          <TextField source="balance" label={"Balance"} />
          <TextField source="batchNumber" label={"Batch/Lot Number"} />
          <TimestampField source="expiry" />
          <TimestampField source="createdAt" />
        </Datagrid>
      </ReferenceManyField>
    </Card>
  );
};

export const StockLevels = ({ record, user }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Conditionally
          when={[superAdmin, admin, dho].includes(user?.role)}
          render={() => (
            <Tabular
              title={"Stock Levels"}
              path={"category/inventory/levels/facility"}
              showTimeFilter={false}
              filter={{ itemId: record._id }}
              modes={[
                {
                  name: "stockLevel",
                  type: "switch",
                  items: [
                    { id: "all", name: "All" },
                    { id: "gtMin", name: "Above Min" },
                    { id: "ltMin", name: "Below Min" },
                    { id: "stockout", name: "StockOut" },
                  ],
                },
              ]}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Tabular
          title={"Stock Batch/Lot By Facility"}
          path={"category/inventory/batches"}
          showTimeFilter={false}
          grid={{ xs: 12 }}
          filter={{ itemId: record._id }}
          modes={[
            {
              name: "expiration",
              type: "switch",
              items: [
                { id: 0, name: "All" },
                { id: 1, name: "Expiry 1mon" },
                { id: 3, name: "Expiry 3mon" },
                { id: 6, name: "Expiry 6mon" },
              ],
            },
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <Tabular
          title={"Stock Expiring Before Consumption"}
          path={"category/inventory/expiring-before-consumption"}
          showTimeFilter={false}
          grid={{ xs: 12 }}
          filter={{ itemId: record._id }}
        />
      </Grid>
    </Grid>
  );
};

export const ItemActions = ({ record, user }) => {
  return (
    <Box display={"flex"} padding={2} marginY={2}>
      <Conditionally
        when={user.role === superAdmin}
        render={() => (
          <>
            <Button
              component={Link}
              to={`/items/${record.id}/edit`}
              label="Edit"
              variant="contained"
              size="small"
            >
              <Edit />
            </Button>
            <Box paddingX={1} />
          </>
        )}
      />
      <Button
        component={Link}
        to={`/adjustments/create?source=${JSON.stringify({
          type: "in",
          itemId: record.id,
        })}`}
        label="Add Stock"
        variant="outlined"
        size="small"
      >
        <Add />
      </Button>
    </Box>
  );
};
