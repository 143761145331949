import { Business } from "@material-ui/icons";

import { ListFacility } from "./list";
import { CreateFacility, EditFacility } from "./create-edit";
import { ShowFacility } from "./show";

const facilities = {
  name: "facilities",
  options: {
    label: "Laboratories",
  },
  icon: Business,
  list: ListFacility,
  create: CreateFacility,
  edit: EditFacility,
  show: ShowFacility,
};

export default facilities;
