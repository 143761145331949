import { Description } from "@material-ui/icons";

import { ShowResult } from "./show";
import { ListResults } from "./list";

const results = {
  name: "results",
  options: {
    label: "Results",
  },
  icon: Description,
  show: ShowResult,
  list: ListResults,
};

export const requests = {
  name: "requests",
  options: {
    label: "Requests",
  },
};

export const cartridges = {
  name: "results/cartridges",
  options: {
    label: "Cartridges",
  },
};

export default results;
