export function formatCurrency(x = 0, currency = "UGX", decimals = 0) {
  const withCommas = formatNumber(x, decimals);
  return `${currency} ${withCommas}`;
}

export function formatCurrencyWithFree(x = 0, currency = "UGX") {
  if (!parseInt(x)) {
    return "FREE";
  }

  return formatCurrency(x, currency);
}

export function formatCurrencyRange(min, max, currency = "UGX") {
  return `${currency} ${formatNumber(min)} - ${formatNumber(max)}`;
}

export function formatNumber(x, decimals) {
  return parseFloat(x)
    ?.toFixed(decimals)
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
