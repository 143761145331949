import Timeline from "@material-ui/icons/Timeline";

import { TimelineList } from "./list";
import { ShowTrail } from "./show";

export const timelines = {
  name: "timelines",
  options: {
    label: "Audit Trail"
  },
  icon: Timeline,
  list: TimelineList,
  show: ShowTrail
};
