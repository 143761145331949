import { client } from "../providers/client";

export function requestResetPasswordLink(email) {
  return client
    .post("/auth/reset-password", { email })
    .then(response => response.data);
}

export function resetPassword(token, password) {
  return client
    .post("/auth/new-password", { token, password })
    .then(response => response.data);
}

export function adminResetPassword(email, password) {
  return client
    .post("/auth/admin-new-password", { email, password })
    .then(response => response.data);
}