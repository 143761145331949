import React from "react";
import { useAuthState } from "react-admin";
import { isNil } from "ramda";

import { MultiDashboard } from "./tabs";

const Dashboard = (props) => {
  const { loading, authenticated } = useAuthState();

  if (!authenticated || loading || isNil(props.permissions?.role)) {
    return null;
  }

  return <MultiDashboard user={props.permissions} />;
};

export default Dashboard;
