import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

import { ChartContainer } from "./container";
import { Conditionally } from "../../components/conditionally";
import { mergeAll } from "ramda";

export const SingleBarChart = ({ request, format = (d) => d, ...props }) => {
  const history = useHistory();

  return (
    <ChartContainer {...props} defaultState={{ series: [] }}>
      {({ value: { series } }) => {
        const summaries = series.filter((d) => d.highlight);
        const stats = series.filter((d) => !d.hide);
        const barStats = [
          {
            name: props.title,
            ...mergeAll(
              stats.map((stat) => ({
                [stat.identifier]: stat.value,
              }))
            ),
          },
        ];

        return (
          <>
            <Conditionally
              render={() => (
                <Grid container alignItems="center" justifyContent="flex-start">
                  {summaries.map((summary, i, all) => (
                    <Grid item xs={12 / all.length} key={i}>
                      <Box padding="0 1em">
                        <Typography
                          variant="h6"
                          align="left"
                          onClick={() =>
                            summary?.url && history.push(summary?.url)
                          }
                        >
                          {summary.value}
                        </Typography>
                        <Typography variant="caption" align="left">
                          {summary.name}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
              when={summaries.length}
            />
            <ResponsiveContainer width={"100%"} height={100}>
              <BarChart
                data={barStats}
                height={100}
                layout="vertical"
                barGap={0}
                stackOffset="expand"
              >
                <XAxis type="number" tick={false} axisLine={false} height={0} />
                <YAxis
                  type="category"
                  dataKey={"name"}
                  tick={false}
                  axisLine={false}
                  width={0}
                />
                <Tooltip formatter={format} />
                {stats.map((stat) => (
                  <Bar
                    key={stat.identifier}
                    dataKey={stat.identifier}
                    name={stat.name}
                    fill={stat.color}
                    stackId={"a"}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
            <Legend series={stats} />
          </>
        );
      }}
    </ChartContainer>
  );
};

export const Legend = ({ series }) => {
  return (
    <Box
      display={"flex"}
      flexDirection="row"
      flexWrap={"wrap"}
      marginX={1}
    >
      {series
        .filter((serie) => serie.type !== "label")
        .map((serie, i) => (
          <Box
            display={"flex"}
            alignItems="center"
            flexDirection={"row"}
            key={i}
            paddingRight="2px"
            flexGrow={1}
          >
            <Box
              bgcolor={serie.color}
              width={"16px"}
              height={"16px"}
              borderRadius={"8px"}
              display="flex"
            />
            <Box margin={"2px"} />
            <Typography variant="caption">{serie.name}</Typography>
          </Box>
        ))}
    </Box>
  );
};
