import React, { Fragment, useEffect } from "react";
import {
  AutocompleteArrayInput,
  CheckboxGroupInput,
  Create,
  Edit,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import { useRequestState } from "../hooks/request";
import { client } from "../providers/client";
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  SelectInput,
  SimpleFormIterator,
} from "react-admin";
import { range } from "ramda";
import moment from "moment";
import { Conditionally } from "../components/conditionally";
import { SimpleArrayField } from "../components/array-string-input";
import { roles } from "../roles";

export const CreateNotification = (props) => {
  return (
    <Fragment>
      <div>
        <Create title={"New Notification"} {...props}>
          <Form {...props} />
        </Create>
      </div>
    </Fragment>
  );
};

export const EditNotification = (props) => (
  <Edit title="Update Notification" {...props}>
    <Form {...props} />
  </Edit>
);

const Form = (props) => {
  const discretes = useRequestState(
    () =>
      client
        .get("/portal/reports/charts/discrete")
        .then((response) => response.data?.data),
    []
  );
  const dashboards = useRequestState(
    () =>
      client
        .get("/portal/reports/dashboards")
        .then((response) => response.data?.data),
    []
  );
  const collections = useRequestState(
    () =>
      client
        .get("/portal/notifications/collections")
        .then((response) => response.data?.data),
    []
  );

  useEffect(() => {
    discretes.fetch();
    dashboards.fetch();
    collections.fetch();
  }, []);

  return (
    <SimpleForm {...props} redirect={"show"}>
      <TextInput source="title" label="Title" variant="outlined" fullWidth />

      <RadioButtonGroupInput
        source="type"
        fullWidth
        choices={[
          { id: "alert", name: "Alert" },
          { id: "report", name: "Report" },
          { id: "event", name: "Event" },
        ]}
      />

      <FormDataConsumer>
        {({ formData }) => {
          return (
            <>
              <Conditionally
                when={formData.type === "event"}
                render={() => (
                  <CheckboxGroupInput
                    source="eventTypes"
                    fullWidth
                    choices={[
                      { id: "create", name: "Create" },
                      { id: "update", name: "Update" },
                      { id: "delete", name: "Delete" },
                    ]}
                  />
                )}
              />

              <CheckboxGroupInput
                source="channels"
                fullWidth
                choices={[
                  { id: "email", name: "Email" },
                  { id: "webhook", name: "Webhook" },
                  ...(formData.type !== "report"
                    ? [{ id: "sms", name: "SMS" }]
                    : []),
                ]}
              />

              <Conditionally
                when={formData.channels?.includes("email")}
                render={() => (
                  <>
                    <TextInput
                      source="emailSubject"
                      label="Email Subject"
                      variant="outlined"
                      fullWidth
                    />
                    <TextInput
                      source="emailBody"
                      label="Email Body"
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={5}
                    />
                  </>
                )}
              />

              <Conditionally
                when={formData.channels?.includes("webhook")}
                render={() => (
                  <SimpleArrayField
                    source="webhookUrls"
                    label="Webhook Url"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />

              <Conditionally
                when={
                  formData.channels?.includes("sms") &&
                  formData.type !== "report"
                }
                render={() => (
                  <TextInput
                    source="smsBody"
                    label="SMS Message"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={5}
                  />
                )}
              />

              <CheckboxGroupInput
                source="recipentTypes"
                label="Type of Recipients"
                fullWidth
                choices={[
                  { id: "adhoc", name: "Ad-hoc Recipients" },
                  { id: "users", name: "Users" },
                  { id: "roles", name: "Roles" },
                  ...(formData.type === "event"
                    ? [{ id: "field", name: "From A Field" }]
                    : []),
                ]}
              />

              <Conditionally
                when={formData.recipentTypes?.includes("adhoc")}
                render={() => (
                  <SimpleArrayField
                    source="adhocRecipients"
                    label="Ad-hoc Recipients"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />

              <Conditionally
                when={formData.recipentTypes?.includes("users")}
                render={() => (
                  <ReferenceArrayInput
                    label="Users"
                    source="userRecipients"
                    reference="users"
                    variant="outlined"
                    filterToQuery={(name) => ({ search: { name } })}
                    fullWidth
                  >
                    <AutocompleteArrayInput optionText="name" />
                  </ReferenceArrayInput>
                )}
              />

              <Conditionally
                when={formData.recipentTypes?.includes("roles")}
                render={() => (
                  <CheckboxGroupInput
                    source="roleRecipients"
                    label="Roles"
                    fullWidth
                    choices={roles}
                  />
                )}
              />

              <Conditionally
                when={formData.recipentTypes?.includes("field")}
                render={() => (
                  <TextInput
                    source="fieldRecipients"
                    label="Recipient Field"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />

              <Conditionally
                when={formData.type === "alert"}
                render={() => (
                  <>
                    <RadioButtonGroupInput
                      source="alertFrequency"
                      fullWidth
                      choices={[
                        { id: "hourly", name: "Hourly" },
                        { id: "daily", name: "Daily" },
                        { id: "weekly", name: "Weekly" },
                      ]}
                    />

                    <Conditionally
                      when={["weekly"].includes(formData?.alertFrequency)}
                      render={() => (
                        <RadioButtonGroupInput
                          source="alertDay"
                          fullWidth
                          choices={range(1, 8).map((i) => ({
                            id: i,
                            name: moment().day(i).format("ddd"),
                          }))}
                        />
                      )}
                    />

                    <Conditionally
                      when={["weekly", "daily"].includes(
                        formData?.alertFrequency
                      )}
                      render={() => (
                        <RadioButtonGroupInput
                          source="alertTime"
                          fullWidth
                          choices={range(7, 18).map((i) => ({
                            id: i,
                            name: moment().hour(i).minute(0).format("HH:mm"),
                          }))}
                        />
                      )}
                    />

                    <AutocompleteInput
                      source="alertAggregation"
                      choices={discretes.value?.map((chart) => ({
                        id: chart.id,
                        name: chart.id,
                      }))}
                      variant="outlined"
                      fullWidth
                    />

                    <ArrayInput source="alertConditions">
                      <SimpleFormIterator disableReordering>
                        <SelectInput
                          label="When"
                          source={"field"}
                          fullWidth
                          choices={
                            discretes.value
                              ?.find(
                                (chart) =>
                                  chart.id === formData.alertAggregation
                              )
                              ?.series?.map((serie) => ({
                                id: serie.identifier,
                                name: serie.name,
                              })) || []
                          }
                          variant="outlined"
                        />
                        <SelectInput
                          label="Is"
                          source="comparator"
                          fullWidth
                          choices={[
                            { id: "gt", name: "Greater Than" },
                            { id: "gte", name: "Greater Than Or Equal" },
                            { id: "eq", name: "Equal" },
                            { id: "neq", name: "Not Equal" },
                            { id: "lt", name: "Less Than" },
                            { id: "lte", name: "Less Than Or Equal" },
                          ]}
                          variant="outlined"
                        />
                        <TextInput
                          source="value"
                          label="To"
                          variant="outlined"
                          fullWidth
                        />
                      </SimpleFormIterator>
                    </ArrayInput>
                  </>
                )}
              />

              <Conditionally
                when={formData.type === "report"}
                render={() => (
                  <>
                    <CheckboxGroupInput
                      label="Frequency"
                      source="reportFrequency"
                      fullWidth
                      choices={[
                        { id: "daily", name: "Daily" },
                        { id: "weekly", name: "Weekly" },
                        { id: "monthly", name: "Monthly" },
                        { id: "quarterly", name: "Quarterly" },
                        { id: "yearly", name: "Yearly" },
                      ]}
                    />

                    <AutocompleteInput
                      label="Dashboard"
                      source="reportDashboard"
                      choices={dashboards.value?.map((dashboard) => ({
                        id: dashboard.id,
                        name: dashboard.title,
                      }))}
                      variant="outlined"
                      fullWidth
                    />
                  </>
                )}
              />

              <Conditionally
                when={formData.type === "event"}
                render={() => (
                  <>
                    <AutocompleteInput
                      label="Collection"
                      source="eventCollection"
                      variant="outlined"
                      choices={collections.value}
                      fullWidth
                    />

                    <SimpleArrayField
                      label="Populate"
                      source="eventPopulate"
                      variant="outlined"
                      fullWidth
                    />

                    <EventConditions
                      source={"eventConditions"}
                      label="Conditions"
                    />
                  </>
                )}
              />
            </>
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  );
};

const EventConditions = ({ source, label, record }) => {
  return (
    <ArrayInput source={source} label={label} record={record}>
      <SimpleFormIterator disableReordering>
        <TextInput source="field" label="When" variant="outlined" fullWidth />

        <SelectInput
          source="comparator"
          label="Is"
          choices={[
            { id: "gt", name: "Greater Than" },
            { id: "gte", name: "Greater Than Or Equal" },
            { id: "lt", name: "Less Than" },
            { id: "lte", name: "Less Than Or Equal" },
            { id: "eq", name: "Equal" },
            { id: "neq", name: "Not Equal" },
            { id: "in", name: "One Of" },
            { id: "elm", name: "Match Element" },
          ]}
          variant="outlined"
          fullWidth
        />

        <FormDataConsumer>
          {({ scopedFormData, getSource }) => (
            <>
              <Conditionally
                when={scopedFormData?.comparator === "eq"}
                render={() => (
                  <EqualityConditionInput
                    source={getSource("value")}
                    record={scopedFormData}
                  />
                )}
              />
              <Conditionally
                when={scopedFormData?.comparator === "in"}
                render={() => (
                  <InConditionInput
                    source={getSource("value")}
                    record={scopedFormData}
                  />
                )}
              />
              <Conditionally
                when={scopedFormData?.comparator === "elm"}
                render={() => (
                  <EventConditions
                    label={"Element Conditions"}
                    source={getSource("value")}
                    record={scopedFormData}
                  />
                )}
              />
              <Conditionally
                when={[].includes(scopedFormData?.comparator)}
                render={() => (
                  <NumericConditionInput
                    source={getSource("value")}
                    record={scopedFormData}
                  />
                )}
              />
            </>
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const NumericConditionInput = ({ record, source }) => (
  <TextInput
    source={source}
    label="Value"
    record={record}
    variant="outlined"
    fullWidth
  />
);

const EqualityConditionInput = ({ record, source }) => (
  <TextInput
    source={source}
    label="Value"
    record={record}
    variant="outlined"
    fullWidth
  />
);

const InConditionInput = ({ record, source }) => (
  <SimpleArrayField
    source={source}
    label="Possible Values"
    record={record}
    variant="outlined"
  />
);
