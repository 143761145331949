import * as React from "react";
import { Button, TextField, Card, CardContent } from "@material-ui/core";
import { AuthLayout } from "./layout";
import { client } from "../providers/client";
import { useRequestState } from "../hooks/request";
import { CustomizedSnackbar } from "../components/customized-snack-bar";

export const ForgotPassword = () => {
  const emailReset = useRequestState(resetEmail);
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("success");

  function onSubmit() {
    emailReset
      .fetch(email)
      .then(data => {
        setMessage(data.message);
      })
      .catch(e => {
        setMessage(e.response.data.message);
        setSeverity("error");
      });
  }

  return (
    <>
      <AuthLayout>
        <Card raised>
          <CardContent>
            <TextField
              type="email"
              name="email"
              placeholder="Enter your email address"
              label="Email Address"
              margin="normal"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              onChange={event => setEmail(event.target.value)}
              value={email}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={onSubmit}
            >
              Confirm
            </Button>
          </CardContent>
        </Card>
      </AuthLayout>
      <CustomizedSnackbar severity={severity} message={message} />
    </>
  );
};

function resetEmail(email) {
  return client
    .post("auth/reset-password", {
      email
    })
    .then(response => response.data);
}
