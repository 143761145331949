import { useLocale, useSetLocale } from "react-admin";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { availableLocales } from "../i18n";
import React, { useState } from "react";
import { Language } from "@material-ui/icons";

export const LanguageSwitcher = () => {
  const locale = useLocale();
  const setLocale = useSetLocale();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(false);
  }

  function handleSelect(value) {
    setLocale(value);
    handleClose();
  }

  return (
    <>
      <IconButton onClick={handleClick} title={locale} size="small">
        <Language />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {availableLocales.map((l) => (
          <MenuItem onClick={() => handleSelect(l.locale)}>{l.name}</MenuItem>
        ))}
      </Menu>
    </>
  );
};
