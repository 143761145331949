import { Chip, Grid } from "@material-ui/core";
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  SelectInput,
  Pagination as RaPagination,
  ReferenceInput,
  TextInput,
  FunctionField,
} from "react-admin";
import { Conditionally } from "../components/conditionally";
import { TimestampField } from "../components/fields";
import { StatusField } from "../components/status";
import { ItemField } from "../users/userTimeline";
import { DiffField } from "./diff";

export const TimelineList = ({ permissions, ...props }) => (
  <List
    title="Timeline"
    filters={<TimelineFilter />}
    bulkActionButtons={false}
    perPage={50}
    pagination={<Pagination />}
    sort={{ field: "createdAt", order: "DESC" }}
    {...props}
  >
    <Datagrid rowClick={"show"}>
      <StatusField />
      <ItemField label="Item" />
      <TimestampField label="Created" source="createdAt" />
      <FunctionField
        render={(record) => (
          <>
            <Conditionally
              when={["sms", "email"].includes(record.payload?.type)}
              render={() => (
                <Grid container spacing={1}>
                  <Grid item>
                    <Chip
                      variant="outlined"
                      label={record.payload?.type?.toUpperCase()}
                    />
                  </Grid>
                  {
                    <Conditionally
                      when={permissions?.permissions?.user.includes("ppi")}
                      render={() =>
                        (Array.isArray(record.payload.destination)
                          ? record.payload.destination
                          : [record.payload.destination]
                        ).map((x) => (
                          <Grid item>
                            <Chip variant="outlined" label={x.email || x} />
                          </Grid>
                        ))
                      }
                    />
                  }
                  <Grid item>
                    <Chip variant="outlined" label={record.payload.subject} />
                  </Grid>
                </Grid>
              )}
            />
            <Conditionally
              when={!record.payload}
              render={() => <DiffField record={record} />}
            />
          </>
        )}
      />
      <TextField source="user.name" label="Actor" />
    </Datagrid>
  </List>
);

const Pagination = (props) => (
  <RaPagination
    rowsPerPageOptions={[10, 25, 50, 100]}
    perPage={50}
    {...props}
  />
);

const TimelineFilter = ({ user, ...props }) => {
  return (
    <Filter {...props}>
      <ReferenceInput reference="users" source="userId">
        <SelectInput />
      </ReferenceInput>
      <SelectInput
        source="payload.type"
        label="Notification"
        choices={[
          { id: "sms", name: "SMS" },
          { id: "email", name: "Email" },
        ]}
      />
      <TextInput source="status" label="Status" />
      <TextInput source="itemType" label="Resource" />
      <TextInput source="diffs.path" label="Path" />
    </Filter>
  );
};
