import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import moment from "moment";

import { ChartContainer } from "./container";

export const StackedBarChart = ({
  defaultStep = "day",
  format = (d) => d,
  tickFormat = (d) => d,
  ...props
}) => {
  return (
    <ChartContainer {...props} defaultState={{ series: [] }}>
      {({ value: { series, stats } }) => {
        return (
          <ResponsiveContainer width={"100%"} height={160}>
            <BarChart
              data={stats}
              height={150}
              layout="horizontal"
              barGap={8}
              barCategoryGap={2}
            >
              <XAxis
                dataKey="_id"
                tickFormatter={(unixTime) => moment(unixTime).format("Do MMM")}
                type="category"
                width={0}
              />
              <YAxis type="number" tick={false} axisLine={false} width={0} />
              <Tooltip
                formatter={format}
                labelFormatter={(d) => moment(d).format("Do MMM YY")}
              />
              <Legend iconSize={"8"} />
              {series.map((serie) => (
                <Bar
                  dataKey={serie.identifier}
                  name={serie.name}
                  fill={serie.color}
                  stackId={"a"}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        );
      }}
    </ChartContainer>
  );
};
