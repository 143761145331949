import React, { Fragment } from "react";
import {
  AutocompleteInput,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput
} from "react-admin";
import { required } from "react-admin";
import { LocationOptionRenderer } from "./list";

const validateName = [required()];

export const CreateDistrict = props => {
  return (
    <Fragment>
      <div>
        <Create title={<CreateTitle />} {...props}>
          <DistrictForm {...props} />
        </Create>
      </div>
    </Fragment>
  );
};

export const EditDistrict = props => {
  return (
    <Edit title="Edit District" undoable={false} {...props}>
      <DistrictForm {...props} />
    </Edit>
  );
};

const CreateTitle = () => "Add Location";

const DistrictForm = props => {
  return (
    <SimpleForm {...props} redirect={"show"}>
      <TextInput
        source="name"
        label="Name"
        InputLabelProps={{
          shrink: true
        }}
        fullWidth
        variant="outlined"
        validate={validateName}
      />

      <ReferenceInput
        label="Level"
        source="levelId"
        reference="levels"
        fullWidth
        validate={[required()]}
        variant="outlined"
      >
        <SelectInput optionText={"name"} />
      </ReferenceInput>

      <ReferenceInput
        label="Location"
        source="parentId"
        reference="districts"
        fullWidth
        perPage={100}
        InputLabelProps={{
          shrink: true,
        }}
        filterToQuery={(searchText) => {
          return {
            search: { name: searchText },
          };
        }}
        validate={[required()]}
        variant="outlined"
      >
        <AutocompleteInput
          options={{
            fullWidth: true,
            InputLabelProps: {
              shrink: true,
            },
          }}
          optionText={<LocationOptionRenderer />}
          matchSuggestion={() => true}
          inputText={(choice) => choice.name}
        />
      </ReferenceInput>
    </SimpleForm>
  );
};
