import React from "react";
import { List, Datagrid, TextField } from "react-admin";

export const ListLevels = ({ permissions, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "number", order: "ASC" }}
  >
    <Datagrid rowClick="show">
      <TextField label="Name" source="name" />
      <TextField label="Number" source="number" />
    </Datagrid>
  </List>
);
