import { Box, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import {
  AutocompleteInput,
  FormWithRedirect,
  ReferenceInput,
  useDataProvider,
} from "react-admin";

const perPage = 100;

export const BulkEditMachines = () => {
  const provider = useDataProvider();
  const [machines, setMachines] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  function list() {
    return provider
      .getList("machines", {
        pagination: { perPage, page },
        sort: { field: "name", order: "ASC" },
      })
      .then((response) => {
        setTotal(response.total);
        setMachines(response.data);
      });
  }

  function update(id, { facilityId }) {
    return provider.update("machines", {
      id,
      data: { facilityId },
    });
  }

  useEffect(() => {
    list();
  }, [page]);

  return (
    <Paper>
      <Box padding={1}>
        <Pagination
          page={page}
          onChange={(_, v) => setPage(v)}
          count={Math.ceil(total / perPage)}
        />
      </Box>
      {machines.map((machine) => (
        <MachineAssigner key={machine.id} machine={machine} onUpdate={update} />
      ))}
      <Box padding={1}>
        <Pagination
          page={page}
          onChange={(_, v) => setPage(v)}
          count={Math.ceil(total / perPage)}
        />
      </Box>
    </Paper>
  );
};

export const MachineAssigner = ({ machine, onUpdate }) => {
  return (
    <FormWithRedirect
      record={machine}
      save={(data) => onUpdate(machine.id, data)}
      resource="machines"
      render={({ handleSubmitWithRedirect }) => {
        return (
          <Grid
            container
            component={Box}
            spacing={1}
            padding={1}
            borderBottom="1px solid #eee"
            alignItems={"center"}
          >
            <Grid item md={5}>
              <Typography>{machine?.name}</Typography>
            </Grid>

            <Grid item md={6}>
              <ReferenceInput
                source="facilityId"
                reference="facilities"
                filterToQuery={(name) => ({ search: { name } })}
                variant="outlined"
              >
                <AutocompleteInput optionText="name" fullWidth helperText="" />
              </ReferenceInput>
            </Grid>

            <Grid item md={1}>
              <IconButton onClick={handleSubmitWithRedirect}>
                <Check />
              </IconButton>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};
