export const facilityTypes = [
  { id: "Testing Center", name: "Testing Center" },
  { id: "Collection Center", name: "Collection Center" },
  { id: "Treatment Center", name: "Treatment Center" },
];

export const settlements = [
  { id: "Urban", name: "Urban" },
  { id: "Rural", name: "Rural" },
];

export const ownerships = [
  { id: "Public/Government", name: "Public/Government" },
  { id: "Private For Profit", name: "Private For Profit" },
  { id: "Private Not for Profit", name: "Private Not for Profit" },
  { id: "Other Government", name: "Other Government" },
];
