import React from "react";

export const Conditionally = ({ when, render, ...props }) => {
  return <>{!!when ? render(props) : null}</>;
};

export const Switch = ({ value, ...renderFns }) => {
  const renderFn = renderFns[value];

  if (!renderFn) {
    return null;
  }

  return renderFn();
};
