import { Book } from "@material-ui/icons";

export const inventoryBatches = {
  name: "stockpiles",
};

export const inventoryLevels = {
  name: "stocklevels",
  options: {
    label: "Stock",
  },
  icon: Book,
};
