import React from "react";
import { Typography, Breadcrumbs as RaBreadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";

export const Breadcrumbs = props => {
  return (
    <RaBreadcrumbs aria-label="breadcrumb">
      {props.items.map((item, index) => {
        if (item.pathname) {
          return (
            <Link
              key={index}
              color="inherit"
              to={{
                pathname: item.pathname
              }}
            >
              <Typography color="textSecondary">{item.name}</Typography>
            </Link>
          );
        }

        return (
          <Typography key={index} color="textPrimary">
            {item.name}
          </Typography>
        );
      })}
    </RaBreadcrumbs>
  );
};
