import React from "react";
import { Typography, Grid, Box } from "@material-ui/core";
import { green, grey, orange } from "@material-ui/core/colors";
import moment from "moment";

export function MachineName({ record }) {
  if (!record) {
    return <div />;
  }

  return (
    <Grid container direction="column">
      <Typography variant="body1">{record.lab?.name}</Typography>
      <Typography variant="caption" color="textSecondary">
        {record.name.toUpperCase()}
      </Typography>
    </Grid>
  );
}

export const MachineStatus = ({ record }) => {
  return (
    <Box display={"flex"} flexDirection="column" alignItems="center">
      <span
        style={{
          display: "inline-block",
          width: "32px",
          height: "32px",
          margin: "2px 5px",
          borderRadius: "16px",
          background: getStatusColor(record.lastSeenAt),
        }}
      />
      <Typography variant="caption">{getStatus(record.lastSeenAt)}</Typography>
    </Box>
  );
};

export function getStatus(timestamp) {
  const date = moment(timestamp);
  const now = moment();

  if (date.isAfter(moment().subtract(100, "seconds"))) {
    return now.diff(date, "seconds") + "s";
  }

  if (date.isAfter(moment().subtract(60, "minutes"))) {
    return now.diff(date, "minutes") + "m";
  }

  if (date.isAfter(moment().subtract(24, "hours"))) {
    return now.diff(date, "hours") + "h";
  }

  return now.diff(date, "days") + "d";
}

export function getStatusColor(timestamp) {
  const date = moment(timestamp);

  // must match backends's onlineThresholdInDays
  if (date.isAfter(moment().subtract(15, "days"))) {
    return green["500"];
  }

  // must match backends's warningThresholdInDays
  if (date.isAfter(moment().subtract(20, "days"))) {
    return orange["500"];
  }

  return grey["500"];
}
