import React from "react";
import { HourglassEmpty } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

import { backendUrl } from "../config";

export const Bullboard = () => {
  function handleDownload() {
    window.open(`${backendUrl}/bullboard`);
  }

  return (
    <IconButton size="small" onClick={handleDownload}>
      <HourglassEmpty />
    </IconButton>
  );
};
