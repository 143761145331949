import React from "react";
import {
  List,
  Filter,
  Pagination as RaPagination,
  ReferenceField,
  TextField,
} from "react-admin";
import { MobileListGrid } from "../components/mobile-list-grid";
import { TimestampField } from "../components/fields";
import { Chip, Grid, Typography } from "@material-ui/core";
import { StatusField } from "../components/status";

export const CaseList = (props) => (
  <List
    title="Treatment Cases"
    actions={false}
    filters={<CaseFilter />}
    bulkActionButtons={false}
    perPage={50}
    pagination={<Pagination />}
    sort={{ field: "createdAt", order: "DESC" }}
    {...props}
  >
    <CaseListGrid />
  </List>
);

export const CaseListGrid = (props) => (
  <MobileListGrid
    {...props}
    metadata={(record) => (
      <Grid container spacing={1}>
        <Grid item>
          <Chip variant="outlined" label={`#${record.caseNumber}`} />
        </Grid>

        <Grid item>
          <StatusField record={record} />
        </Grid>
      </Grid>
    )}
    content={(record) => {
      return (
        <>
          <Typography variant="body1">
            {record?.patient?.name?.toUpperCase()}
          </Typography>
          <ReferenceField
            source="treatmentFacilityId"
            reference="facilities"
            record={record}
          >
            <TextField source="name" />
          </ReferenceField>
        </>
      );
    }}
    link={(record) => `/cases/${record.id}/show`}
    right={(record) => (
      <TimestampField record={record} label="Created" source="createdAt" />
    )}
  />
);

const Pagination = (props) => (
  <RaPagination
    rowsPerPageOptions={[10, 25, 50, 100]}
    perPage={50}
    {...props}
  />
);

const CaseFilter = ({ user, ...props }) => {
  return <Filter {...props}></Filter>;
};
