export const questionFormats = [
  "Text",
  "Multiline Text",
  "Number",
  "Radio Buttons",
  "Checkboxes",
  "Dropdown",
  "Date",
  "Time",
  "Date Time",
  "Priority",
  "Country",
  "Image"
].map(id => ({ id, name: id }));

export const parameterTypes = ["Quantitative", "Qualitative"].map(id => ({
  id,
  name: id
}));

export const testMethods = [
  "RDT",
  "Microscopy",
  "PCR",
  "Biochemistry",
  "CBC",
  "Electrophoresis",
  "Immunology"
].map(id => ({ id, name: id }));

export const referenceRangeConditionTypes = [
  "Request",
  "Patient",
  "Sample"
].map(id => ({ id, name: id }));

export const referenceRangeRequestConditionFieldFormats = [
  "Text",
  "Number",
  "Radio Buttons",
  "Checkboxes",
  "Dropdown",
  "Date",
  "Time",
  "Country",
  "Date Time"
];

export const selectionFieldsComparators = ["=", "!="].map(id => ({
  id,
  name: id
}));

export const quantitativeFieldsComaparators = [
  "=",
  ">",
  ">=",
  "<",
  "<="
].map(id => ({ id, name: id }));

export const quantitativeFields = [
  "Number",
  "Date",
  "Time",
  "Date Time",
  "dateOfBirth",
  "age"
];

export const referenceRangePatientConditionFields = ["age", "gender"];

export const referenceRangeSampleConditionFields = ["type"];

export const sampleTypeOptions = [
  "Blood",
  "Cerebrospinal Fluid",
  "Amniotic Fluid",
  "Urine",
  "Stool",
  "Sputum",
  "Saliva",
  "Semen",
  "Nasopharyngeal Swab",
  "Throat Swab",
  "Cervical Swab",
  "Vaginal Swab",
  "Bone Marrow Aspirate or biopsy",
  "Synovial Fluid",
  "Peritoneal Fluid",
  "Pleural Fluid",
  "Pericardial Fluid",
  "Endometrial Tissue",
  "Wound/Sore Swab"
];

export const selectionFields = [
  "Radio Buttons",
  "Checkboxes",
  "Dropdown",
  "gender",
  "type"
];
