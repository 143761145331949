import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  CircularProgress,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Grid,
  Chip,
  IconButton,
  makeStyles,
  Button,
} from "@material-ui/core";
import { ChartContainer } from "./container";
import { Conditionally } from "../../components/conditionally";
import { Launch } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import { client } from "../../providers/client";

const useStyles = makeStyles({
  container: {
    "@media print": {
      height: "auto",
    },
    height: "320px",
  },
});

export const Tabular = (props) => {
  return (
    <ChartContainer
      defaultState={{
        series: [],
        stats: [],
      }}
      {...props}
    >
      {({ value, loading }) => <TablePresenter {...value} loading={loading} />}
    </ChartContainer>
  );
};

const TablePresenter = ({ category, series, stats, loading }) => {
  const containerRef = useRef();
  const [maxWidth, setMaxWidth] = useState(0);
  const styles = useStyles();
  const history = useHistory();

  useEffect(() => {
    setMaxWidth(containerRef.current.clientWidth);
  }, [containerRef.current]);

  return (
    <div ref={containerRef}>
      <Conditionally
        when={stats.length > 0}
        render={() => (
          <TableContainer
            style={{
              maxWidth,
              overflow: "auto",
            }}
            className={styles.container}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {[category, ...series].map((serie, i) => (
                    <TableCell key={i}>{serie.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {stats.map((stat, i) => (
                  <TableRow key={i}>
                    {[category, ...series].map((serie, j) => (
                      <TableCell key={j} style={{ whiteSpace: "nowrap" }}>
                        <Grid
                          container
                          style={{ paddingTop: "4px" }}
                          spacing={1}
                        >
                          <Conditionally
                            when={serie.type === "list"}
                            render={() =>
                              stat[serie.identifier]?.map((serial) => (
                                <Grid item key={serial}>
                                  <Chip label={serial} size="small" />
                                </Grid>
                              ))
                            }
                          />
                          <Conditionally
                            when={serie.type === "listItem"}
                            render={() => (
                              <Grid item>
                                <Chip
                                  label={stat[serie.identifier]}
                                  variant={"outlined"}
                                  size="small"
                                />
                              </Grid>
                            )}
                          />
                          <Conditionally
                            when={serie.type === "link"}
                            render={() => (
                              <IconButton
                                to={stat[serie.identifier]}
                                component={Link}
                              >
                                <Launch />
                              </IconButton>
                            )}
                          />
                          <Conditionally
                            when={serie.type === "action"}
                            render={() => (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  const config = stat[serie.identifier];
                                  return client
                                    .request(config)
                                    .then((response) => response.data)
                                    .then((link) => history.push(link));
                                }}
                              >
                                {serie.actionText}
                              </Button>
                            )}
                          />
                          <Conditionally
                            when={!serie.type}
                            render={() => stat[serie.identifier]}
                          />
                        </Grid>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      />

      <Conditionally
        render={() => (
          <Box
            height={"230px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Box>
        )}
        when={loading && stats.length === 0}
      />

      <Conditionally
        render={() => (
          <Box
            height={"230px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography variant={"body1"}>No data for this period.</Typography>
          </Box>
        )}
        when={!loading && !stats.length}
      />
    </div>
  );
};
