export const statuses = [
  { id: "completed", name: "Completed" },
  { id: "approved", name: "Approved" },
  { id: "rejected", name: "Rejected" },
];

export const trace = ["MTB Trace"];
export const mtb = ["MTB"];

export const rifResistance = ["RIF Resistance", "Rif Resistance"];

export const xdResistance = [
  "INH Resistance",
  "Low FLQ Resistance",
  "AMK Resistance",
  "KAN Resistance",
  "CAP Resistance",
  "ETH Resistance",
];

export const detectedValues = [
  "DETECTED HIGH",
  "DETECTED MEDIUM",
  "DETECTED LOW",
  "DETECTED",
  "DETECTED VERY LOW",
];

export const presumptiveValues = ["PRESUMPTIVE POS"];

export const nonDetectedValues = ["NOT DETECTED"];

export const noResult = "NO RESULT";

export const invalid = "INVALID";

export const error = "ERROR";

export const errorValues = [error, invalid, noResult];

export const indeterminateValues = ["INDETERMINATE"];

export const diagnosisOptions = [
  {
    value: {
      name: mtb,
      "value.qualitative": detectedValues,
    },
    name: "MTB Positive",
  },
  {
    value: {
      name: trace,
      "value.qualitative": [...detectedValues, ...nonDetectedValues ],
    },
    name: "MTB Trace",
  },
  {
    value: {
      name: mtb,
      "value.qualitative": nonDetectedValues,
    },
    name: "MTB Negative",
  },
  {
    value: {
      name: "MTB",
      "value.qualitative": noResult,
    },
    name: "No Result",
  },
  {
    value: {
      "value.qualitative": invalid,
    },
    name: "Invalid",
  },
  {
    value: {
      "value.qualitative": error,
    },
    name: "Error",
  },
  {
    value: {
      name: rifResistance,
      "value.qualitative": detectedValues,
    },
    name: "RR Positive",
  },
  {
    value: {
      name: xdResistance,
      "value.qualitative": detectedValues,
    },
    name: "XDR Positive",
  },
  {
    value: {
      name: rifResistance,
      "value.qualitative": indeterminateValues,
    },
    name: "RR Indeterminate",
  },
];
