import { Show, SimpleShowLayout, FunctionField } from "react-admin";
import React from "react";
import { Typography, Grid } from "@material-ui/core";
import DistrictIcon from "@material-ui/icons/MapOutlined";
import Map, { Layer, Source } from "react-map-gl";
import { bbox } from "../turf";

export const ShowDistrict = (props) => {
  return (
    <Show {...props} title={<Title />}>
      <SimpleShowLayout>
        <FunctionField
          className="district"
          addLabel={false}
          render={(record) => {
            const boundingBox =
              record?.geometry?.geojson && bbox(record?.geometry?.geojson);

            return (
              <>
                <Grid container spacing={4}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <District record={record} />
                  </Grid>
                </Grid>

                {record?.geometry?.geojson ?
                  <Map
                    style={{ width: "100%", height: 400 }}
                    mapboxAccessToken={
                      "pk.eyJ1IjoiZXJuZXN0b28iLCJhIjoiY2w1azhydHBoMDdwZTNvbnhndHpybHk3OSJ9.iKMRDef7z1SaYsCT4Z2skQ"
                    }
                    mapStyle="mapbox://styles/ernestoo/cl5kmqzko008i14nvefbcudzo"
                    bounds={boundingBox}
                  >
                    <Source type="geojson" data={record.geometry.geojson}>
                      <Layer
                        id="data"
                        type="fill"
                        paint={{ "fill-color": "blue", "fill-opacity": 0.2 }}
                      />
                    </Source>
                  </Map>
                  :
                  <div>Map not available</div>
                }
              </>
            );
          }}
        />
      </SimpleShowLayout>
    </Show>
  );
};

const District = ({ record }) => {
  return (
    <Grid container alignItems="center" flexGrow={1} spacing={1}>
      <Grid item>
        <DistrictIcon
          style={{ fontSize: 100, textAlign: "center" }}
          color="secondary"
        />
      </Grid>

      <Grid item flexGrow={1}>
        <Typography color="secondary">{record.name}</Typography>
        <Typography color="primary" variant="caption">
          {record?.level?.name}
        </Typography>
      </Grid>
    </Grid>
  );
};

const Title = ({ record }) => {
  return <Typography variant="h6">{record.name}</Typography>;
};
