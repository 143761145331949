import React from "react";
import {
  Show,
  SimpleShowLayout,
  Button as RaButton,
  useRefresh,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  Pagination,
} from "react-admin";
import {
  Typography,
  Grid,
  CardActions,
  Card,
  CardHeader,
  CardContent,
  Box,
} from "@material-ui/core";
import { StatusField } from "../components/status";
import { TimestampField } from "../components/fields";
import { Conditionally } from "../components/conditionally";
import { ListEvents } from "../components/timeline";
import { client } from "../providers/client";
import { useRequestState } from "../hooks/request";
import { DoneAll as CloseIcon, Email, Stop } from "@material-ui/icons";
import { superAdmin, admin, maintenance, dho } from "../roles";
import { OverdueField } from "../components/overdue";
import { issueTypesMapper } from "./constants";
import { FileReport } from "../components/file-input";
import moment from "moment";
import { UserListGrid } from "../users/list";
import { FlatActionButton } from "../components/action-button";

export function ShowMaintenance(props) {
  return (
    <Show
      title={<Title />}
      actions={<MaintenanceActions user={props.permissions} />}
      component="div"
      {...props}
    >
      <SimpleShowLayout>
        <Ticket />
        <Description user={props.permissions} />
        <Photo />
        <FileReports />
        <Contacts />
        <ListEvents />
      </SimpleShowLayout>
    </Show>
  );
}

export const MaintenanceActions = ({ data, user }) => {
  const record = data || {};

  return (
    <CardActions>
      <Grid container direction="row">
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant="h6">
            Maintenance Case #{record.maintenanceNumber}
          </Typography>
        </Grid>

        <Grid item>
          <Grid container spacing={1}>
            <Conditionally
              when={
                record?.status === "pending" &&
                [superAdmin, admin, maintenance].includes(user?.role)
              }
              render={() => (
                <Grid item>
                  <StatusButton record={record} status="completed" />
                </Grid>
              )}
            />
            <Conditionally
              when={
                record?.status === "completed" &&
                [superAdmin, admin, dho].includes(user?.role)
              }
              render={() => (
                <Grid item>
                  <StatusButton record={record} status="closed" />
                </Grid>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </CardActions>
  );
};

const Ticket = ({ record }) => {
  return (
    <Card component={Box} marginBottom={2}>
      <CardHeader title={"Details"} />
      <CardContent>
        <Grid container spacing={2}>
          <InfoField value={<StatusField record={record} />} />

          <Conditionally
            when={record.status === "pending"}
            render={() => (
              <Grid item xs={6} md={3}>
                <OverdueField record={record} />
              </Grid>
            )}
          />

          <InfoField
            label={"Issue Type"}
            value={issueTypesMapper?.[record?.issueType]}
          />

          <InfoField label={"Facility"} value={record?.lab?.name} />

          <InfoField
            label={"Instrument Serial Number"}
            value={record?.serialNumber}
          />

          <InfoField
            label={"Created"}
            value={
              record?.createdAt && (
                <TimestampField source={"createdAt"} record={record} />
              )
            }
          />

          <Conditionally
            when={["closed", "completed"].includes(record.status)}
            render={() => (
              <InfoField
                label={"Turn Around Time"}
                value={moment(record.createdAt).from(record.completedAt, true)}
              />
            )}
          />

          <InfoField
            label={"Failed Computer"}
            value={record?.computerSerialNumber}
          />

          <InfoField
            label={"Failed Module"}
            value={record?.failedModuleSerialNumber}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

const Description = ({ record, user }) => {
  const content = (
    <Card component={Box} marginBottom={2}>
      <CardHeader title={"Description"} />
      <CardContent>
        <Grid container spacing={2}>
          <DescriptionField value={record?.description} />
        </Grid>
      </CardContent>
      <Conditionally
        when={user?.role === superAdmin && record.status === "initiated"}
        render={() => (
          <CardActions>
            <FlatActionButton
              icon={<Email />}
              title={"Send Email"}
              handler={() =>
                client
                  .post(`/portal/maintenance/${record.id}/notify`)
                  .then((response) => response.data)
              }
              success={(data) => data}
              variant="contained"
              color={"primary"}
            />
          </CardActions>
        )}
      />
    </Card>
  );

  return record?.description ? content : null;
};

const Photo = ({ record }) => {
  const content = (
    <Card component={Box} marginBottom={2}>
      <CardHeader title={"Photo / Screenshot"} />
      <CardContent>
        <FunctionField
          render={(record) => (
            <Grid container spacing={2}>
              {record.photo.map((photo) => (
                <Grid item md={6}>
                  <img
                    src={photo}
                    alt="Screenshot"
                    style={{ width: "100%", display: "block" }}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        />
      </CardContent>
    </Card>
  );

  return record?.photo?.length > 0 ? content : null;
};

const FileReports = ({ record }) => {
  const content = (
    <Card component={Box} marginBottom={2}>
      <CardHeader title={"File Reports"} />
      <CardContent>
        {record?.installationQualificationReport && (
          <FileReport
            title="Installation Qualification Report"
            source="installationQualificationReport"
          />
        )}

        {record?.systemLogReport && (
          <FileReport title="System Log Report" source="systemLogReport" />
        )}

        {record?.resultArchiveReport && (
          <FileReport
            title="Result Archive (3 months)"
            source="resultArchiveReport"
          />
        )}
      </CardContent>
    </Card>
  );

  return record?.installationQualificationReport ||
    record?.systemLogReport ||
    record?.resultArchiveReport
    ? content
    : null;
};

function StatusButton({ record, status }) {
  const refresh = useRefresh();
  const request = useRequestState(() => {
    return client.put(`/portal/maintenance/${record.id}/status`, { status });
  });
  const statusOptions =
    status !== "completed"
      ? { label: "Close", icon: <CloseIcon /> }
      : { label: "Complete", icon: <Stop /> };

  const handleStatusUpdate = () => {
    request.fetch().then(() => {
      refresh();
    });
  };

  return (
    <RaButton
      variant={"contained"}
      label={statusOptions.label}
      color={"primary"}
      onClick={handleStatusUpdate}
    >
      {statusOptions.icon}
    </RaButton>
  );
}

export function InfoField({ label, value, hint }) {
  if (!value) return null;

  return (
    <Grid item xs={6} md={3} title={hint}>
      {label ? <Typography variant="caption">{label}</Typography> : null}
      {typeof value ? (
        <Typography variant="body1">{value}</Typography>
      ) : (
        { value }
      )}
    </Grid>
  );
}

export function DescriptionField({ label, value, hint }) {
  if (!value) return null;

  return (
    <Grid item title={hint}>
      {label ? <Typography variant="caption">{label}</Typography> : null}
      {typeof value ? (
        <Typography variant="body2">{value}</Typography>
      ) : (
        { value }
      )}
    </Grid>
  );
}

export function TurnAroundField({ label, record }) {
  return (
    <Grid item>
      {label ? <Typography variant="caption">{label}</Typography> : null}

      <Typography variant="body2">
        {moment(
          record?.tat.pending || record?.tat.created || record?.tat.updated
        ).from(record.tat.completed, false)}
      </Typography>
    </Grid>
  );
}

const Title = ({ record }) => {
  return <Typography variant="h6">{record.caseNumber}</Typography>;
};

export const Contacts = ({ record }) => {
  return (
    <Card component={Box} marginBottom={2}>
      <CardHeader title="Contacts" />

      <ReferenceField
        reference="facilities"
        addLabel={false}
        source="facilityId"
        link={false}
        record={record}
      >
        <ReferenceManyField
          reference="users"
          target="facilityId"
          pagination={<Pagination />}
          addLabel={false}
        >
          <UserListGrid />
        </ReferenceManyField>
      </ReferenceField>
    </Card>
  );
};
