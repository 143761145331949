import * as React from "react";
import { Notification, useNotify } from "react-admin";
import { Box, Button, CircularProgress, TextField } from "@material-ui/core";
import { AuthLayout, BaseLayout } from "./layout";
import { useRequestState } from "../hooks/request";
import { adminResetPassword, resetPassword } from "../users/operations";
import { useHistory } from "react-router";

export const ResetPassword = props => {
  const [password, setPassword] = React.useState("");

  React.useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  const reset = useRequestState(resetPassword, null);
  const notify = useNotify();
  const history = useHistory();

  const handleSubmit = event => {
    event.preventDefault();
    reset
      .fetch(props.match.params.token, password)
      .then(() => history.push("/login"))
      .catch(error => {
        notify(error.response?.data?.message || error.message, "error");
      });
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit}>
        <Box marginY="1em">
          <TextField
            name="password"
            label={"Password"}
            type="password"
            value={password}
            fullWidth
            onChange={event => setPassword(event.target.value)}
          />
        </Box>

        <Button variant="contained" type="submit" color="primary" fullWidth>
          {reset.loading && <CircularProgress size={25} thickness={2} />}
          Set New Password
        </Button>
      </form>
    </AuthLayout>
  );
};

export const AdminResetPassword = props => {
  const [password, setPassword] = React.useState("");

  const { fetch, loading } = useRequestState(adminResetPassword, null);
  const notify = useNotify();
  const history = useHistory();

  const handleSubmit = event => {
    event.preventDefault();
    fetch(props.match.params.email, password)
      .then(() => {
        notify("Reset successfully", "success");
        setTimeout(() => {
          history.goBack();
        }, 1200);
      })
      .catch(error => {
        notify(error.response?.data?.message || error.message, "error");
      });
  };

  return (
    <BaseLayout>
      <form onSubmit={handleSubmit}>
        <Box marginY="1em">
          <TextField
            name="password"
            label={"Password"}
            type="password"
            value={password}
            fullWidth
            onChange={event => setPassword(event.target.value)}
          />
        </Box>

        <Button variant="contained" type="submit" color="primary" fullWidth>
          {loading && <CircularProgress size={25} thickness={2} />}
          Set New Password
        </Button>
      </form>
      <Notification />
    </BaseLayout>
  );
};