import { createBrowserHistory } from "history";

import { gtag } from "./google-analytics";

export const history = createBrowserHistory();

history.listen(location => {
  gtag("event", "page_view", {
    page_title: location.pathname,
    page_location: window.location.href
  });
});
