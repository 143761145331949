import {
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  Pagination,
  FormWithRedirect,
  ReferenceInput,
  AutocompleteInput,
  useRefresh,
  SelectInput,
  DateInput,
  Button as RaButton,
  TextInput,
} from "react-admin";
import React, { useState } from "react";
import {
  Typography,
  Grid,
  CardActions,
  Card,
  CardHeader,
  CardContent,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
} from "@material-ui/core";
import { StatusField } from "../components/status";
import { TimestampField } from "../components/fields";
import { ResultsGrid } from "../results/list";
import { Conditionally } from "../components/conditionally";
import { Add, Edit, PlayArrow, Stop } from "@material-ui/icons";
import { useRequestState } from "../hooks/request";
import { client } from "../providers/client";
import { Diagnosis } from "../components/diagnosis";
import { ListEvents } from "../components/timeline";
import { InfoField } from "../maintenance/show";

export function ShowCase(props) {
  return (
    <Show
      title={<Title />}
      actions={<CaseActions user={props.permissions} />}
      component="div"
      {...props}
    >
      <SimpleShowLayout>
        <Details />
        <Patient />
        <MTB />
        <XDR />
        <OtherDST />
        <ListEvents />
      </SimpleShowLayout>
    </Show>
  );
}

export const CaseActions = ({ data }) => {
  const record = data || {};
  return (
    <CardActions>
      <Grid container direction="row">
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant="h6">Case #{record.caseNumber}</Typography>
        </Grid>

        <Grid item>
          <Grid container spacing={1}>
            <Conditionally
              when={record?.status === "awaiting-treatment"}
              render={() => (
                <Grid item>
                  <StartTreatmentButton record={record} />
                </Grid>
              )}
            />
            <Conditionally
              when={record?.status === "started-treatment"}
              render={() => (
                <Grid item>
                  <CompleteTreatmentButton record={record} />
                </Grid>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </CardActions>
  );
};

export const Details = ({ record }) => {
  return (
    <Card component={Box} marginBottom={2}>
      <CardHeader title={"Details"} />
      <CardContent>
        <Grid container spacing={2}>
          <InfoField value={<StatusField record={record} />} />

          <InfoField
            label={"Created"}
            value={<TimestampField source={"createdAt"} record={record} />}
          />

          <Conditionally
            when={record.dateOfTreatment}
            render={() => (
              <InfoField
                label={"Started Treatment"}
                value={
                  <TimestampField source={"dateOfTreatment"} record={record} />
                }
              />
            )}
          />

          <InfoField label={"Treatment Facility"} value={record?.tic?.name} />

          <InfoField
            label={"Treatment Outcome"}
            value={record?.treatmentOutcome}
          />

          <Conditionally
            when={record.dateTreatmentCompleted}
            render={() => (
              <InfoField
                label={"Treatment Completed"}
                value={
                  <TimestampField
                    source={"dateTreatmentCompleted"}
                    record={record}
                  />
                }
              />
            )}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

const Patient = ({ record }) => {
  const refresh = useRefresh();
  const [attach, setAttach] = useState(false);
  const update = useRequestState((patient) => {
    return client.put(`/portal/cases/${record.id}`, { patient });
  });

  function handleSave(values) {
    update
      .fetch(values)
      .then(() => setAttach(false))
      .then(() => refresh());
  }

  if (!record.patient) {
    return null;
  }
  return (
    <Card component={Box} marginBottom={2}>
      <CardHeader
        title="Patient"
        action={
          <IconButton onClick={() => setAttach(true)}>
            <Edit />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <InfoField label={"MRN"} value={record.patient?.mrn} />
          <InfoField label={"Name"} value={record.patient?.name} />
          <InfoField label={"Age"} value={record.patient?.age} />
          <InfoField
            label={"Gender"}
            value={record.patient?.gender?.toUpperCase()}
          />
          <InfoField
            label={"Infection Site"}
            value={record.patient?.infectionSite}
          />
          <InfoField label={"HIV Status"} value={record.patient?.hivStatus} />
        </Grid>
      </CardContent>

      <Dialog open={attach} onClose={() => setAttach(false)}>
        <DialogTitle>Edit Patient Details</DialogTitle>

        <FormWithRedirect
          defaultValue={record.patient}
          resource="cases"
          save={handleSave}
          render={({ handleSubmitWithRedirect }) => {
            return (
              <>
                <DialogContent>
                  <TextInput source="mrn" fullWidth variant="outlined" />
                  <TextInput source="name" fullWidth variant="outlined" />
                  <TextInput
                    source="age"
                    type="number"
                    fullWidth
                    variant="outlined"
                  />
                  <SelectInput
                    source="gender"
                    choices={[
                      { id: "Male", name: "Male" },
                      { id: "Female", name: "Female" },
                    ]}
                    fullWidth
                    variant="outlined"
                  />
                  <SelectInput
                    source="infectionSite"
                    choices={[
                      { id: "Pulmonary", name: "Pulmonary" },
                      { id: "Extra Pulmonary", name: "Extra Pulmonary" },
                    ]}
                    fullWidth
                    variant="outlined"
                  />
                  <SelectInput
                    source="hivStatus"
                    choices={[
                      { id: "Positive", name: "Positive" },
                      { id: "Negative", name: "Negative" },
                      { id: "Unknown", name: "Unknown" },
                    ]}
                    fullWidth
                    variant="outlined"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setAttach(false)} color="primary">
                    Cancel
                  </Button>
                  <Button color="primary" onClick={handleSubmitWithRedirect}>
                    Save
                  </Button>
                </DialogActions>
              </>
            );
          }}
        />
      </Dialog>
    </Card>
  );
};

export const MTB = ({ record }) => {
  const refresh = useRefresh();
  const [attach, setAttach] = useState(false);
  const update = useRequestState((mtbResult) => {
    return client.put(`/portal/cases/${record.id}`, {
      mtbResults: [...record.mtbResults, mtbResult],
    });
  });

  function handleSave(values) {
    update
      .fetch(values.xdrResult)
      .then(() => setAttach(false))
      .then(() => refresh());
  }
  return (
    <Card component={Box} marginBottom={2}>
      <CardHeader
        title={"MTB Results"}
        action={
          <IconButton onClick={() => setAttach(true)}>
            <Add />
          </IconButton>
        }
      />
      <Conditionally
        when={record.mtbResults?.length}
        render={() => (
          <ReferenceManyField
            reference="results"
            source="mtbResults"
            target="_id"
            perPage={10}
            pagination={<Pagination />}
            sort={{ field: "completedAt", order: "DESC" }}
            addLabel={false}
            record={record}
          >
            <ResultsGrid />
          </ReferenceManyField>
        )}
      />
      <Conditionally
        when={!record.mtbResults?.length}
        render={() => (
          <Box padding={4}>
            <Typography>No Results</Typography>
          </Box>
        )}
      />
      <Dialog open={attach} onClose={() => setAttach(false)}>
        <DialogTitle>Attach MTB Test Result</DialogTitle>

        <FormWithRedirect
          resource="cases"
          save={handleSave}
          render={({ handleSubmitWithRedirect }) => {
            return (
              <>
                <DialogContent>
                  <ReferenceInput
                    label="Result"
                    source="xdrResult"
                    reference="results"
                    filterToQuery={(searchText) => {
                      return {
                        search: { "instrument.sampleId": searchText },
                        "instrument.cartridgeType": [
                          "Xpert MTB-Xpert MTB-RIF Assay G4",
                          "Xpert MTB-RIF Ultra",
                        ],
                      };
                    }}
                    fullWidth
                    variant="outlined"
                  >
                    <AutocompleteInput optionText="instrument.sampleId" />
                  </ReferenceInput>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setAttach(false)} color="primary">
                    Cancel
                  </Button>
                  <Button color="primary" onClick={handleSubmitWithRedirect}>
                    Save
                  </Button>
                </DialogActions>
              </>
            );
          }}
        />
      </Dialog>
    </Card>
  );
};

export const XDR = ({ record }) => {
  const refresh = useRefresh();
  const [attach, setAttach] = useState(false);
  const update = useRequestState((xdrResult) => {
    return client.put(`/portal/cases/${record.id}`, {
      xdrResults: [...record.xdrResults, xdrResult],
    });
  });

  function handleSave(values) {
    update
      .fetch(values.xdrResult)
      .then(() => setAttach(false))
      .then(() => refresh());
  }

  return (
    <Card component={Box} marginBottom={2}>
      <CardHeader
        title={"XDR Results"}
        action={
          <IconButton onClick={() => setAttach(true)}>
            <Add />
          </IconButton>
        }
      />
      <Conditionally
        when={record.xdrResults?.length}
        render={() => (
          <ReferenceManyField
            reference="results"
            source="xdrResults"
            target="_id"
            perPage={10}
            pagination={<Pagination />}
            sort={{ field: "completedAt", order: "DESC" }}
            addLabel={false}
            record={record}
          >
            <ResultsGrid />
          </ReferenceManyField>
        )}
      />
      <Conditionally
        when={!record.xdrResults?.length}
        render={() => (
          <Box padding={4}>
            <Typography>No Results</Typography>
          </Box>
        )}
      />
      <Dialog open={attach} onClose={() => setAttach(false)}>
        <DialogTitle>Attach XDR Test Result</DialogTitle>

        <FormWithRedirect
          resource="cases"
          save={handleSave}
          render={({ handleSubmitWithRedirect }) => {
            return (
              <>
                <DialogContent>
                  <ReferenceInput
                    label="Result"
                    source="xdrResult"
                    reference="results"
                    filterToQuery={(searchText) => {
                      return {
                        search: { "instrument.sampleId": searchText },
                        "instrument.cartridgeType": "Xpert MTB-XDR",
                      };
                    }}
                    fullWidth
                    variant="outlined"
                  >
                    <AutocompleteInput optionText="instrument.sampleId" />
                  </ReferenceInput>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setAttach(false)} color="primary">
                    Cancel
                  </Button>
                  <Button color="primary" onClick={handleSubmitWithRedirect}>
                    Save
                  </Button>
                </DialogActions>
              </>
            );
          }}
        />
      </Dialog>
    </Card>
  );
};

export const OtherDST = ({ record }) => {
  const refresh = useRefresh();
  const [attach, setAttach] = useState(false);
  const update = useRequestState((result) => {
    return client.put(`/portal/cases/${record.id}`, {
      otherDstResults: [...record.otherDstResults, result],
    });
  });

  function handleSave(values) {
    update
      .fetch(values.otherDstResult)
      .then(() => setAttach(false))
      .then(() => refresh());
  }

  return (
    <Card component={Box} marginBottom={2}>
      <CardHeader
        title={"Other DST Results"}
        action={
          <IconButton onClick={() => setAttach(true)}>
            <Add />
          </IconButton>
        }
      />
      <Conditionally
        when={record.otherDstResults?.length}
        render={() => (
          <Box>
            {record.otherDstResults.map((result) => (
              <Box
                component={Paper}
                padding={2}
                display={"flex"}
                flexDirection="row"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Diagnosis
                  name={`${result.drug} Resistance`}
                  qualitative={result.result}
                />
                <TimestampField source="date" record={result} />
              </Box>
            ))}
          </Box>
        )}
      />
      <Conditionally
        when={!record.otherDstResults?.length}
        render={() => (
          <Box padding={4}>
            <Typography>No Results</Typography>
          </Box>
        )}
      />
      <Dialog open={attach} onClose={() => setAttach(false)}>
        <DialogTitle>Record DST Result</DialogTitle>

        <FormWithRedirect
          resource="cases"
          save={handleSave}
          render={({ handleSubmitWithRedirect }) => {
            return (
              <>
                <DialogContent>
                  <SelectInput
                    variant="outlined"
                    label="Drug"
                    source="otherDstResult.drug"
                    choices={[
                      { id: "Bequilin", name: "Bequilin" },
                      { id: "Deldradnid", name: "Deldradnid" },
                      { id: "Linezolid", name: "Linezolid" },
                    ]}
                    fullWidth
                  />
                  <SelectInput
                    variant="outlined"
                    label="Result"
                    source="otherDstResult.result"
                    choices={[
                      { id: "Resistant", name: "Resistant" },
                      { id: "Not Resistant", name: "Not Resistant" },
                    ]}
                    fullWidth
                  />
                  <DateInput
                    variant="outlined"
                    label="Date"
                    source="otherDstResult.date"
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setAttach(false)} color="primary">
                    Cancel
                  </Button>
                  <Button color="primary" onClick={handleSubmitWithRedirect}>
                    Save
                  </Button>
                </DialogActions>
              </>
            );
          }}
        />
      </Dialog>
    </Card>
  );
};

export const StartTreatmentButton = ({ record }) => {
  const refresh = useRefresh();
  const [attach, setAttach] = useState(false);
  const update = useRequestState((treatment) => {
    return client.put(`/portal/cases/${record.id}`, treatment);
  });

  function handleSave({ treatmentFacilityId, dateOfTreatment }) {
    update
      .fetch({
        status: "started-treatment",
        treatmentFacilityId,
        dateOfTreatment,
      })
      .then(() => setAttach(false))
      .then(() => refresh());
  }

  return (
    <>
      <RaButton
        variant={"contained"}
        label={"Start Treatment"}
        color={"primary"}
        onClick={() => setAttach(true)}
      >
        <PlayArrow />
      </RaButton>
      <Dialog open={attach} onClose={() => setAttach(false)}>
        <DialogTitle>Start Treatment</DialogTitle>

        <FormWithRedirect
          resource="cases"
          save={handleSave}
          render={({ handleSubmitWithRedirect }) => {
            return (
              <>
                <DialogContent>
                  <ReferenceInput
                    label="Treatment Center"
                    source="treatmentFacilityId"
                    reference="facilities"
                    filterToQuery={(name) => ({ search: { name } })}
                    fullWidth
                    variant="outlined"
                  >
                    <AutocompleteInput optionText="name" />
                  </ReferenceInput>
                  <DateInput
                    variant="outlined"
                    label="Date"
                    source="dateOfTreatment"
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setAttach(false)} color="primary">
                    Cancel
                  </Button>
                  <Button color="primary" onClick={handleSubmitWithRedirect}>
                    Save
                  </Button>
                </DialogActions>
              </>
            );
          }}
        />
      </Dialog>
    </>
  );
};

export const CompleteTreatmentButton = ({ record }) => {
  const refresh = useRefresh();
  const [attach, setAttach] = useState(false);
  const update = useRequestState((treatment) => {
    return client.put(`/portal/cases/${record.id}`, treatment);
  });

  function handleSave({ treatmentOutcome, dateTreatmentCompleted }) {
    update
      .fetch({
        status: "completed-treatment",
        treatmentOutcome,
        dateTreatmentCompleted,
      })
      .then(() => setAttach(false))
      .then(() => refresh());
  }

  return (
    <>
      <RaButton
        variant={"contained"}
        label={"Complete Treatment"}
        color={"primary"}
        onClick={() => setAttach(true)}
      >
        <Stop />
      </RaButton>
      <Dialog open={attach} onClose={() => setAttach(false)}>
        <DialogTitle>Complete Treatment</DialogTitle>

        <FormWithRedirect
          resource="cases"
          save={handleSave}
          render={({ handleSubmitWithRedirect }) => {
            return (
              <>
                <DialogContent>
                  <SelectInput
                    variant="outlined"
                    label="Outcome"
                    source="treatmentOutcome"
                    choices={[
                      { id: "Cured", name: "Cured" },
                      { id: "Failure", name: "Failure" },
                      { id: "Death", name: "Death" },
                    ]}
                    fullWidth
                  />
                  <DateInput
                    variant="outlined"
                    label="Date"
                    source="dateTreatmentCompleted"
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setAttach(false)} color="primary">
                    Cancel
                  </Button>
                  <Button color="primary" onClick={handleSubmitWithRedirect}>
                    Save
                  </Button>
                </DialogActions>
              </>
            );
          }}
        />
      </Dialog>
    </>
  );
};

const Title = ({ record }) => {
  return <Typography variant="h6">{record.caseNumber}</Typography>;
};
