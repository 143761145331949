import * as React from "react";
import { useLogin, useNotify } from "react-admin";
import { Paper, Typography, Button, TextField, Box } from "@material-ui/core";
import * as jwtDecode from "jwt-decode";

import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Notification from "./notification";
import { gtag } from "./google-analytics";
import { Link } from "react-router-dom";
import { setTawkAttributes } from "./support";
import { useTheme } from "./theme";
import { useSettings } from "./settings";

const useStyles = makeStyles({
  forgotPasswordLink: {
    color: "white",
    textDecoration: "none",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    padding: 16,
    boxSizing: "border-box",
  },
  paper: {
    padding: 16,
    width: "100%",
    maxWidth: 420,
    margin: 8,
  },
  verticalMargin: {
    margin: "1em 0",
  },
});

const pkg = require("../package.json");

export const LoginPage = () => {
  const theme = useTheme();
  const settings = useSettings();
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const login = useLogin();
  const notify = useNotify();

  React.useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    login({ email, password })
      .then(() => {
        const user = jwtDecode(localStorage.getItem("token"));
        gtag("event", "login");
        gtag("set", "user_id", user.id);
        gtag("set", "user_properties", {
          role: user.role,
          org: settings["instance-name"],
        });
        setTawkAttributes();
      })
      .catch((error) => {
        const action = error.response?.data?.link;
        notify(error.response?.data?.message || error.message, "error", {
          ...(action
            ? { action: <Button href={action}>Reset Password</Button> }
            : {}),
        });
      });
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <img
          style={{ display: "block", margin: "0 auto" }}
          src={settings["instance-logo"] || "icon.png"}
          alt="Logo"
          height="84"
        />
        <Box padding={4}/>
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit}>
            <div className={classes.verticalMargin}>
              <TextField
                autoFocus
                name="email"
                label={"Email"}
                value={email}
                fullWidth
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>

            <div className={classes.verticalMargin}>
              <TextField
                name="password"
                label={"Password"}
                type="password"
                value={password}
                fullWidth
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>

            <Button variant="contained" type="submit" color="primary" fullWidth>
              {/* {isLoading && <CircularProgress size={25} thickness={2} />} */}
              Sign In
            </Button>
          </form>
        </Paper>
        <Typography
          component={Link}
          color="primary"
          variant="body1"
          to="/forgot"
        >
          Forgot Password?
        </Typography>
        <Box marginTop="2em">
          <Typography variant="caption">v{pkg.version}</Typography>
        </Box>
        <Notification />
      </div>
    </ThemeProvider>
  );
};
