import DistrictIcon from "@material-ui/icons/MapOutlined";
import { ListLevels } from "./list";
import { CreateLevel, EditLevel } from "./create-edit";

export const levels = {
  name: "levels",
  options: {
    label: "Geographical Levels"
  },
  icon: DistrictIcon,
  list: ListLevels,
  create: CreateLevel,
  edit: EditLevel
};
