import { Show, TabbedShowLayout, Tab, EditButton } from "react-admin";
import React from "react";
import { Typography, Grid, CardActions } from "@material-ui/core";
import { ListUserActions } from "./userTimeline";
import { AvatarField } from "../components/avatar";
import { Conditionally } from "../components/conditionally";

export function ShowUser(props) {
  return (
    <Show
      title={<Title />}
      actions={<UserActions user={props.permissions} />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="Audit Trail">
          <Conditionally
            render={() => <ListUserActions />}
            when={["super-admin", "admin"].includes(props.permissions?.role)}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export const UserActions = ({ data, basePath }) => {
  const record = data || {};
  return (
    <CardActions>
      <Grid container alignItems="center" direction="column">
        <Grid item style={{ flexGrow: 1 }}>
          <Grid container alignItems="center" direction="column">
            <Grid item>
              <AvatarField record={record} source="avatar" size={256} />
            </Grid>
            <Grid item>
              <Typography variant="h5">{record.name}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid spacing={1} alignItems="center" container>
            <Grid item>
              <EditButton record={record} basePath={basePath} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardActions>
  );
};

const Title = ({ record }) => {
  return <Typography variant="h6">{record.name}</Typography>;
};
