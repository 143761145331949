import React from "react";
import { Avatar, Typography, Grid } from "@material-ui/core";
import { Camera, Photo } from "@material-ui/icons";
import { addField } from "react-admin";
import * as Compress from "client-compress";

const compressor = new Compress({ quality: 0.6 });

export const CameraInput = addField(
  ({ input, meta: { touched, error } = {}, ...all }) => {
    function onCapture() {
      const uploadInput = document.createElement("input");
      uploadInput.type = "file";
      uploadInput.accept = "image/*";
      uploadInput.capture = "environment";
      uploadInput.onchange = handleUpload;
      uploadInput.click();
    }

    function handleUpload(e) {
      compressor
        .compress(Array.from(e.target.files))
        .then(([avatar]) => {
          return Compress.blobToBase64(avatar.photo.data);
        })
        .then((base64Url) => {
          input.onChange(base64Url);
        });
    }

    return (
      <Grid
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ paddingBottom: "2em" }}
        spacing={2}
        container
      >
        <Grid item>
          {input.value ? (
            <Avatar
              src={input.value}
              style={{ width: 240, height: 240 }}
              onClick={onCapture}
            />
          ) : (
            <Avatar style={{ width: 240, height: 240 }} onClick={onCapture}>
              <Camera style={{ fontSize: 64 }} />
            </Avatar>
          )}
        </Grid>
        <Grid item>
          <Typography variant="caption">
            {touched && error ? error : "Profile Photo"}
          </Typography>
        </Grid>
      </Grid>
    );
  }
);

export const ImageInput = addField(
  ({ input, meta: { touched, error } = {}, label, ...all }) => {
    function onCapture() {
      const uploadInput = document.createElement("input");
      uploadInput.type = "file";
      uploadInput.accept = "image/*";
      uploadInput.onchange = handleUpload;
      uploadInput.click();
    }

    function handleUpload(e) {
      compressor
        .compress(Array.from(e.target.files))
        .then(([avatar]) => {
          return Compress.blobToBase64(avatar.photo.data);
        })
        .then((base64Url) => {
          input.onChange(base64Url);
        });
    }

    return (
      <Grid
        justifyContent="center"
        alignItems="center"
        style={{ paddingBottom: "2em" }}
        container
      >
        <Grid style={{ display: "flex" }} direction="column" item xs={12}>
          <Typography variant="caption">
            {touched && error ? error : label}
          </Typography>
          {input.value ? (
            <Avatar
              src={input.value}
              style={{ width: 600, height: 240 }}
              onClick={onCapture}
              variant="square"
            />
          ) : (
            <Avatar
              style={{ width: 600, height: 240 }}
              onClick={onCapture}
              variant="square"
            >
              <Photo style={{ fontSize: 64 }} />
            </Avatar>
          )}
        </Grid>
      </Grid>
    );
  }
);

export const MultipleImageInput = addField(
  ({ input, meta: { touched, error } = {}, label }) => {
    function onCapture() {
      const uploadInput = document.createElement("input");
      uploadInput.type = "file";
      uploadInput.accept = "image/*";
      uploadInput.multiple = true;
      uploadInput.onchange = handleUpload;
      uploadInput.click();
    }

    function handleUpload(e) {
      compressor
        .compress(Array.from(e.target.files))
        .then((images) =>
          Promise.all(
            images.map(({ photo }) => Compress.blobToBase64(photo.data))
          )
        )
        .then((images) => input.onChange(images));
    }

    return (
      <Grid
        justifyContent="center"
        alignItems="center"
        style={{ paddingBottom: "2em" }}
        container
      >
        <Grid style={{ display: "flex" }} direction="column" item xs={12}>
          <Typography variant="caption">
            {touched && error ? error : label}
          </Typography>
          {input.value?.length ? (
            <Grid container spacing={1}>
              {input.value?.map((image) => {
                return (
                  <Grid item xs={6} md={4}>
                    <Avatar
                      src={image}
                      style={{ width: "100%", height: "20vh" }}
                      onClick={onCapture}
                      variant="square"
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Avatar
              style={{ width: 600, height: 240 }}
              onClick={onCapture}
              variant="square"
            >
              <Photo style={{ fontSize: 64 }} />
            </Avatar>
          )}
        </Grid>
      </Grid>
    );
  }
);
