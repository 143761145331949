import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  FormControl,
  IconButton,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Paper,
  Chip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Close, FilterList } from "@material-ui/icons";
import {
  useDataProvider,
  Button as RaButton,
  useTranslate,
} from "react-admin";

import { SourcePicker, TimeRangePicker, UnitOfTimePicker, formatExact } from "./controls";
import { Conditionally } from "./conditionally";
import { LocationOptionRenderer } from "../districts/list";
import { facilityAdmin } from "../roles";
import { useRequestState } from "../hooks/request";
import moment from "moment";

export const GlobalFilter = ({
  user,
  onChangeMultiFilter,
}) => {
  const [open, setOpen] = useState(false);

  const [range, setRange] = useState({
    type: "last",
    unitOfTime: "day",
    qty: 30,
  });

  const [unitOfTime, setUnitOfTime] = useState("week");

  const [locationType, setLocationType] = useState(null);

  const [locationId, setLocationId] = useState(null);

  const [source, setSource] = useState("all");

  useEffect(() => {
    setLocationId(null);
  }, [locationType]);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleApply() {
    setOpen(false);
    setTimeout(() => {
      onChangeMultiFilter({
        locationFilter: { id: locationId, resource: locationType },
        rangeFilter: range,
        stepFilter: unitOfTime,
        sourceFilter: source,
      });
    }, 1);
  }

  return (
    <div>
      <RaButton
        variant="outlined"
        color="primary"
        label="Filter"
        onClick={handleOpen}
        size="medium"
      >
        <FilterList />
      </RaButton>

      <Conditionally
        when={open}
        render={() => (
          <Dialog fullWidth open={open} onClose={handleClose}>
            <DialogTitle disableTypography>
              <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Typography variant="h6">Filter</Typography>
                <IconButton size="small" onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>

            <DialogContent>
              <Grid container spacing={2} direction={"row"}>
                <Grid item xs={4}>
                  <Paper component={Box} padding={1}>
                    <Typography variant="overline">Period</Typography>
                    <Box marginBottom={1} />
                    <TimeRangePicker value={range} onChange={setRange} />
                  </Paper>
                </Grid>

                <Grid item xs={4}>
                  <Paper component={Box} padding={1}>
                    <Typography variant="overline">Trend By</Typography>
                    <Box marginBottom={1} />
                    <UnitOfTimePicker
                      value={unitOfTime}
                      onChange={setUnitOfTime}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={4}>
                  <Paper component={Box} padding={1}>
                    <Typography variant="overline">Result source</Typography>
                    <Box marginBottom={1} />
                    <SourcePicker
                      value={source}
                      onChange={setSource}
                    />
                  </Paper>
                </Grid>

                <Conditionally
                  when={![facilityAdmin].includes(user.role)}
                  render={() => (
                    <Grid item xs={12}>
                      <Paper component={Box} padding={1}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography variant="overline">Location</Typography>
                            <Box marginBottom={1} />

                            <FormControl size="small">
                              <RadioGroup
                                value={locationType}
                                onChange={(_, value) => setLocationType(value)}
                              >
                                <FormControlLabel
                                  value="facilities"
                                  control={<Radio />}
                                  label="Facility"
                                />
                                <FormControlLabel
                                  value="districts"
                                  control={<Radio />}
                                  label="Geographic Area"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Conditionally
                            when={locationType}
                            render={() => (
                              <Grid item xs={6}>
                                <Typography variant="overline">
                                  {locationType === "facilities"
                                    ? "Facility"
                                    : "Geographic Area"}
                                </Typography>

                                <Box marginBottom={1} />

                                <LocationPicker
                                  value={locationId}
                                  onChange={setLocationId}
                                  resource={locationType}
                                />
                              </Grid>
                            )}
                          />
                        </Grid>
                      </Paper>
                    </Grid>
                  )}
                />
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button variant="contained" color="primary" onClick={handleApply}>
                Apply
              </Button>
            </DialogActions>
          </Dialog>
        )}
      />
    </div>
  );
};

export const GlobalFilterValues = ({
  location,
  period,
  step,
  source,
  onChangeLocation,
  onChangePeriod,
  onChangeStep,
  onChangeSource,
}) => {
  return (
    <Grid container spacing={1} alignItems="center">
      <Conditionally
        when={location.id}
        render={() => (
          <Grid item>
            <LocationFilterValue
              value={location}
              onRemove={() => onChangeLocation({ resource: "facilities" })}
            />
          </Grid>
        )}
      />
      <Conditionally
        when={period}
        render={() => (
          <Grid item>
            <PeriodFilterValue
              value={period}
              onRemove={() => onChangePeriod(undefined)}
            />
          </Grid>
        )}
      />
      <Conditionally
        when={step}
        render={() => (
          <Grid item>
            <StepilterValue
              value={step}
              onRemove={() => onChangeStep(undefined)}
            />
          </Grid>
        )}
      />
      <Conditionally
        when={source && source !== "all"}
        render={() => (
          <Grid item>
            <SourceFilterValue
              value={source}
              onRemove={() => onChangeSource(undefined)}
            />
          </Grid>
        )}
      />
    </Grid>
  );
};

export const LocationFilterValue = ({ value, onRemove }) => {
  const provider = useDataProvider();

  const location = useRequestState((resource, id) =>
    provider.getOne(resource, { id: id }).then((response) => response.data)
  );

  useEffect(() => {
    location.fetch(value.resource, value.id);
  }, [value]);

  if (!location.value) {
    return <div />;
  }

  return (
    <Chip
      variant="outlined"
      color="primary"
      onDelete={onRemove}
      label={
        location.value?.name +
        (value.resource === "districts" ? " " + location.value.level?.name : "")
      }
    />
  );
};

export const StepilterValue = ({ value, onRemove }) => {
  const translate = useTranslate();
  return (
    <Chip
      variant="outlined"
      color="primary"
      onDelete={onRemove}
      label={`Trend by ${translate(`medx.unitoftime.${value}`, {
        smart_count: 1,
      })}`}
    />
  );
};

export const PeriodFilterValue = ({ value, onRemove }) => {
  const translate = useTranslate();

  function getTextRep(period) {
    if (period.type === "last") {
      return `Last ${translate(`medx.unitoftime.${period.unitOfTime}`, {
        smart_count: period.qty,
      })}`;
    }

    if (period.type === "current") {
      return `This ${translate(`medx.unitoftime.${period.unitOfTime}`, {
        smart_count: 1,
      })}`;
    }

    if (period.type === "after") {
      return `After ${moment(period.after).format("Do MMM 'YY")}`;
    }

    if (period.type === "before") {
      return `Before ${moment(period.before).format("Do MMM 'YY")}`;
    }

    if (period.type === "between") {
      return `${moment(period.before).format("Do MMM 'YY")} to ${moment(
        period.after
      ).format("Do MMM 'YY")}`;
    }

    if (period.type === "exact") {
      return formatExact(period.date, period.unitOfTime);
    }

    return period.type;
  }

  return (
    <Chip
      variant="outlined"
      color="primary"
      onDelete={onRemove}
      label={getTextRep(value)}
    />
  );
};

export const LocationPicker = ({ resource, value, onChange }) => {
  const [input, setInput] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  const provider = useDataProvider();

  useEffect(() => {
    if (input.length > 2) {
      provider
        .getList(resource, {
          filter: {
            search: {
              name: input,
            },
          },
          pagination: {
            perPage: 10,
            page: 1,
          },
        })
        .then((response) => setOptions(response.data));
    }
  }, [input]);

  useEffect(() => {
    if (value) {
      provider
        .getOne(resource, { id: value })
        .then((response) => setSelected(response.data));
    } else {
      setSelected(null);
    }
  }, [value]);

  useEffect(() => {
    if (selected) {
      setInput(selected.name);
    } else {
      setInput("");
    }
  }, [selected]);

  return (
    <Autocomplete
      size="small"
      freeSolo
      value={selected || null}
      fullWidth
      open={openAutocomplete}
      onOpen={() => setOpenAutocomplete(true)}
      onClose={() => setOpenAutocomplete(false)}
      getOptionSelected={(option, v) => option?.id === v}
      getOptionLabel={(option) => option.name}
      options={options}
      inputValue={input}
      onChange={(_, v) => onChange(v?.id)}
      onInputChange={(_, v) => setInput(v)}
      renderOption={(option) => <LocationOptionRenderer record={option} />}
      renderInput={(params) => {
        const { InputProps, ...rest } = params;
        return (
          <TextField autoFocus variant="outlined" {...InputProps} {...rest} />
        );
      }}
    />
  );
};

export const SourceFilterValue = ({ value, onRemove }) => {
  const translate = useTranslate();
  return (
    <Chip
      variant="outlined"
      color="primary"
      onDelete={onRemove}
      label={`Result source: ${translate(`medx.source.${value}`, {
        smart_count: 1,
      })}`}
    />
  );
};