import { Chip, Grid, Typography } from "@material-ui/core";
import React from "react";
import {
  List,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  Pagination,
} from "react-admin";
import moment from "moment";

import { MobileListGrid } from "../components/mobile-list-grid";
import {
  LocationOptionRenderer,
  filterToLocationQuery,
  locationInputText,
  matchLocationSuggestion,
} from "../districts/list";

export const ListNotifications = ({ permissions, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "end", order: "DESC" }}
    filters={<Filters />}
    pagination={
      <Pagination
        rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
        perPage={100}
      />
    }
  >
    <NotificationGrid />
  </List>
);

const Filters = (props) => {
  return (
    <Filter {...props}>
      <TextInput source="search.serial" label="Serial" />
      <ReferenceInput
        source="facilityId"
        reference="facilities"
        filterToQuery={(name) => ({ search: { name } })}
        variant="outlined"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="parentId"
        reference="districts"
        variant="outlined"
        label="Location"
        filterToQuery={filterToLocationQuery}
      >
        <AutocompleteInput
          option
          optionText={<LocationOptionRenderer />}
          matchSuggestion={matchLocationSuggestion}
          inputText={locationInputText}
        />
      </ReferenceInput>
    </Filter>
  );
};

export const NotificationGrid = (props) => {
  return (
    <MobileListGrid
      {...props}
      metadata={(record) => (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Chip label={record.type} />
          </Grid>
        </Grid>
      )}
      right={(record) => (
        <Typography variant="caption">
          {moment(record.createdAt).fromNow()}
        </Typography>
      )}
      content={(record) => (
        <Typography variant="body1">
          {record.title}
        </Typography>
      )}
      link={(record) => `/notifications/${record.id}/edit`}
    />
  );
};
