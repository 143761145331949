import { SelectField, Show, SimpleShowLayout } from "react-admin";
import React from "react";
import {
  Typography,
  Grid,
  CardActions,
  Card,
  CardContent,
  CardHeader,
  Box,
} from "@material-ui/core";
import { stringify } from "qs";
import { backendUrl } from "../config";
import { FlatActionButton } from "../components/action-button";
import { CloudDownload, CloudUpload } from "@material-ui/icons";
import { InfoField } from "../maintenance/show";
import { assayTypes } from "./constants";
import moment from "moment";
import { TimestampField } from "../components/fields";
import { Conditionally } from "../components/conditionally";
import { client } from "../providers/client";
import { SingleBarChart } from "../dashboard/charts/single-bar";
import { Tabular } from "../dashboard/charts/table";

export function ShowAnalysis(props) {
  return (
    <Show title={<Title />} component={"div"} {...props}>
      <SimpleShowLayout>
        <Details />
        <MissingContent />
        <Charts />
      </SimpleShowLayout>
    </Show>
  );
}

const Charts = ({ record }) => {
  return (
    <Card>
      <CardHeader title={"Analysis"} />

      <Grid container style={{ padding: 10 }} spacing={2}>
        <Grid item xs={12}>
          <SingleBarChart
            title="Over/Under Reporting"
            path={"discrete/reporting/delta"}
            filter={{ id: record.id }}
            showTimeFilter={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Tabular
            title="Facilities"
            path={"category/reporting/facilities"}
            filter={{ id: record.id }}
            showTimeFilter={false}
            modes={[
              {
                name: "variable",
                type: "switch",
                items: [
                  { id: "Total", name: "Total" },
                  { id: "MTB-", name: "MTB-" },
                  { id: "MTB Trace", name: "MTB Trace" },
                  { id: "MTB+RIF+", name: "MTB+RIF+" },
                  { id: "MTB+RIF-", name: "MTB+RIF-" },
                  { id: "Indeterminate", name: "Indeterminate" },
                  { id: "Error", name: "Error" },
                  { id: "Invalid", name: "Invalid" },
                  { id: "No Result", name: "No Result" },
                ],
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Tabular
            {...{
              title: "Discepant Results",
              path: "category/discrepancy/verification",
              type: "tabular",
              defaultRange: {
                type: "between",
                before: record.start,
                after: record.end,
              },
              filterByDate: "completedAt",
              grid: { xs: 12 },
              location: { id: record.districtId, resource: "districts" },
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

const Details = ({ record }) => {
  return (
    <Card>
      <CardHeader title={record.name} />
      <CardContent>
        <Grid container spacing={2}>
          <InfoField
            label={"Assay"}
            value={
              <SelectField
                source="assay"
                choices={assayTypes}
                record={record}
              />
            }
          />
          <InfoField label={"Area"} value={record?.district?.name} />
          <InfoField
            label={"Period"}
            value={`${moment(record.start).format("Do MMMM YYYY")} to ${moment(
              record.end
            ).format("Do MMMM YYYY")}`}
          />
          <InfoField
            label={"Created"}
            value={<TimestampField source="createdAt" record={record} />}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

const MissingContent = ({ record }) => {
  return (
    <Card component={Box} marginTop={2}>
      <CardHeader title={"Reference Data"} />
      <CardContent>
        <Conditionally
          when={!record.metrics.length}
          render={() => (
            <Typography variant="body1">Missing Reference Data</Typography>
          )}
        />
        <Conditionally
          when={record.metrics.length}
          render={() => (
            <Typography variant="body1">
              {record.metrics.length} facilities
            </Typography>
          )}
        />
      </CardContent>
      <CardActions>
        <Grid container spacing={1}>
          <Grid item>
            <FlatActionButton
              icon={<CloudUpload />}
              title={"Upload"}
              handler={() => uploadReferenceDataset(record.id)}
              success={"Done!"}
              variant="contained"
              color={"primary"}
            />
          </Grid>
          <Grid item>
            <FlatActionButton
              icon={<CloudDownload />}
              title={"Download Template"}
              handler={() => downloadTemplate(record.id)}
              success={"Done!"}
              variant="outlined"
              color={"primary"}
            />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

const Title = ({ record }) => {
  return <Typography variant="h6">{record.name}</Typography>;
};

export function downloadTemplate(id) {
  const url = `/portal/analyses/${id}/template?${stringify({
    access_token: localStorage.getItem("token"),
  })}`;
  window.open(`${backendUrl}${url}`);

  return Promise.resolve();
}

function uploadReferenceDataset(id) {
  return new Promise((resolve, reject) => {
    const uploadInput = document.createElement("input");
    uploadInput.type = "file";
    uploadInput.accept = "text/csv";
    uploadInput.capture = "environment";
    uploadInput.onchange = (e) => {
      const [file] = e.target.files;
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
    };
    uploadInput.click();
  }).then((file) => client.put(`/portal/analyses/${id}/template`, { file }));
}
