import axios from "axios";

import config from "../config";
import swConfig from "../swConfig";
import pkg from "../../package.json";

export const client = withVersion(
  withAuth(
    axios.create({
      baseURL: config.backendUrl,
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    })
  )
);

function withAuth(client) {
  client.interceptors.request.use((request) => {
    const accessToken = localStorage.getItem("token");

    return {
      ...request,
      headers: {
        ...request.headers,
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      },
    };
  });

  return client;
}

function withVersion(client) {
  let alerted = false;

  client.interceptors.request.use((request) => {
    return {
      ...request,
      headers: {
        ...request.headers,
        Version: pkg.version,
      },
    };
  });

  client.interceptors.response.use(undefined, (error) => {
    if (error?.response?.data?.type === "VersionError" && !alerted) {
      const { message } = error?.response?.data;
      return Promise.resolve(alert(message))
        .then(() => (alerted = true))
        .then(() => navigator.serviceWorker.getRegistration())
        .then((swRegistration) => swConfig.onUpdate(swRegistration))
        .catch(() => window.location.reload());
    }

    return Promise.reject(error);
  });

  return client;
}
