import { Box } from "@material-ui/core";
import React from "react";
import {
  Create,
  SimpleForm,
  SaveButton,
  Toolbar,
  ReferenceInput,
  SelectInput,
  RadioButtonGroupInput,
  NumberInput,
  TextInput,
  FormDataConsumer,
  DateInput,
  ReferenceField,
  useGetList,
  maxValue,
  minValue,
  AutocompleteInput,
} from "react-admin";
import { Conditionally } from "../components/conditionally";
import { useServerValidation } from "../hooks/validation";
import { StockCard } from "./stock-card";
import { facilityAdmin } from "../roles";

export const CreateAdjustment = (props) => {
  return (
    <Create title={"New Stock Adjustment"} {...props}>
      <Form {...props} />
    </Create>
  );
};

const Form = (props) => {
  const validateAndSave = useServerValidation({
    type: "create",
    resource: "adjustments",
    redirect: "list",
  });
  return (
    <SimpleForm
      {...props}
      save={validateAndSave}
      redirect={"list"}
      toolbar={<CreateToolbar />}
    >
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <>
              <Conditionally
                when={formData.type !== "in"}
                render={() => (
                  <>
                    <ReferenceField reference="adjustments" source="batchId">
                      <StockCard big noGutter />
                    </ReferenceField>
                    <Box marginBottom={2} />
                  </>
                )}
              />

              <Conditionally
                when={["in"].includes(formData.type)}
                render={() => (
                  <>
                    <ReferenceInput
                      label="Item"
                      source="itemId"
                      reference="items"
                      perPage={200}
                      sort={{ field: "name", order: "ASC" }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  </>
                )}
              />

              <Conditionally
                when={
                  ["in"].includes(formData.type) &&
                  props.permissions?.role !== facilityAdmin
                }
                render={() => (
                  <>
                    <ReferenceInput
                      perPage={200}
                      sort={{ field: "name", order: "ASC" }}
                      source="labId"
                      reference="facilities"
                      fullWidth
                      variant="outlined"
                      filterToQuery={(name) => ({ search: { name } })}
                      ss
                    >
                      <AutocompleteInput optionText={"name"} />
                    </ReferenceInput>
                  </>
                )}
              />

              <Conditionally
                when={formData.type === "dispose"}
                render={() => (
                  <>
                    <RadioButtonGroupInput
                      source="disposeReason"
                      label="Reason to dispose"
                      choices={[
                        { id: "expiry", name: "Expiry" },
                        { id: "damage", name: "Damage" },
                        { id: "other", name: "Other" },
                      ]}
                      required
                    />

                    <TextInput
                      source="narrative"
                      label="Narrative"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      multiline
                      minRows={3}
                      required
                    />
                  </>
                )}
              />

              <Conditionally
                when={formData.type === "count"}
                render={() => (
                  <TextInput
                    source="narrative"
                    label="Narrative"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    minRows={3}
                    required
                  />
                )}
              />

              <Conditionally
                when={["in"].includes(formData.type)}
                render={() => (
                  <>
                    <TextInput
                      source="batchNumber"
                      label="Batch/Lot Number"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />

                    <DateInput
                      source="expiry"
                      label="Expiry Date"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </>
                )}
              />

              <QuantityInput batchId={formData.batchId} />
            </>
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  );
};

const CreateToolbar = (props) => {
  return (
    <Toolbar {...props} pristine={false}>
      <SaveButton label="Save" />
    </Toolbar>
  );
};

const QuantityInput = ({ batchId }) => {
  const {
    data,
    ids: [id],
  } = useGetList(
    "adjustments",
    { page: 1, perPage: 1 },
    { field: "createdAt", order: "DESC" },
    { batchId }
  );
  return (
    <>
      <NumberInput
        label="Quantity"
        variant="outlined"
        source="quantity"
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        validate={[
          ...(id && batchId ? [maxValue(data[id]?.batchBalance)] : []),
          minValue(1),
        ]}
      />
    </>
  );
};
