export const userPermissions = [
  { id: "manage", name: "Manage Users" },
  { id: "permissions", name: "Edit Permissions" },
  { id: "suspend", name: "Can Suspend User" },
  { id: "ppi", name: "View Patient Details" },
];

export const machinePermissions = [
  { id: "edit", name: "Edit Machine Details" },
];

export const treatmentPermissions = [
  { id: "patient", name: "Edit Patient Information" },
];

export const maintenancePermissions = [
  { id: "email", name: "Receive maintenance emails" },
];

export const resultPermissions = [
  { id: "discrepant", name: "Audit Results" },
  { id: "rr_notification", name: "Receive Notification for RR cases" },
];

export const assignmentPermissions = [
  { id: "manage", name: "Can manage master list" },
];
