import { Grid } from "@material-ui/core";
import { List, Pagination as RaPagination } from "ra-ui-materialui";
import React from "react";
import { TopToolbar, CreateButton } from "react-admin";
import { ItemCard } from "../stock-card";
import { Conditionally } from "../../components/conditionally";
import { superAdmin } from "../../roles";

export const ListItems = (props) => {
  return (
    <List
      title="Items"
      bulkActionButtons={false}
      perPage={50}
      pagination={<Pagination />}
      actions={<ListActions user={props.permissions} />}
      sort={{ field: "createdAt", order: "DESC" }}
      {...props}
    >
      <ItemGrid />
    </List>
  );
};

const ItemGrid = ({ data, ids }) => {
  return (
    <Grid container>
      {ids
        .map((id) => data[id])
        .map((item) => {
          return (
            <Grid item xs={12} sm={6} md={4}>
              <ItemCard record={item} />
            </Grid>
          );
        })}
    </Grid>
  );
};

const Pagination = (props) => (
  <RaPagination
    rowsPerPageOptions={[10, 25, 50, 100]}
    perPage={50}
    {...props}
  />
);

const ListActions = ({ user }) => (
  <TopToolbar>
    <Conditionally
      when={user?.role === superAdmin}
      render={() => <CreateButton variant="contained" />}
    />
  </TopToolbar>
);
