import { Box, Chip, Grid, IconButton, Paper } from "@material-ui/core";
import { Check, Delete } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import {
  AutocompleteInput,
  FormWithRedirect,
  ReferenceInput,
  SelectArrayInput,
  TextInput,
  useDataProvider,
} from "react-admin";
import { Conditionally } from "../components/conditionally";
import { RaLocationInput } from "../components/ra-location-input";

import {
  filterToLocationQuery,
  locationInputText,
  LocationOptionRenderer,
  matchLocationSuggestion,
} from "../districts/list";
import { pluralise } from "../utils/number";
import { facilityTypes } from "./constants";

const perPage = 50;

export const BulkEditFacilities = () => {
  const provider = useDataProvider();
  const [facilties, setFacilities] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  function list() {
    return provider
      .getList("facilities", {
        pagination: { perPage, page },
        sort: { field: "name", order: "ASC" },
      })
      .then((response) => {
        setTotal(response.total);
        setFacilities(response.data);
      });
  }

  function update(id, data) {
    return provider.update("facilities", {
      id,
      data,
    });
  }

  function remove(id) {
    return provider.delete("facilities", { id }).then(() => list());
  }

  useEffect(() => {
    list();
  }, [page]);

  return (
    <Paper>
      <Box padding={1}>
        <Pagination
          page={page}
          onChange={(_, v) => setPage(v)}
          count={Math.ceil(total / perPage)}
        />
      </Box>
      {facilties.map((facility) => (
        <FacilityAssigner
          key={facility.id}
          facility={facility}
          onUpdate={update}
          onRemove={remove}
        />
      ))}
      <Box padding={1}>
        <Pagination
          page={page}
          onChange={(_, v) => setPage(v)}
          count={Math.ceil(total / perPage)}
        />
      </Box>
    </Paper>
  );
};

export const FacilityAssigner = ({ facility, onUpdate, onRemove }) => {
  return (
    <FormWithRedirect
      record={facility}
      save={(data) => onUpdate(facility.id, data)}
      resource="facilities"
      render={({ handleSubmitWithRedirect }) => {
        return (
          <Grid
            container
            spacing={1}
            component={Box}
            padding={1}
            borderBottom="1px solid #eee"
            alignItems={"center"}
          >
            <Grid item md={3}>
              <TextInput
                source="name"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                helperText=""
              />
            </Grid>

            <Grid item md={3}>
              <SelectArrayInput
                source="type"
                choices={facilityTypes}
                variant="outlined"
                fullWidth
                helperText=""
                margin="dense"
                size="small"
              />
            </Grid>

            <Grid item md={2}>
              <ReferenceInput
                source="districtId"
                reference="districts"
                variant="outlined"
                label="Location"
                filterToQuery={filterToLocationQuery}
              >
                <AutocompleteInput
                  optionText={<LocationOptionRenderer />}
                  matchSuggestion={matchLocationSuggestion}
                  inputText={locationInputText}
                  options={{
                    InputLabelProps: { shrink: true },
                  }}
                  helperText=""
                  fullWidth
                />
              </ReferenceInput>
            </Grid>

            <Grid item md={3}>
              <RaLocationInput source="geolocation" label="Geolocation" />
            </Grid>

            <Grid item md={1}>
              <IconButton onClick={handleSubmitWithRedirect}>
                <Check />
              </IconButton>
              <IconButton onClick={() => onRemove(facility.id)}>
                <Delete />
              </IconButton>
            </Grid>

            <Conditionally
              when={facility.instruments}
              render={() => (
                <Grid item>
                  <Chip
                    label={pluralise(facility.instruments, `instrument`)}
                    size="small"
                    color="primary"
                  />
                </Grid>
              )}
            />
            <Conditionally
              when={facility.installedModules}
              render={() => (
                <Grid item>
                  <Chip
                    label={`${facility.installedModules} modules`}
                    size="small"
                    color="primary"
                  />
                </Grid>
              )}
            />
          </Grid>
        );
      }}
    />
  );
};
