import * as React from "react";
import { Pagination, ReferenceManyField } from "react-admin";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import {
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem as RaTimelineItem,
} from "@material-ui/lab";
import { StatusIcon } from "../components/status";
import { DiffField } from "../timelines/diff";
import { Conditionally } from "./conditionally";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

export const ListEvents = () => {
  return (
    <Card>
      <CardHeader title="Audit Trail" />
      <CardContent>
        <ReferenceManyField
          reference="timelines"
          target="itemId"
          perPage={50}
          pagination={
            <Pagination rowsPerPageOptions={[2, 25, 50, 100]} perPage={50} />
          }
          sort={{ field: "createdAt", order: "DESC" }}
        >
          <EventsTimeLineListGrid />
        </ReferenceManyField>
      </CardContent>
    </Card>
  );
};

export const EventsTimeLineListGrid = ({ ids = [], data = {} }) => {
  const [expandedId, dispatch] = React.useReducer((_, action) => {
    if (action.type === "set") {
      return action.value;
    }

    return null;
  }, null);

  return ids.map((id) => {
    const record = data[id];
    return (
      <RaTimelineItem key={id}>
        <TimelineOppositeContent style={{ flex: 0, padding: 0 }} />
        <TimelineSeparator>
          <TimelineDot variant="outlined">
            <StatusIcon status={record?.status} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Box display="flex" flexDirection="row">
            <Box
              style={{ padding: "12px 0" }}
              flexGrow="1 1 auto"
              width={"100%"}
            >
              <Typography variant="caption">
                {record.status.toUpperCase()}
              </Typography>

              <Tooltip
                title={moment(record.createdAt).format("Do MMM YYYY hh:mma")}
              >
                <Typography variant="body1">
                  <span>{moment(record.createdAt).calendar()}</span>
                </Typography>
              </Tooltip>
              <Conditionally
                when={record.user?.name}
                render={() => (
                  <Typography variant="caption">{` by ${record.user.name}`}</Typography>
                )}
              />
              <Conditionally
                when={record.payload?.type}
                render={() => (
                  <>
                    <Typography variant="caption" color="primary">
                      {record.payload.type?.toUpperCase()}:{" "}
                      {record.payload.subject}
                    </Typography>
                    <Grid container spacing={1}>
                      {(Array.isArray(record.payload.destination)
                        ? record.payload.destination
                        : [record.payload.destination]
                      ).map((x) => (
                        <Grid item>
                          <Chip label={x} size="small" />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              />
            </Box>
            <Conditionally
              when={record.diffs?.length && expandedId !== id}
              render={() => (
                <Box>
                  <IconButton
                    onClick={() => dispatch({ type: "set", value: id })}
                  >
                    <ExpandMore />
                  </IconButton>
                </Box>
              )}
            />
            <Conditionally
              when={expandedId === id}
              render={() => (
                <Box>
                  <IconButton onClick={() => dispatch({ type: "unset" })}>
                    <ExpandLess />
                  </IconButton>
                </Box>
              )}
            />
          </Box>
          <Conditionally
            when={expandedId === id}
            render={() => <DiffField record={record} />}
          />
        </TimelineContent>
      </RaTimelineItem>
    );
  });
};
