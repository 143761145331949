import React from "react";
import {
  Create,
  Edit,
  SimpleForm,
  SaveButton,
  Toolbar,
  required,
  TextInput,
  NumberInput,
  BooleanInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  CheckboxGroupInput,
  ReferenceArrayInput,
} from "react-admin";
import { ImageInput } from "../../components/camera";
import { Conditionally } from "../../components/conditionally";

export const CreateItems = (props) => {
  return (
    <Create title="Add New Item" {...props}>
      <Form {...props} />
    </Create>
  );
};

export const EditItem = (props) => {
  return (
    <Edit title={<Title />} {...props}>
      <Form {...props} />
    </Edit>
  );
};

const Form = (props) => {
  return (
    <SimpleForm {...props} redirect="list" toolbar={<CreateToolbar />}>
      <TextInput
        source="name"
        fullWidth
        validate={[required()]}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextInput
        label="Unit"
        source="unit"
        fullWidth
        validate={[required()]}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />

      <ImageInput label="Picture" source="image" />

      <BooleanInput
        source="consumption.enabled"
        label="Enable Auto Consumption"
      />

      <FormDataConsumer>
        {({ formData }) => {
          return (
            <>
              <Conditionally
                when={formData.consumption?.enabled}
                render={() => (
                  <RadioButtonGroupInput
                    source="consumption.filter"
                    label="Filter By"
                    choices={[
                      { id: "cartridge", name: "Cartridge" },
                      { id: "none", name: "None" },
                    ]}
                  />
                )}
              />

              <Conditionally
                when={
                  formData.consumption?.enabled &&
                  formData.consumption?.filter === "cartridge"
                }
                render={() => (
                  <ReferenceArrayInput
                    reference="results/cartridges"
                    source="consumption.cartridges"
                    label="Select Cartridges"
                  >
                    <CheckboxGroupInput optionText="name" />
                  </ReferenceArrayInput>
                )}
              />

              <Conditionally
                when={formData.consumption?.enabled}
                render={() => (
                  <div>
                    <NumberInput
                      source="consumption.quantity"
                      label={`Auto Comsume Quantity`}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                )}
              />
            </>
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  );
};

const CreateToolbar = (props) => {
  return (
    <Toolbar {...props} pristine={false}>
      <SaveButton label="Save" />
    </Toolbar>
  );
};

const Title = ({ record }) => record && `${record.name}`;
