import React from "react";
import {
  Show,
  Button,
  SimpleShowLayout,
  ReferenceManyField,
  Datagrid,
  TextField
} from "react-admin";
import { Box, Card, CardHeader } from "@material-ui/core";
import { StockCard } from "./stock-card";
import { Delete, Remove } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { StatusField } from "../components/status";
import { TimestampField } from "../components/fields";
import { Conditionally } from "../components/conditionally";

const Title = ({ record }) => ``;

export const ShowStock = props => (
  <Show title={<Title />} component="div" actions={null} {...props}>
    <SimpleShowLayout>
      <StockCard big noGutter />
      <StockActions />
      <StockHistory />
    </SimpleShowLayout>
  </Show>
);

export const StockHistory = ({ record }) => {
  return (
    <Card component={Box} marginTop={2}>
      <CardHeader title={"History"} />
      <ReferenceManyField
        reference="adjustments"
        target="batchId"
        record={record}
        sort={{ field: "createdAt", order: "DESC" }}
      >
        <Datagrid>
          <StatusField source="type" size="small" />
          <TextField source="quantity" />
          <TextField source="batchBalance" label={"Balance"} />
          <TimestampField source="createdAt" />
        </Datagrid>
      </ReferenceManyField>
    </Card>
  );
};

export const StockActions = ({ record }) => {
  return (
    <Conditionally
      when={
        record.latestBatchBalance
          ? record.latestBatchBalance.batchBalance > 0
          : record.quantity > 0
      }
      render={() => (
        <Box display={"flex"} padding={2} marginY={2}>
          <Button
            component={Link}
            to={`/adjustments/create?source=${JSON.stringify({
              type: "out",
              labId: record.labId,
              itemId: record.itemId,
              batchId: record.id
            })}`}
            label="Issue"
            variant="contained"
            size="small"
          >
            <Remove />
          </Button>
          <Box paddingX={1} />
          <Button
            component={Link}
            to={`/adjustments/create?source=${JSON.stringify({
              type: "dispose",
              labId: record.labId,
              itemId: record.itemId,
              batchId: record.id
            })}`}
            label="Dispose"
            variant="outlined"
            size="small"
          >
            <Delete />
          </Button>
        </Box>
      )}
    />
  );
};
