import { NotificationImportant } from "@material-ui/icons";
import { CreateNotification, EditNotification } from "./create-edit";
import { ListNotifications } from "./list";

export const notifications = {
  name: "notifications",
  options: {
    label: "Notifications",
  },
  icon: NotificationImportant,
  list: ListNotifications,
  create: CreateNotification,
  edit: EditNotification,
};
