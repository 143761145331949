import * as React from "react";
import { linkToRecord, ReferenceManyField, Pagination } from "react-admin";
import { Link } from "react-router-dom";
import { Tooltip, Typography } from "@material-ui/core";
import moment from "moment";
import {
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem as RaTimelineItem,
} from "@material-ui/lab";
import { path } from "ramda";
import { StatusIcon } from "../components/status";
const pluralize = require("pluralize");

export const ListUserActions = () => {
  return (
    <ReferenceManyField
      reference="timelines"
      target="userId"
      perPage={10}
      pagination={
        <Pagination rowsPerPageOptions={[5, 25, 50, 100]} perPage={50} />
      }
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <UserTimeLineListGrid />
    </ReferenceManyField>
  );
};

export const UserTimeLineListGrid = ({ ids = [], data = {} }) => {
  return ids.map((id) => {
    const record = data[id];
    return (
      <RaTimelineItem key={id}>
        <TimelineOppositeContent style={{ flex: 0, padding: 0 }} />
        <TimelineSeparator>
          <TimelineDot variant="outlined">
            <StatusIcon status={record.status} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div style={{ padding: "12px 0" }}>
            <Typography variant="caption">
              {record?.status?.toUpperCase()} <ItemField record={record} />
            </Typography>
            <Typography variant="body1">
              <Tooltip
                title={moment(record.createdAt).format("Do MMM YYYY hh:mma")}
              >
                <span>{moment(record.createdAt).calendar()}</span>
              </Tooltip>
              {` by ${record?.user?.name}`}
            </Typography>
          </div>
        </TimelineContent>
      </RaTimelineItem>
    );
  });
};

export const ItemField = ({ record }) => {
  if (record.status === "deleted") {
    return <Link to="#">{`${record.itemType} ${record.itemId}`}</Link>;
  }

  const resourceName = pluralize(`${record.itemType.toLowerCase()}`);
  const itemShowPage = linkToRecord(`/${resourceName}`, record.itemId, "show");
  const identifiers = mapper[record.itemType] || ["id"];
  const itemNames = identifiers
    .map((key) => path(key.split("."), record.item))
    .join(" ");

  return (
    <Link to={itemShowPage}>
      <Typography variant="caption">
        {record.itemType} {itemNames}
      </Typography>
    </Link>
  );
};

const mapper = {
  Account: ["name"],
  Company: ["name"],
  Cryobox: ["reference"],
  Currency: ["name"],
  Deposit: ["companyId"],
  District: ["name"],
  Facility: ["name"],
  HealthFacility: ["name"],
  Kit: ["name"],
  Ledger: ["companyId"],
  Machine: ["name"],
  Order: ["orderNumber"],
  Patient: ["email", "surname", "firstname"],
  Payment: ["serial"],
  Request: ["serial"],
  Result: ["instrument.sampleId"],
  Service: ["title"],
  Subscription: ["userId"],
  Test: ["title"],
  User: ["name", "email"],
  Worksheet: ["runId"],
};
