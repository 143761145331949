import { formatNumber } from "./currency";

const suffixes = [null, "k", "m", "b"];

export function compactNumeric(value) {
  const exponent = Math.floor(Math.log10(value));

  if (!exponent || exponent < 3) {
    return value;
  }

  const suffixIndex = Math.floor(exponent / 3);
  const suffix = suffixes[suffixIndex];
  const compacted = (value / Math.pow(1000, suffixIndex)).toFixed(1);

  return `${+compacted}${suffix}`;
}

export function percent(value = 0) {
  return `${(value * 100).toFixed(2)}%`;
}

export function percentInt(value) {
  if (value < 0.01) {
    return `${(value * 100).toFixed(2)}%`;
  }

  return `${(value * 100).toFixed(0)}%`;
}

export function formatOrdinal(n) {
  var s = ["th", "st", "nd", "rd"];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function pluralise(count, noun, suffix = "s") {
  return `${formatNumber(count)} ${noun}${count !== 1 ? suffix : ""}`;
}

export function getCount(value, arr) {
  return arr.filter(element => element === value).length;
}
