import * as React from "react";
import {
  DashboardMenuItem,
  MenuItemLink,
  usePermissions,
  useToggleSidebar,
  useTranslate,
} from "react-admin";
import {
  Box,
  Collapse,
  ListItemIcon,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import classnames from "classnames";

import { getResources } from "../resources";
import { users } from "../users";
import facilities from "../facilities";
import districts from "../districts";
import machines from "../machines";
import results from "../results";
import items from "../inventory/items";
import { inventoryBatches } from "../inventory/batches";
import { levels } from "../levels";
import { Conditionally } from "../components/conditionally";
import { cases } from "../cases";
import { maintenanceTickets } from "../maintenance";
import { timelines } from "../timelines";
import facilityAssignments from "../facility-assignment";
import { analyses } from "../analyses";
import { notifications } from "../notifications";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { useTheme } from "../theme";

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: "0.5em",
      marginBottom: "1em",
      [theme.breakpoints.only("xs")]: {
        marginTop: 0,
      },
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    open: { width: "200px" },
    closed: { width: "48px" },
  }),
  { name: "RaMenu" }
);

export const Menu = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const { permissions: user } = usePermissions();
  const [open] = useToggleSidebar();
  const resources = getResources(user);

  const filter = (items) =>
    items
      .filter((resource) => resources.some((r) => r.name === resource.name))
      .filter((resource) =>
        resources.some((r) => r.name === resource.name && r.list)
      );

  const menuResources = filter([
    facilities,
    machines,
    maintenanceTickets,
    results,
    cases,
    analyses,
    inventoryBatches,
    items,
    users,
  ]);

  const settingsResources = filter([
    levels,
    districts,
    timelines,
    facilityAssignments,
    notifications,
  ]);

  return (
    <Box
      marginTop={2}
      className={classnames(classes.main, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
      width={"100%"}
    >
      <DashboardMenuItem />

      {menuResources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={translate(`resources.${resource.name}.name`)}
          leftIcon={<resource.icon />}
        />
      ))}

      <Conditionally
        when={settingsResources.length}
        render={() => (
          <ExpandedMenu sidebarIsOpen={open}>
            {settingsResources.map((resource) => (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={translate(`resources.${resource.name}.name`)}
                leftIcon={<resource.icon color="inherit" />}
              />
            ))}
          </ExpandedMenu>
        )}
      />
    </Box>
  );
};

const ExpandedMenu = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen((v) => !v);
  }

  return (
    <Box bgcolor={open ? "#efefef" : undefined}>
      <MenuItem to={"/more"} primaryText="More" onClick={handleClick}>
        <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </ListItemIcon>
        {"More"}
      </MenuItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Box>
  );
};
