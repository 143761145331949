import React from "react";
import {
  List,
  Filter,
  SelectInput,
  Pagination as RaPagination,
  TextInput,
  ExportButton,
  TopToolbar,
  CreateButton,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { Link } from "react-router-dom";

import { dho, facilityAdmin, roles } from "../roles";
import { MobileListGrid } from "../components/mobile-list-grid";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { AvatarField } from "../components/avatar";
import { SortButton } from "../components/sort";
import { Conditionally } from "../components/conditionally";
import {
  filterToLocationQuery,
  locationInputText,
  LocationOptionRenderer,
  matchLocationSuggestion,
} from "../districts/list";
import { Add, Phone } from "@material-ui/icons";
import { TimestampField } from "../components/fields";

export const UserList = (props) => (
  <List
    title="Users"
    actions={<ListActions />}
    filters={<UserFilter />}
    bulkActionButtons={false}
    perPage={50}
    pagination={<Pagination />}
    sort={{ field: "createdAt", order: "DESC" }}
    filterDefaultValues={{ suspended: false }}
    {...props}
  >
    <UserListGrid />
  </List>
);

export const UserListGrid = (props) => (
  <MobileListGrid
    {...props}
    metadata={() => <></>}
    content={(record) => {
      return (
        <Link to={`/users/${record.id}/show`} style={{ color: "inherit" }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <AvatarField record={record} source="avatar" />
            </Grid>
            <Grid item>
              <Typography variant="body1">{record?.name}</Typography>
              <Typography variant="caption" color="primary">
                {roles.find((role) => role.id === record.role)?.name}
              </Typography>
              <Conditionally
                when={record.role === facilityAdmin}
                render={() => (
                  <Box>
                    <Typography variant="caption">
                      {record.facility?.name}
                    </Typography>
                  </Box>
                )}
              />
              <Conditionally
                when={record.role === dho}
                render={() => (
                  <Box>
                    <Typography variant="caption">
                      {record.district?.name}
                    </Typography>
                  </Box>
                )}
              />
            </Grid>
          </Grid>
        </Link>
      );
    }}
    right={(record) => (
      <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
        <Conditionally
          when={record.phone}
          render={() => (
            <IconButton href={`tel:${record.phone}`}>
              <Phone />
            </IconButton>
          )}
        />
        <TimestampField record={record} label="Created" source="createdAt" />
      </Box>
    )}
  />
);

const Pagination = (props) => (
  <RaPagination
    rowsPerPageOptions={[10, 25, 50, 100]}
    perPage={50}
    {...props}
  />
);

const ListActions = ({
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
}) => (
  <TopToolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <SortButton
      fields={[
        { id: "name", name: "Name" },
        { id: "role", name: "Role" },
        { id: "createdAt", name: "Date Created" },
      ]}
    />
    <CreateButton label="">
      <Add />
    </CreateButton>
    <ExportButton label="" />
  </TopToolbar>
);

const UserFilter = ({ user, ...props }) => {
  return (
    <Filter {...props}>
      <TextInput label="Name" source="search.name" variant="outlined" />
      <TextInput label="Email" source="search.email" variant="outlined" />
      <SelectInput
        label="Role"
        source="role"
        choices={roles}
        variant="outlined"
      />
      <SelectInput
        label="Status"
        source="suspended"
        choices={[
          { id: false, name: "Active" },
          { id: true, name: "Suspended" },
        ]}
        variant="outlined"
      />
      <ReferenceInput
        source="facilityId"
        reference="facilities"
        variant="outlined"
        label="Facility"
        filterToQuery={(name) => ({ search: { name } })}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        source="districtId"
        reference="districts"
        variant="outlined"
        label="Area"
        filterToQuery={filterToLocationQuery}
      >
        <AutocompleteInput
          option
          optionText={<LocationOptionRenderer />}
          matchSuggestion={matchLocationSuggestion}
          inputText={locationInputText}
        />
      </ReferenceInput>
    </Filter>
  );
};
