import TestIcon from "@material-ui/icons/Router";

import { ListTests } from "./list";
import { CreateTest, EditTest } from "./create-edit";

const tests = {
  name: "tests",
  options: {
    label: "Tests"
  },
  icon: TestIcon,
  list: ListTests,
  create: CreateTest,
  edit: EditTest
};

export default tests;
