import React, { Fragment } from "react";
import {
  AutocompleteInput,
  Create,
  DateInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import { machineTypes } from "./constants";

export const CreateFacilityAssignment = (props) => {
  return (
    <Fragment>
      <div>
        <Create title={"Assign Instrument"} {...props}>
          <Form {...props} />
        </Create>
      </div>
    </Fragment>
  );
};

export const EditFacilityAssignment = (props) => (
  <Edit title="Edit Facility" {...props}>
    <Form {...props} />
  </Edit>
);

const Form = (props) => {
  return (
    <SimpleForm {...props} redirect={"show"}>
      <SelectInput source="type" choices={machineTypes} />
      <ReferenceInput
        source="facilityId"
        reference="facilities"
        filterToQuery={(name) => ({ search: { name } })}
        variant="outlined"
        fullWidth
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <TextInput
        source="serial"
        reference="instruments"
        fullWidth
        variant="outlined"
      />

      <DateInput source="start" variant="outlined" fullWidth />

      <DateInput source="end" variant="outlined" fullWidth />
    </SimpleForm>
  );
};
