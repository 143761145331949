import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";

export const EditMachine = props => (
  <Edit title="Edit Equipment" {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput disabled source="name" fullWidth />
      <ReferenceInput
        label="Facility"
        source="facilityId"
        reference="facilities"
        filterToQuery={(name) => ({ search: { name } })}
        fullWidth
      >
        <AutocompleteInput
          options={{
            fullWidth: true,
            InputLabelProps: {
              shrink: true
            }
          }}
          optionText="name"
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

const redirect = (_basePath, _id) => `/machines`;
