import React from "react";
import ReactDOM from "react-dom";
import * as moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";
import { googleAnalyticsMeasurementId, sentryDsn } from "./config";
import * as serviceWorker from "./serviceWorker";
import swConfig from "./swConfig";
import { installGATag } from "./google-analytics";
import { SettingsProvider } from "./settings";

Sentry.init({
  dsn: sentryDsn,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
});

moment.relativeTimeRounding(Math.floor);

installGATag(googleAnalyticsMeasurementId);

ReactDOM.render(
  <SettingsProvider>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </SettingsProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(swConfig);
