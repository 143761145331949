import React from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import * as jwtDecode from "jwt-decode";
import { useSettings } from "./settings";

export const Support = () => {
  const settings = useSettings();
  function onLoad() {
    setTawkAttributes(settings["instance-name"]);
  }

  return (
    <TawkMessengerReact
      propertyId="62de9ce937898912e95f8b2a"
      widgetId="1g8qp9std"
      onLoad={onLoad}
    />
  );
};

export function setTawkAttributes(instanceName) {
  const token = localStorage.getItem("token");
  if (token) {
    const user = jwtDecode(token);
    const attributes = {
      userId: user.id,
      name: user.name,
      email: user.email,
      role: user.role,
      facility: user.facility?.name,
      area: user.district?.name,
      org: instanceName,
    };

    window.Tawk_API.login(attributes, console.log);
  }
}
