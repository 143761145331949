import { Build } from "@material-ui/icons";

import { ShowMaintenance } from "./show";
import { MaintenanceList } from "./list";
import { CreateMaintenanceTicket } from "./create";

export const maintenanceTickets = {
  name: "maintenance",
  options: {
    label: "Maintenance",
  },
  icon: Build,
  create: CreateMaintenanceTicket,
  list: MaintenanceList,
  show: ShowMaintenance,
};
