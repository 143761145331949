import React from "react";
import { Box, Checkbox, Grid, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Conditionally } from "./conditionally";

export const MobileListGrid = ({
  ids = [],
  data = {},
  metadata,
  content,
  right,
  link,
  onClick,
  hasBulkActions,
  onSelect,
  selectedIds,
}) => (
  <>
    <Conditionally
      when={hasBulkActions}
      render={() => (
        <Paper
          component={Box}
          style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
        >
          <Checkbox
            checked={selectedIds.length === ids.length}
            onChange={(e) => onSelect(e.target.checked ? ids : [])}
          />
        </Paper>
      )}
    />
    {ids.map((id) => {
      const record = data[id];
      if (!record) return null;

      return (
        <Paper component={Box} display="flex" square alignItems="center">
          <Conditionally
            when={hasBulkActions}
            render={() => (
              <Checkbox
                checked={selectedIds.includes(id)}
                onChange={(e) =>
                  onSelect(
                    e.target.checked
                      ? [...selectedIds, id]
                      : selectedIds.filter((x) => x !== id)
                  )
                }
              />
            )}
          />

          <Box
            {...(link ? { component: Link, to: link(record) } : {})}
            {...(onClick ? { onClick: () => onClick(record) } : {})}
            padding={1}
            flexGrow={1}
            color={"black"}
          >
            <Grid container direction="column" spacing={1}>
              {metadata && <Grid item>{metadata(record)}</Grid>}
              <Grid item>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="nowrap"
                >
                  {content && <Grid item>{content(record)}</Grid>}
                  {right && <Grid item>{right(record)}</Grid>}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      );
    })}
  </>
);
