import * as React from "react";
import { Box, CircularProgress, Paper, Typography } from "@material-ui/core";
import { useLogin, useNotify } from "react-admin";
import * as jwtDecode from "jwt-decode";

import { AuthLayout } from "./layout";
import { gtag } from "../google-analytics";

export const LoginWithPasscode = (props) => {
  const login = useLogin();
  const notify = useNotify();

  React.useEffect(() => {
    localStorage.removeItem("token");

    login({ code: props.match.params.code })
      .then(() => {
        const user = jwtDecode(localStorage.getItem("token"));
        gtag("event", "login");
        gtag("set", "user_id", user.id);
        gtag("set", "user_properties", {
          role: user.role,
        });

        window.open("/", "_self");
      })
      .catch((error) => {
        notify(error.response?.data?.message || error.message, "error");
      });
  }, []);

  return (
    <AuthLayout>
      <Paper
        component={Box}
        padding={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
        <Box marginTop={2} />
        <Typography variant="body1">Logging you in!</Typography>
      </Paper>
    </AuthLayout>
  );
};
