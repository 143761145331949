import React from "react";
import { CloudDownload } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

import { backendUrl } from "../config";

export const DownloadDump = () => {
  function handleDownload() {
    window.open(
      `${backendUrl}/portal/dumpdb?access_token=${localStorage.getItem(
        "token"
      )}`
    );
  }

  return (
    <IconButton size="small" onClick={handleDownload}>
      <CloudDownload />
    </IconButton>
  );
};
