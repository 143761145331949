import { AllInbox } from "@material-ui/icons";

import { ListItems } from "./list";
import { CreateItems, EditItem } from "./create-edit";
import { ShowItem } from "./show";

const items = {
  name: "items",
  options: {
    label: "Stock"
  },
  icon: AllInbox,
  list: ListItems,
  create: CreateItems,
  edit: EditItem,
  show: ShowItem
};

export default items;
