import { ListFacilityAssignments } from "./list";
import { CreateFacilityAssignment, EditFacilityAssignment } from "./create-edit";
import { ViewArrayTwoTone } from "@material-ui/icons";

const facilityAssignments = {
  name: "facility-assignments",
  options: {
    label: "Master List",
  },
  icon: ViewArrayTwoTone,
  list: ListFacilityAssignments,
  create: CreateFacilityAssignment,
  edit: EditFacilityAssignment
};

export default facilityAssignments;
