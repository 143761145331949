import React from "react";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  BarChart as ReBarChart,
} from "recharts";
import { ChartContainer } from "./container";

export const CategoryBarChart = ({
  hideYAxis,
  defaultStep = "day",
  format = (d) => d,
  tickFormat = (d) => d,
  nameKey,
  valueKey,
  color,
  ...props
}) => {
  return (
    <ChartContainer
      {...props}
      defaultStep={defaultStep}
      defaultState={{
        series: [],
        stats: [],
        period: {},
      }}
    >
      {({ value: { series, stats, period } }) => {
        return (
          <ResponsiveContainer width={"100%"} height={240}>
            <ReBarChart
              data={stats}
              barGap={4}
            >
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis dataKey={nameKey} name="Time" type="category" />
              <YAxis
                type="number"
                width={0}
                tickFormatter={tickFormat}
              />
              <Tooltip formatter={format} />

              <Bar
                type="monotone"
                dataKey={valueKey}
                fill={color}
                stackId="a"
              />
            </ReBarChart>
          </ResponsiveContainer>
        );
      }}
    </ChartContainer>
  );
};
