import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  TextInput,
  FormDataConsumer,
  AutocompleteInput,
} from "react-admin";
import { MultipleImageInput } from "../components/camera";
import { Conditionally } from "../components/conditionally";
import { FileInput } from "../components/file-input";

import { issueTypes } from "./constants";
import { facilityAdmin } from "../roles";

export const CreateMaintenanceTicket = (props) => {
  return (
    <Create title={<CreateTitle />} {...props}>
      <MaintenanceForm {...props} />
    </Create>
  );
};

const MaintenanceForm = (props) => {
  return (
    <SimpleForm {...props} redirect={"show"}>
      <SelectInput
        variant="outlined"
        source="issueType"
        choices={issueTypes}
        fullWidth
        validate={[required()]}
      />

      <TextInput
        source="description"
        variant="outlined"
        multiline
        minRows={3}
        fullWidth
        validate={[required()]}
      />

      <Conditionally
        when={props?.permissions?.role !== facilityAdmin}
        render={() => (
          <ReferenceInput
            label="Facility"
            style={{ minWidth: "95%" }}
            source="facilityId"
            reference="facilities"
            variant="outlined"
            filterToQuery={(name) => ({ search: { name } })}
            fullWidth
            validate={[required()]}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        )}
      />

      <FormDataConsumer>
        {({ formData: { issueType } }) => (
          <>
            <Conditionally
              when={["computer", "software"].includes(issueType)}
              render={() => (
                <TextInput
                  source="computerSerialNumber"
                  variant="outlined"
                  label={"Computer Serial Number"}
                  fullWidth
                  validate={[required()]}
                />
              )}
            />
            <Conditionally
              when={["module", "other"].includes(issueType)}
              render={() => (
                <TextInput
                  source="serialNumber"
                  variant="outlined"
                  label={"Instrument Serial"}
                  fullWidth
                  validate={[required()]}
                />
              )}
            />
            <Conditionally
              when={["module"].includes(issueType)}
              render={() => (
                <TextInput
                  source="failedModuleSerialNumber"
                  variant="outlined"
                  label={"Failed Module Serial"}
                  fullWidth
                  validate={[required()]}
                />
              )}
            />
            <Conditionally
              when={["computer", "software", "other"].includes(issueType)}
              render={() => (
                <MultipleImageInput source="photo" label="Photo / Screenshot" />
              )}
            />

            <Conditionally
              when={["module", "other"].includes(issueType)}
              render={() => (
                <>
                  <FileInput
                    source="installationQualificationReport"
                    label="Installation Qualification Report"
                    fileType="pdf"
                  />

                  <FileInput
                    source="systemLogReport"
                    label="System Log Report"
                    fileType="pdf"
                  />

                  <FileInput
                    source="resultArchiveReport"
                    label="Result Archive (3 months)"
                    fileType="gxx"
                  />
                </>
              )}
            />
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
};

const CreateTitle = () => "Create Maintenance Ticket";
