import { Box, Tooltip, Typography } from "@material-ui/core";
import { Build, Close, Eject } from "@material-ui/icons";
import moment from "moment";
import { mergeAll } from "ramda";
import React, { useEffect, useRef, useState } from "react";
import { Conditionally } from "../../components/conditionally";
import { ChartContainer } from "./container";

const keys = [{ number: "0" }, {}, {}, {}, {}, { number: "5+" }];

const eventIcons = {
  Replaced: <Eject style={{ color: "white", fontSize: "16px" }} />,
  Failure: <Close style={{ color: "white", fontSize: "16px" }} />,
  Calibration: <Build style={{ color: "white", fontSize: "16px" }} />,
};

export const HeatmapChart = ({
  defaultStep = "day",
  format = (d) => d,
  tickFormat = (d) => d,
  ...props
}) => {
  const containerRef = useRef();
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    setMaxWidth(containerRef.current.clientWidth);
  }, [containerRef.current]);

  return (
    <ChartContainer {...props} defaultState={{ series: [] }}>
      {({ value: { series, stats } }) => {
        return (
          <div position={"relative"} ref={containerRef}>
            <Box paddingY={2} maxWidth={maxWidth} overflow="auto">
              {series?.map((serie, index) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  paddingX={"16px"}
                  paddingY={"2px"}
                  borderRadius={"16px"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent="center"
                    marginRight={"8px"}
                  >
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "700" }}
                      align="center"
                    >
                      {serie.name}
                    </Typography>
                  </Box>
                  {stats.map((stat, i) => (
                    <HeatmapDatum
                      key={i}
                      {...mergeAll(
                        [
                          "failures",
                          "tests",
                          "replacements",
                          "enables",
                          "disables",
                          "predicted",
                        ].map((event) => ({
                          [event]: stat[serie.identifier + "_" + event],
                        }))
                      )}
                      day={stat._id}
                    />
                  ))}
                </Box>
              ))}
            </Box>
            <Box
              display={"flex"}
              flexDirection="row"
              justifyContent={"space-between"}
              marginBottom={2}
            >
              <Box display={"flex"} flexDirection="row">
                {keys.map((key, index) => (
                  <Box
                    key={index}
                    item
                    style={{
                      background:
                        index === 0
                          ? "#ccc"
                          : `rgba(0,100,0,${(index + 1) / 5})`,
                      color: "#fff",
                    }}
                    padding="2px 4px"
                    textAlign="center"
                    width="24px"
                    height="24px"
                    marginRight={"4px"}
                    borderRadius="12px"
                    display={"flex"}
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Typography variant="caption" color="inherit">
                      {key?.number}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <EventLegend events={eventIcons} />
            </Box>
          </div>
        );
      }}
    </ChartContainer>
  );
};

const EventLegend = ({ events = {} }) => {
  return (
    <Box display={"flex"} flexDirection="row">
      {Object.keys(events).map((event) => (
        <Box
          display={"flex"}
          flexDirection="row"
          alignItems={"center"}
          marginRight={1}
        >
          <Box
            marginRight={"8px"}
            width="20px"
            height="20px"
            borderRadius="16px"
            bgcolor={"#ccc"}
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            {events[event]}
          </Box>
          <Typography>{event}</Typography>
        </Box>
      ))}
      <Box
        display={"flex"}
        flexDirection="row"
        alignItems={"center"}
        marginRight={1}
      >
        <Box
          marginRight={"8px"}
          width="20px"
          height="20px"
          borderRadius="16px"
          border={"4px green solid"}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        />
        <Typography>Enabled</Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection="row"
        alignItems={"center"}
        marginRight={1}
      >
        <Box
          marginRight={"8px"}
          width="20px"
          height="20px"
          borderRadius="16px"
          border="4px red solid"
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        />
        <Typography>Disabled</Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection="row"
        alignItems={"center"}
        marginRight={1}
      >
        <Box
          marginRight={"8px"}
          width="20px"
          height="20px"
          borderRadius="16px"
          border="4px black dotted"
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        />
        <Typography>Predictive</Typography>
      </Box>
    </Box>
  );
};

const HeatmapDatum = ({
  failures,
  tests,
  replacements,
  enables,
  disables,
  predicted,
  day,
}) => {
  return (
    <Tooltip
      title={
        <>
          <Typography>{moment(day).format("Do MMMM YYYY")}</Typography>
          <ul>
            <Conditionally
              when={tests > 0}
              render={() => (
                <li>
                  <Typography variant="body2">{tests} Tests</Typography>
                </li>
              )}
            />
            <Conditionally
              when={failures > 0}
              render={() => (
                <li>
                  <Typography variant="body2">{failures} Failures</Typography>
                </li>
              )}
            />
            {/* <Conditionally
              when={replacements.filter((d) => d).length > 0}
              render={() =>
                replacements
                  .filter((d) => d)
                  .map(([from, to]) => (
                    <li>
                      <Typography variant="body2">
                        {from} replaced with {to}
                      </Typography>
                    </li>
                  ))
              }
            /> */}
          </ul>
        </>
      }
    >
      <Box
        bgcolor={
          tests === 0 ? "#ccc" : `rgba(0,100,0,${(colorMapper(tests) + 1) / 5})`
        }
        style={{
          borderWidth: "4px",
          borderStyle: predicted ? "dotted" : "solid",
          borderColor: disables ? "red" : enables ? "green" : "grey",
        }}
        padding="4px"
        marginRight={"2px"}
        width="20px"
        height="20px"
        borderRadius="16px"
        flexShrink={0}
        display="flex"
        alignItems={"center"}
        justifyContent="center"
      >
        {replacements.filter((d) => d).length
          ? eventIcons.Replaced
          : failures > 0
          ? eventIcons.Failure
          : null}
      </Box>
    </Tooltip>
  );
};

const colorMapper = (count = 0) => {
  if (count >= 5) {
    return 5;
  }

  if (count <= 0) {
    return 0;
  }

  return count;
};
