import { Create, Edit } from "react-admin";
import {
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tab,
  Tabs,
  OutlinedInput,
  Switch,
  Paper,
  Box,
} from "@material-ui/core";
import { TabPanel, tabHeaderProps } from "./tabPanel";
import React, { useEffect, useState } from "react";
import {
  useCreateController,
  CreateContextProvider,
  useEditController,
  EditContextProvider,
  required,
  Button as RaButton,
} from "react-admin";
import { Save, ArrowUpward, ArrowDownward, Close } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import ChipInput from "material-ui-chip-input";
import { v4 as uuid } from "uuid";
import {
  questionFormats,
  sampleTypeOptions,
  testMethods,
  parameterTypes,
  referenceRangeConditionTypes,
  referenceRangePatientConditionFields,
  referenceRangeRequestConditionFieldFormats,
  quantitativeFields,
  selectionFields,
  referenceRangeSampleConditionFields,
  quantitativeFieldsComaparators,
  selectionFieldsComparators,
} from "./constants";
import { Conditionally } from "../components/conditionally";

export const CreateTest = (props) => {
  const createControllerProps = useCreateController(props);
  const { save, record } = createControllerProps;
  return (
    <Create {...props} title={<CreateTitle />}>
      <CreateContextProvider value={createControllerProps}>
        <Form record={record} save={save} />
      </CreateContextProvider>
    </Create>
  );
};

export const EditTest = (props) => {
  const editControllerProps = useEditController(props);
  const { save, record } = editControllerProps;
  return (
    <Edit {...props} title="Edit Test">
      <EditContextProvider value={editControllerProps}>
        <Form record={record} save={save} />
      </EditContextProvider>
    </Edit>
  );
};

const Form = ({ record = {}, save }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [sampleTypes, setSampleTypes] = useState([]);
  const [testMethod, setTestMethod] = useState("");
  const [pcrPositiveMessage, setPcrPositiveMessage] = useState("");
  const [pcrNegativeMessage, setPcrNegativeMessage] = useState("");
  const [rdtKits, setRdtKits] = useState([]);
  const [manualResultEntry, setManualResultEntry] = useState(false);
  const [disabledTest, setDisabledTest] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [parameters, setParameters] = useState([]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleChecked = () => {
    setManualResultEntry((prev) => !prev);
  };

  const toggleDisabled = () => {
    setDisabledTest((prev) => !prev);
  };

  useEffect(() => {
    setTitle(record.title || "");
    setDescription(record.description || "");
    setSampleTypes(record.sampleTypes || []);
    setPcrNegativeMessage(record.pcrNegativeMessage);
    setPcrPositiveMessage(record.pcrPositiveMessage);
    setTestMethod(record.testMethod || 0);
    setRdtKits(record.rdtKits || []);
    setManualResultEntry(record.manualResultEntry || false);
    setDisabledTest(record.disabledTest || false);
    setQuestions(record.questions || []);
    setParameters(record.parameters || []);
  }, [record]);

  function handleSave() {
    save({
      title,
      description,
      sampleTypes,
      testMethod,
      pcrNegativeMessage,
      pcrPositiveMessage,
      rdtKits,
      manualResultEntry,
      questions,
      parameters,
      disabledTest,
    });
  }

  return (
    <Card component="form" style={{ marginTop: "1em", border: "none" }}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="test tabs"
      >
        <Tab
          style={{ textTransform: "capitalize" }}
          label="Details"
          {...tabHeaderProps(0)}
        />
        <Tab
          style={{ textTransform: "capitalize" }}
          label="Parameters"
          {...tabHeaderProps(1)}
        />
        <Tab
          style={{ textTransform: "capitalize" }}
          label="Questions"
          {...tabHeaderProps(2)}
        />
      </Tabs>
      <CardContent>
        <TabPanel value={value} index={0}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <TextField
                label="Name"
                variant="outlined"
                type="text"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                validate={[required()]}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Description"
                variant="outlined"
                type="text"
                fullWidth
                multiline
                minRows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="select-label" variant="outlined">
                  Sample Types
                </InputLabel>
                <Select
                  multiple
                  value={sampleTypes}
                  onChange={(e) => setSampleTypes(e.target.value)}
                  labelId="select-label"
                  input={<OutlinedInput label="Sample Types" />}
                >
                  {sampleTypeOptions.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <DropdownField
                label="Technology"
                options={testMethods}
                value={testMethod}
                onChange={(e) => setTestMethod(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {testMethod !== "RDT" ? null : (
              <Grid item>
                <ChipInput
                  label="RDT Kits"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  allowDuplicates={false}
                  value={rdtKits}
                  chipRenderer={chipRenderer}
                  onAdd={(value) => setRdtKits([...rdtKits, value])}
                  onDelete={(value) => {
                    setRdtKits(rdtKits.filter((v) => v !== value));
                  }}
                />
              </Grid>
            )}

            <Grid>
              <FormControlLabel
                control={
                  <Switch
                    checked={manualResultEntry}
                    onChange={toggleChecked}
                  />
                }
                label="Enable Manual Result Entry"
              />
            </Grid>
            <Grid>
              <FormControlLabel
                control={
                  <Switch checked={disabledTest} onChange={toggleDisabled} />
                }
                label="Disable This Test"
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Parameters
                questions={questions}
                value={parameters}
                onChange={setParameters}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Questions value={questions} onChange={setQuestions} />
            </Grid>
          </Grid>
        </TabPanel>
      </CardContent>
      <Toolbar style={{ backgroundColor: "#f5f5f5" }}>
        <RaButton
          variant="contained"
          color="primary"
          label="Save"
          size="medium"
          onClick={handleSave}
        >
          <Save color="inherit" />
        </RaButton>
      </Toolbar>
    </Card>
  );
};

const Questions = ({ value, onChange }) => {
  function add() {
    onChange((qns) => [
      ...qns,
      {
        position: qns.length + 1,
        id: uuid(),
        title: "",
        format: "text",
        options: [],
        dependsOn: null,
        dependsOnValues: [],
      },
    ]);
  }

  function remove(position) {
    return () =>
      onChange(
        value
          .filter((qn) => qn.position !== position)
          .map((qn, i) => ({ ...qn, position: i + 1 }))
      );
  }

  function handleMoveUp(position) {
    return () =>
      onChange(
        value
          .map((qn) => {
            if (qn.position === position - 1) {
              return { ...qn, position: position };
            }

            if (qn.position === position) {
              return { ...qn, position: position - 1 };
            }

            return qn;
          })
          .sort((a, b) => a.position - b.position)
      );
  }

  function handleMoveDown(position) {
    return () =>
      onChange(
        value
          .map((qn) => {
            if (qn.position === position) {
              return { ...qn, position: position + 1 };
            }

            if (qn.position === position + 1) {
              return { ...qn, position };
            }

            return qn;
          })
          .sort((a, b) => a.position - b.position)
      );
  }

  function handleChange(position) {
    return (record) => {
      onChange(
        value.map((qn) => {
          if (qn.position === position) {
            return record;
          }

          return qn;
        })
      );
    };
  }

  return (
    <Grid container direction="column" spacing={4}>
      {value.map((qn) => {
        return (
          <Grid key={qn.position} item>
            <Question
              record={qn}
              all={value}
              remove={remove(qn.position)}
              onChange={handleChange(qn.position)}
              moveDown={handleMoveDown(qn.position)}
              moveUp={handleMoveUp(qn.position)}
            />
          </Grid>
        );
      })}
      <Grid item>
        <Button onClick={add}>Add Question</Button>
      </Grid>
    </Grid>
  );
};

const Question = ({ onChange, record, all, moveUp, moveDown, remove }) => {
  const [dependableQuestions, setDependableQuestions] = useState([]);
  const [dependableQuestion, setDependableQuestion] = useState();
  const [dependableAnswers, setDependableAnswers] = useState([]);

  useEffect(() => {
    setDependableQuestions(
      all
        .slice(0, record.position)
        .filter(
          (qn) =>
            ["Radio Buttons", "Dropdown", "Checkboxes", "Country"].includes(
              qn.format
            ) && qn.id !== record.id
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all]);

  useEffect(() => {
    setDependableQuestion(
      dependableQuestions.find((qn) => qn.id === record.dependsOn)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependableQuestions]);

  useEffect(() => {
    setDependableAnswers(dependableQuestion?.options || []);
  }, [dependableQuestion]);

  function handleChange(key, value) {
    onChange({ ...record, [key]: value });
  }

  return (
    <Grid
      container
      spacing={2}
      style={{
        borderColor: "#efefef",
        borderRadius: 16,
        padding: "16px 0",
      }}
    >
      <Grid item>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <Chip label={record.position} color="primary" />
          </Grid>
          {record.position === 1 ? null : (
            <Grid item>
              <IconButton onClick={moveUp} size="small">
                <ArrowUpward />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <IconButton onClick={remove} size="small">
              <Close />
            </IconButton>
          </Grid>
          {record.position === all.length ? null : (
            <Grid item>
              <IconButton onClick={moveDown} size="small">
                <ArrowDownward />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={record.title}
              onChange={(e) => handleChange("title", e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <DropdownField
              label="Format"
              options={questionFormats}
              value={record.format}
              onChange={(e) => handleChange("format", e.target.value)}
            />
          </Grid>
          {["Checkboxes", "Radio Buttons", "Dropdown"].includes(
            record.format
          ) ? (
            <Grid item>
              <ChipInput
                label="Choices"
                variant="outlined"
                color="primary"
                fullWidth
                allowDuplicates={false}
                value={record.options}
                chipRenderer={chipRenderer}
                onAdd={(value) =>
                  handleChange("options", [...record.options, value])
                }
                onDelete={(value) => {
                  handleChange(
                    "options",
                    record.options.filter((v) => v !== value)
                  );
                }}
              />
            </Grid>
          ) : null}

          {dependableQuestions.length === 0 ? null : (
            <Grid item>
              <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                  <DropdownField
                    label="Depends On"
                    options={dependableQuestions.map((qn) => ({
                      id: qn.id,
                      name: qn.title,
                    }))}
                    value={record.dependsOn}
                    onChange={(e) => handleChange("dependsOn", e.target.value)}
                  />
                </Grid>

                {!record.dependsOn ? null : (
                  <Grid item sm={12} md={6}>
                    <Autocomplete
                      multiple
                      options={dependableAnswers}
                      value={record.dependsOnValues}
                      onChange={(_, values) =>
                        handleChange("dependsOnValues", values)
                      }
                      getOptionLabel={(option) => option}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option}
                            {...getTagProps({ index })}
                            color="primary"
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          label="When Values Are Any Of"
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const Parameters = ({ value, onChange, questions }) => {
  useEffect(() => {
    if (value?.length < 1) {
      add();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function add() {
    onChange((prms) => [
      ...prms,
      {
        id: uuid(),
        name: "",
        identifier: "",
        title: "",
        description: "",
        type: "Qualitative",
        unit: null,
        values: [],
        references: [],
      },
    ]);
  }

  function remove(identifier) {
    return () => onChange(value.filter((prm) => prm.id !== identifier));
  }

  function handleChange(identifier) {
    return (record) => {
      onChange(
        value.map((prm) => {
          if (prm.id === identifier) {
            return record;
          }
          return prm;
        })
      );
    };
  }

  return (
    <Grid container direction="column" spacing={4}>
      {value.map((prm, index) => {
        return (
          <Grid key={index} item>
            <Parameter
              record={prm}
              all={value}
              remove={remove(prm.id)}
              onChange={handleChange(prm.id)}
              questions={questions}
            />
          </Grid>
        );
      })}
      <Grid item>
        <Button onClick={add}>Add Parameter</Button>
      </Grid>
    </Grid>
  );
};

const Parameter = ({ onChange, record, all, remove, questions }) => {
  function handleChange(key, value) {
    onChange({ ...record, [key]: value });
  }

  return (
    <Grid
      container
      spacing={2}
      style={{
        borderColor: "#efefef",
        borderRadius: 16,
        padding: "16px 0",
      }}
    >
      <Grid item>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <IconButton onClick={remove} size="small">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Grid spacing={4} container item>
              <Grid item sm={12} md={3}>
                <TextField
                  label="Identifier"
                  variant="outlined"
                  fullWidth
                  value={record.identifier}
                  onChange={(e) => handleChange("identifier", e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={record.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={12} md={3}>
                <DropdownField
                  label="Type"
                  options={parameterTypes}
                  value={record.type}
                  onChange={(e) => handleChange("type", e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              label="Description"
              variant="outlined"
              multiline
              minRows={3}
              fullWidth
              value={record.description}
              onChange={(e) => handleChange("description", e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {record.type !== "Quantitative" ? null : (
            <Grid item>
              <TextField
                label="Unit"
                variant="outlined"
                fullWidth
                value={record.unit}
                onChange={(e) => handleChange("unit", e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}

          {record.type !== "Qualitative" ? null : (
            <Grid item>
              <ChipInput
                label="Values"
                variant="outlined"
                color="primary"
                fullWidth
                allowDuplicates={false}
                value={record.values}
                chipRenderer={chipRenderer}
                InputLabelProps={{
                  shrink: true,
                }}
                onAdd={(value) =>
                  handleChange("values", [...record.values, value])
                }
                onDelete={(value) => {
                  handleChange(
                    "values",
                    record.values.filter((v) => v !== value)
                  );
                }}
              />
            </Grid>
          )}
          <Conditionally
            when={record.type === "Quantitative"}
            render={() => (
              <Grid item>
                <ReferenceRanges
                  questions={questions}
                  onChange={handleChange}
                  record={record}
                />
              </Grid>
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const ReferenceRanges = ({ onChange, record, questions }) => {
  function add() {
    onChange("references", [
      ...record.references,
      {
        id: uuid(),
        startRange: "",
        endRange: "",
        prognosis: "",
        conditions: [],
      },
    ]);
  }

  function handleChange(id) {
    return (refValue) => {
      onChange(
        "references",
        record.references.map((ref) => {
          if (ref.id !== id) {
            return ref;
          }
          return refValue;
        })
      );
    };
  }

  function remove(id) {
    return () =>
      onChange(
        "references",
        record.references.filter((ref) => ref.id !== id)
      );
  }
  return (
    <>
      <Grid container direction="column" spacing={2}>
        {record.references?.map((ref, index) => {
          return (
            <Grid key={index} item>
              <ReferenceRange
                record={ref}
                all={record.references}
                remove={remove(ref.id)}
                onChange={handleChange(ref.id)}
                questions={questions}
              />
            </Grid>
          );
        })}

        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={add}
          >
            Add reference Range
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const Conditions = ({ onChange, record, questions }) => {
  function add() {
    onChange("conditions", [
      ...record.conditions,
      {
        id: uuid(),
        type: "Request",
        field: "",
        comparator: "",
        value: [],
      },
    ]);
  }

  function handleChange(id) {
    return (condValue) => {
      onChange(
        "conditions",
        record.conditions.map((cond) => {
          if (cond.id !== id) {
            return cond;
          }
          return condValue;
        })
      );
    };
  }

  function remove(id) {
    return () =>
      onChange(
        "conditions",
        record.conditions.filter((cond) => cond.id !== id)
      );
  }
  return (
    <>
      <Grid
        container
        spacing={4}
        style={{ marginBottom: "1em" }}
        direction="column"
      >
        {record.conditions?.map((cond, index) => {
          return (
            <Grid key={index} justifyContent="space-evenly" container item>
              <Condition
                record={cond}
                all={record.conditions}
                remove={remove(cond.id)}
                onChange={handleChange(cond.id)}
                questions={questions}
              />
            </Grid>
          );
        })}
      </Grid>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={add}
      >
        Add Condition
      </Button>
    </>
  );
};

const Condition = ({ onChange, record, all, remove, questions }) => {
  function handleChange(key, value) {
    onChange({ ...record, [key]: value });
  }

  const conditionFieldMapper = {
    Request: questions
      .filter((quiz) =>
        referenceRangeRequestConditionFieldFormats.includes(quiz.format)
      )
      .map((quiz) => ({ id: quiz.id, name: quiz.title })),
    Patient: referenceRangePatientConditionFields.map((id) => ({
      id,
      name: id,
    })),
    Sample: referenceRangeSampleConditionFields.map((id) => ({ id, name: id })),
  };

  const conditionValueMapper = {
    type: sampleTypeOptions.map((id) => ({ id, name: id })),
    gender: ["male", "female"].map((id) => ({ id, name: id })),
  };

  function getComaparator(field) {
    if (
      quantitativeFields.includes(
        questions.find((quiz) => quiz.id === field)?.format
      ) ||
      quantitativeFields.includes(field)
    ) {
      return quantitativeFieldsComaparators;
    }

    if (
      selectionFields.includes(
        questions.find((quiz) => quiz.id === field)?.format
      ) ||
      selectionFields.includes(field)
    ) {
      return selectionFieldsComparators;
    }

    return [];
  }

  return (
    <Grid item container spacing={2}>
      <Grid item>
        <IconButton onClick={remove} size="small">
          <Close />
        </IconButton>
      </Grid>
      <Grid item sm={6} md={2}>
        <DropdownField
          label="Type"
          size="small"
          fullWidth
          options={referenceRangeConditionTypes}
          value={record.type}
          onChange={(e) => handleChange("type", e.target.value)}
        />
      </Grid>
      <Grid item sm={6} md={2}>
        <DropdownField
          label="Field"
          size="small"
          fullWidth
          options={conditionFieldMapper[record.type]}
          value={record.field}
          onChange={(e) => handleChange("field", e.target.value)}
        />
      </Grid>
      <Grid item sm={6} md={2}>
        <DropdownField
          label="Comparator"
          size="small"
          fullWidth
          options={getComaparator(record.field)}
          value={record.comparator}
          onChange={(e) => handleChange("comparator", e.target.value)}
        />
      </Grid>
      <Grid item sm={6} md={2}>
        {quantitativeFields.includes(record.field) ? (
          <TextField
            label="Value"
            variant="outlined"
            size="small"
            fullWidth
            value={record.value}
            onChange={(e) => handleChange("value", e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ) : null}

        {selectionFields.includes(
          questions.find((quiz) => quiz.id === record.field)?.format
        ) &&
        questions.find((quiz) => quiz.id === record.field)?.format ===
          "Dropdown" ? (
          <DropdownField
            label="Value"
            size="small"
            fullWidth
            options={questions
              .find((quiz) => quiz.id === record.field)
              ?.options?.map((id) => ({ id, name: id }))}
            value={record.value}
            onChange={(e) => handleChange("value", e.target.value)}
          />
        ) : null}

        {selectionFields.includes(
          questions.find((quiz) => quiz.id === record.field)?.format
        ) &&
        questions.find((quiz) => quiz.id === record.field)?.format ===
          "Checkboxes" ? (
          <CheckboxGroupInput
            value={record.value}
            label="Value"
            options={questions
              .find((quiz) => quiz.id === record.field)
              ?.options?.map((id) => ({ id, name: id }))}
            fullWidth
            onChange={(value) => handleChange("value", value)}
          />
        ) : null}

        {selectionFields.includes(record.field) &&
        [
          ...referenceRangeSampleConditionFields,
          ...referenceRangePatientConditionFields,
        ].includes(record.field) ? (
          <DropdownField
            label="Value"
            size="small"
            fullWidth
            options={conditionValueMapper[record.field]}
            value={record.value}
            onChange={(e) => handleChange("value", e.target.value)}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

const ReferenceRange = ({ onChange, record, all, remove, questions }) => {
  function handleChange(key, value) {
    onChange({ ...record, [key]: value });
  }

  return (
    <Paper component={Box} padding={2}>
      <Grid container spacing={2} direction="row">
        <Grid item>
          <IconButton onClick={remove} size="small">
            <Close />
          </IconButton>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={1}>
              <Grid item sm={6}>
                <TextField
                  label="Prognosis"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  value={record.prognosis}
                  onChange={(e) => handleChange("prognosis", e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  label="Greater Than (>)"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={record.startRange}
                  onChange={(e) => handleChange("startRange", e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  label="Less Than (<)"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={record.endRange}
                  onChange={(e) => handleChange("endRange", e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container>
              <Conditions
                questions={questions}
                onChange={handleChange}
                record={record}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const DropdownField = ({ label, value, onChange, options }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        input={<OutlinedInput label="Sample Types" />}
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const CheckboxGroupInput = ({ label, value, onChange, options }) => {
  return (
    <FormControl variant="outlined">
      <Typography variant="caption">{label}</Typography>
      <FormGroup row>
        {options.map((option) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={value.includes(option.id)}
                onChange={(e) => {
                  const values = e.target.checked
                    ? [...value, option.id]
                    : value.filter((v) => v !== option.id);
                  onChange(values);
                }}
                name={option.name}
              />
            }
            label={option.name}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

const chipRenderer = ({
  text,
  isFocused,
  isDisabled,
  handleClick,
  handleDelete,
  className,
}) => {
  return (
    <Chip
      key={text}
      label={text}
      disabled={isDisabled}
      color={isFocused ? "secondary" : "primary"}
      onDelete={handleDelete}
      clickable
      onClick={handleClick}
      className={className}
    />
  );
};

const CreateTitle = () => {
  return <Typography variant="h6">Create Test</Typography>;
};
